import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme';

export const StyledPageContainer = styled.div`
    flex: 1;
    align-self: center;
    ${p => p.paddingTop && '6rem'};
    ${p =>
        (p.bankPage || p.lightGray) &&
        `background-color: ${theme.colors.superLightGrey}`};
    @media only screen and (max-width: ${theme.breakpoints.tablet}px) {
        min-height: calc(100vh - ${theme.headerHeight});
    }
`;

export const StyledFeaturedImageContainer = styled.div`
    ${theme.mediaQueries.desktopMin} {
        top: ${p => p.hasBreadcrumbs ? 32 : 0}px;
    }
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
`;
