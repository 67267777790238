import produce from 'immer';

import {
    REQUEST_FOOTER,
    RECEIVE_FOOTER,
} from '../actions/footer';

import setCommonReducerProperties from '../helpers/set-common-reducer-properties';

const initialState = {
    columns: [],
    mission: '',
    social_links: [], // eslint-disable-line
    ...setCommonReducerProperties(),
};

function footer(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case REQUEST_FOOTER:
                draft.loading = true;
                draft.error = false;
                draft.loaded = false;
                break;

            case RECEIVE_FOOTER: {
                draft.columns = action.payload.columns;
                draft.copyright = action.payload.copyright;
                draft.social_links = action.payload.social_links; // eslint-disable-line
                draft.error = false;
                draft.loading = false;
                draft.loaded = true;
                break;
            }
        }
    });
}

export default footer;
