import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { HeadingCss } from './style';
import { switchCase } from '../../helpers/switch';

const Heading = ({
    align = 'left',
    className,
    children,
    color,
    padding,
    margin,
    tag = 'h2',
    size = 1,
}) => {
    const Component = styled[tag]`
        ${HeadingCss}
    `;

    return (
        <Component
            className={className}
            color={color}
            padding={padding}
            margin={margin}
            tag={tag}
            size={size}
            align={align}
        >
            {children}
        </Component>
    );
};

Heading.propTypes = {
    align: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    padding: PropTypes.string,
    margin: PropTypes.string,
    tag: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    size: PropTypes.number,
};

Heading.parseProps = ({ margin, ...atts }) => {
    const size = {
        small: '1rem',
        medium: '1.5rem',
        large: '2rem',
    };

    const marginSize = size[atts.marginSize];

    return {
        align: atts.align,
        color: atts.color,
        children: atts.children?.replace(/<[^>]*>/gm, ''),
        tag: atts.tag || null,
        margin: switchCase({
            none: '0',
            top: `${marginSize} 0 0`,
            bottom: `0 0 ${marginSize}`,
            topAndBottom: `${marginSize} 0`,
        })('none')(margin),
        size: atts.size || 1,
    };
};

export default Heading;
