import 'core-js/stable';
import 'regenerator-runtime/runtime';
require('smoothscroll-polyfill').polyfill();

import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Loadable from 'react-loadable';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

import { Provider } from 'react-redux';
import EmotionThemeProvider from '../scripts/emotion/provider';

import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import * as reducers from './reducers';

import App from './containers/app';
import sentry from './sentry';

import initRsaWorker from './helpers/init-rsa-worker';

require('./helpers/prepend-polyfill');

let getData = function(name) {
    var data = {};
    try {
        data = JSON.parse(document.querySelector(name).innerHTML); // eslint-disable-line
    } catch (err) {
        console.log("error", name, err); // eslint-disable-line
    }
    return data;
};

const appState = getData('[data-app-state]');
const root = document.querySelector('[data-root]'); // eslint-disable-line

// Initiate sentry
sentry(
    appState.settings.sentryDSN,
    appState.settings.sentryProject,
    appState.settings.version,
    { userState: appState.user }
);

if (appState.user.isAuthenticated) {
    // Initialize web worker to generate RSA keys for pin decryption
    initRsaWorker();
}

// Since this will only be set in the frontend, we dont need to have the complete url
appState.settings.apiUrl = appState.settings.apiUrl.replace(/http(|s):\/\/.+?\//, '/');
appState.settings.fileDownloadUrl = appState.settings.fileDownloadUrl.replace(/http(|s):\/\/.+?\//, '/');

const composerEnhancer = composeWithDevTools({
    trace: true,
    traceLimit: 25
});

// const debuggerCompose = process.env.NODE_ENV == 'production'?
//     compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);

const store = createStore(
    combineReducers({...reducers, routing: routerReducer}), appState, composerEnhancer(applyMiddleware(thunk))
);

// On the frontend we make sure all actions are triggered directly
const registerFetchAction = (action) => {
    action(store.dispatch, store.getState());
};

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
Loadable.preloadReady().then(() => {
    renderMethod(
        <Provider store={store}>
            <BrowserRouter>
                <EmotionThemeProvider>
                    <App registerFetchAction={registerFetchAction} />
                </EmotionThemeProvider>
            </BrowserRouter>
        </Provider>
        , root
    );
});

if ('serviceWorker' in navigator) {

    let bundles = appState.settings.bundles;
    let parsedBundles = bundles.map(bundle => encodeURIComponent(bundle)).join(',');

    // Register a service worker hosted at the root of the
    // site using the default scope.
    console.log('HTML Load timestamp', window.RAWB_LOADING_TIMESTAMP ); // eslint-disable-line
    navigator.serviceWorker.register(`${appState.settings.serviceWorkerScript}?version=${appState.settings.version}&reactVersion=${appState.settings.reactVersion}&scripts=${parsedBundles}`).then(() => {
    }).catch(function(error) {
        console.log('Service worker registration failed:', error); // eslint-disable-line
    });

}
