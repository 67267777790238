import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { lighten } from 'polished';

const xsmall = () => css`
    padding: 1.7rem 3rem 1.7rem 3rem;
    font-size: 1.5rem;
    line-height: 1em;
`;

const small = () => css`
    padding: 2rem 2.5rem;
    font-size: 1.75rem;
    line-height: 1em;
`;

const medium = () => css`
    padding: 1.7rem 4rem 1.7rem 4rem;
    font-size: clamp(1.5rem, 1.5vw, 2rem);
    line-height: 1.5em;
`;

const large = p => css`
    padding: 1.7rem 6rem 1.7rem 6rem;
    font-size: clamp(1.7rem, 1.7vw, 2.5rem);
    line-height: 1.75em;
    text-align: ${!p.icon && 'center'};
`;

const full = () => css`
    padding: 1.7rem 2rem 1.7rem 2rem;
    font-size: clamp(1.7rem, 1.7vw, 2.5rem)
    line-height: 1.75em;
    width: 100%;
`;

const disabled = p =>
    p.disabled &&
    css`
        border: 1px solid ${p.theme.colors.lightGrey};
        color: ${p.theme.colors.darkGrey};
        background-color: ${p.theme.colors.superLightGrey};
    `;

const size = {
    xsmall,
    small,
    medium,
    large,
    full,
};

const border = (border, theme) =>
    border.shorthand &&
    css`
        ${border.shorthand}: ${border.width}px solid
            ${theme.colors[border.color]}
    `;

const buttonHover = ({ $background, theme, disabled }) => {
    let background = $background !== 'purple' ? $background : 'red';
    return (
        !disabled &&
        css`
            background: ${lighten(0.1, theme.colors[background] || background)};
            transition: background-color 0.3s ease-out;
        `
    );
};

export const StyledButton = styled.span`
    font-family: 'Courier new', sans-serif;
    font-weight: 700;
    letter-spacing: 1px;
    display: ${p => p.$display};
    text-transform: ${p => p.allCaps && 'uppercase'};
`;

export const StyledButtonWrapper = styled.button`
    ${p => size[p.size](p)};
    cursor: ${p => !p.disabled && 'pointer'};
    position: relative;
    overflow: hidden;
    text-align: ${p => p.$textAlign};
    width: ${p => (p.fullWidth ? '100%' : 'fit-content')};
    margin: ${p => p.$margin && p.$margin};
    color: ${p => p.theme.colors[p.$color]};
    background: ${p => {
        if (p.$background === 'purple') {
            return p.theme.colors.red;
        }
        return p.theme.colors[p.$background] || p.$background;
    }};
    ${p => border(p.$border, p.theme)};

    &:hover {
        ${({ $background, color, theme, hover, disabled }) =>
            buttonHover({ $background, color, theme, hover, disabled })}
    }
    ${p => disabled(p)}

    ${p =>
        p.icon &&
        p.icon.placement &&
        css`
            padding-${p.icon.placement}: calc(${p.iconWidth}px + ${p.iconSpacing});
        `};

    ${p =>
        p.disabled &&
        css`
            opacity: 0.7;
        `}
`;
