import React from 'react';
import PropTypes from 'prop-types';
import BankID from './bankid';

const Icons = ({
    name,
    className,
    color,
    opacity,
    width,
    height,
    stroke,
    onClick,
}) => {
    let icons = {};

    icons['arrow-right'] = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 8}
            height={height || 15}
            viewBox={`0 0 ${width || 8} ${height || 15}`}
            className={className || ''}
        >
            <path
                fill={color || '#5887F5'}
                fillRule="evenodd"
                d="M6.13 7.293L.2 1.09A.579.579 0 0 1 0 .645C0 .47.067.315.2.182A.606.606 0 0 1 .645 0c.176 0 .325.06.446.182l6.584 6.656c.133.122.2.273.2.455a.589.589 0 0 1-.2.454L1.09 14.404a.606.606 0 0 1-.446.182.606.606 0 0 1-.445-.182.589.589 0 0 1-.2-.455c0-.181.067-.333.2-.454l5.93-6.202z"
            />
        </svg>
    );

    icons['arrow-left'] = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 8}
            height={height || 15}
            viewBox={`0 0 ${width || 8} ${height || 15}`}
            className={className || ''}
        >
            <path
                fill={color || '#5887F5'}
                fillRule="evenodd"
                d="M1.745 7.293l5.93-6.202c.133-.121.2-.27.2-.446a.636.636 0 0 0-.2-.463A.606.606 0 0 0 7.229 0a.606.606 0 0 0-.445.182L.2 6.838a.589.589 0 0 0-.2.455c0 .182.067.333.2.454l6.584 6.657c.121.121.27.182.445.182.176 0 .324-.06.446-.182a.589.589 0 0 0 .2-.455.589.589 0 0 0-.2-.454l-5.93-6.202z"
            />
        </svg>
    );

    icons.minus = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 14}
            height={height || 2}
            viewBox={`0 0 ${width || 14} ${height || 2}`}
            className={className || ''}
        >
            <path
                fill={color || '#FFF'}
                fillRule="evenodd"
                d="M.778 1.556h12.444a.757.757 0 0 0 .56-.22A.757.757 0 0 0 14 .779a.757.757 0 0 0-.219-.56.757.757 0 0 0-.559-.218H.778a.757.757 0 0 0-.56.219A.757.757 0 0 0 0 .778c0 .227.073.413.219.559a.757.757 0 0 0 .559.219z"
                opacity={opacity || 1}
            />
        </svg>
    );

    icons.plus = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 14}
            height={height || 14}
            viewBox={`0 0 ${width || 14} ${height || 14}`}
            className={className || ''}
            x="0px"
            y="0px"
        >
            <path
                fill={color || '#FFF'}
                fillRule="evenodd"
                d="M.778 7.778h5.444v5.444c0 .227.073.413.219.56A.757.757 0 0 0 7 14a.757.757 0 0 0 .559-.219.757.757 0 0 0 .219-.559V7.778h5.444a.757.757 0 0 0 .56-.219A.757.757 0 0 0 14 7a.757.757 0 0 0-.219-.559.757.757 0 0 0-.559-.219H7.778V.778a.757.757 0 0 0-.219-.56A.757.757 0 0 0 7 0a.757.757 0 0 0-.559.219.757.757 0 0 0-.219.559v5.444H.778a.757.757 0 0 0-.56.219A.757.757 0 0 0 0 7c0 .227.073.413.219.559a.757.757 0 0 0 .559.219z"
                opacity={opacity || 1}
            />
        </svg>
    );

    icons.headset = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 48}
            height={height || 48}
            viewBox={`0 0 ${width || 48} ${height || 48}`}
            className={className || ''}
        >
            <path
                fill={color || '#000000'}
                d="M24,1C12.42,1,3,10.42,3,22v12c0,2.757,2.243,5,5,5h6c0.552,0,1-0.448,1-1V24c0-0.552-0.448-1-1-1H5v-1 C5,11.523,13.523,3,24,3s19,8.523,19,19v1h-9c-0.552,0-1,0.448-1,1v14c0,0.552,0.448,1,1,1h6c1.13,0,2.162-0.391,3-1.026V39 c0,2.757-2.243,5-5,5h-3v-1c0-0.552-0.448-1-1-1H24c-0.552,0-1,0.448-1,1v3c0,0.552,0.448,1,1,1h10c0.552,0,1-0.448,1-1h3 c3.86,0,7-3.14,7-7V22C45,10.42,35.58,1,24,1z"
            ></path>
        </svg>
    );

    icons['customer-support'] = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 48}
            height={height || 48}
            viewBox={`0 0 ${width || 48} ${height || 48}`}
            className={className || ''}
        >
            <path
                fill={color || '#000000'}
                d="M46,3H27c-0.553,0-1,0.447-1,1v19c0,0.379,0.214,0.725,0.553,0.895 C26.694,23.965,26.848,24,27,24c0.212,0,0.424-0.067,0.6-0.2l7.733-5.8H46c0.553,0,1-0.447,1-1V4C47,3.447,46.553,3,46,3z"
            ></path>
            <path
                fill={color || '#000000'}
                d="M23.883,35.86C21.761,35.012,18.324,34,14,34s-7.761,1.012-9.884,1.86C2.224,36.619,1,38.438,1,40.494V44 c0,0.553,0.447,1,1,1h24c0.553,0,1-0.447,1-1v-3.506C27,38.438,25.776,36.619,23.883,35.86z"
            ></path>
            <path
                fill={color || '#000000'}
                d="M14,31c4.432,0,8-5.471,8-10c0-4.411-3.589-8-8-8s-8,3.589-8,8C6,25.529,9.568,31,14,31z"
            ></path>
        </svg>
    );

    icons.star = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 20}
            height={height || 21}
            viewBox={`0 0 ${width || 20} ${height || 21}`}
            className={className || ''}
        >
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M10,15.273 L16.18,19 L14.545,11.971 L20,7.244 L12.809,6.627 L10,0 L7.191,6.627 L0,7.244 L5.455,11.971 L3.82,19 L10,15.273 Z"
                id="Shape"
            />
        </svg>
    );

    icons.avatar = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 26 26"
            width={width || 48}
            height={height || 48}
            className={className || ''}
        >
            <g transform="translate(1, 1)">
                <g
                    fillRule="evenodd"
                    fill="none"
                    strokeWidth="1"
                    stroke="none"
                    id="ic/user_1582537645497"
                >
                    <path
                        fill={color || '#000000'}
                        id="Combined-Shape_1582537645497"
                        d="M9.90589831,1.31893688 C13.6242712,0.16726374 17.5768136,2.23278061 18.7342373,5.93267898 L18.7342373,5.93267898 L17.6901695,7.22422995 L17.0711864,7.99930894 L19.0511864,7.99930894 C19.0515593,9.66819611 18.4504746,11.2818 17.3583051,12.548121 C20.6322034,13.1877745 22.9947797,16.0406143 23,19.3605793 L23,19.3605793 L23,23 L20.3898305,23 L20.3898305,19.3605793 C20.3879661,16.9585394 18.4295932,15.0136069 16.0159322,15.015462 L16.0159322,15.015462 L8.18542373,15.015462 C5.66027119,15.0195433 3.61427119,17.0557489 3.61016949,19.5679844 L3.61016949,19.5679844 L3.61016949,23 L1,23 L1,19.5679844 C1.01938983,16.2135137 3.37674576,13.3220869 6.67152542,12.6111959 C6.03538983,11.8802693 5.55772881,11.0265323 5.26911864,10.1034128 C4.11169492,6.40388546 6.18789831,2.47061002 9.90589831,1.31893688 Z M12,3.5840672 C9.54942373,3.58629337 7.56308475,5.56127924 7.55898305,7.99930894 L7.55898305,7.99930894 C7.55898305,10.4436461 9.54755932,12.4219713 12,12.4219713 C14.4528136,12.4219713 16.4410169,10.4436461 16.4410169,8.00301923 C16.4410169,5.56239232 14.4528136,3.5840672 12,3.5840672 Z"
                        vectorEffect="non-scaling-stroke"
                    />
                </g>
            </g>
        </svg>
    );

    icons.search = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 48}
            height={height || 48}
            viewBox="0 0 64 64"
            className={className || ''}
        >
            <path
                fill={color || '#000000'}
                d="M57.5,24l4.7-5.9c-1.2-4.6-3.8-8.8-7.6-12C45.9-1,33-0.8,24.6,6.7c-5,4.4-7.8,10.7-7.8,17.3c0,5,1.6,9.7,4.5,13.6L1.1,57.8
                l4.9,5l20.2-20.2c5.1,3.7,11.5,5.3,18.1,4.1c3.2-0.6,6.2-1.9,8.9-3.8c6.2-4.5,9.8-11.4,9.8-18.9v0H57.5z M51.2,35.5
                c-3,3-7.1,4.7-11.4,4.7c-4.3,0-8.3-1.7-11.4-4.7c-3-3-4.7-7.1-4.7-11.4s1.7-8.3,4.7-11.4c3.1-3.1,7.2-4.7,11.4-4.7
                c4.1,0,8.2,1.6,11.4,4.7c3,3,4.7,7.1,4.7,11.4S54.3,32.5,51.2,35.5z"
            ></path>
        </svg>
    );

    icons.closeCircle = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            shapeRendering={'auto'}
            height={height}
            width={width}
            x="0px"
            y="0px"
            viewBox="-20 -20 1040 1040"
            className={className}
        >
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                strokeWidth={'20px'}
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
                d="M846.5,153.5C655.2-37.7,345-37.7,153.5,153.5C-38,344.8-37.7,655,153.5,846.5c191.3,191.3,501.5,191.3,692.9,0C1038,655.2,1037.7,344.8,846.5,153.5z M817.5,817.5c-175,175-460,175-635,0c-175-175-175-460,0-635c175-175,460-175,635,0C992.7,357.5,992.7,642.5,817.5,817.5z"
            />
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                strokeWidth={'20px'}
                strokeLinecap={'round'}
                strokeLinejoin={'round'}
                d="M698.3,273.9L499.5,472.2L301.7,274.8l-27.8,27.8L471.7,500L273.9,697.4l27.8,27.8l197.8-197.4l198.8,198.3l27.8-27.8L527.3,500l198.8-198.3L698.3,273.9z"
            />
        </svg>
    );

    icons['arrow-down'] = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            className={className}
        >
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M277.375,85v259.704l119.702-119.702L427,256L256,427L85,256l29.924-29.922l119.701,118.626V85H277.375z"
            />
        </svg>
    );

    icons.document = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            className={className}
        >
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M399.3,168.9c-0.7-2.9-2-5-3.5-6.8l-83.7-91.7c-1.9-2.1-4.1-3.1-6.6-4.4c-2.9-1.5-6.1-1.6-9.4-1.6H136.2
            c-12.4,0-23.7,9.6-23.7,22.9v335.2c0,13.4,11.3,25.9,23.7,25.9h243.1c12.4,0,21.2-12.5,21.2-25.9V178.4
            C400.5,174.8,400.1,172.2,399.3,168.9z M305.5,111l58,63.5h-58V111z M144.5,416.5v-320h129v81.7c0,14.8,13.4,28.3,28.1,28.3h66.9
            v210H144.5z"
            />
        </svg>
    );

    icons.cross = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            className={className}
            onClick={onClick}
        >
            <polygon
                fill={color || '#000000'}
                points="405,136.798 375.202,107 256,226.202 136.798,107 107,136.798 226.202,256 107,375.202 136.798,405 256,285.798
			375.202,405 405,375.202 285.798,256"
            />
        </svg>
    );

    icons.checkMark = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            className={className}
        >
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M461.6,109.6l-54.9-43.3c-1.7-1.4-3.8-2.4-6.2-2.4c-2.4,0-4.6,1-6.3,2.5L194.5,323c0,0-78.5-75.5-80.7-77.7
                c-2.2-2.2-5.1-5.9-9.5-5.9c-4.4,0-6.4,3.1-8.7,5.4c-1.7,1.8-29.7,31.2-43.5,45.8c-0.8,0.9-1.3,1.4-2,2.1c-1.2,1.7-2,3.6-2,5.7
                c0,2.2,0.8,4,2,5.7l2.8,2.6c0,0,139.3,133.8,141.6,136.1c2.3,2.3,5.1,5.2,9.2,5.2c4,0,7.3-4.3,9.2-6.2L462,121.8
                c1.2-1.7,2-3.6,2-5.8C464,113.5,463,111.4,461.6,109.6z"
            />
        </svg>
    );

    icons.close = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width={width}
            height={height}
            viewBox="0 0 512 512"
            className={className}
            onClick={onClick}
        >
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4
                L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1
                c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1
                c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
            />
        </svg>
    );

    icons.helpCircled = (
        <svg
            width={width || 18}
            height={height || 18}
            viewBox="0 0 18 18"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill={color || '#000000'}
                d="M9,18 C4.02943725,18 0,13.9705627 0,9 C0,4.02943725 4.02943725,0 9,0 C13.9705627,0 18,4.02943725 18,9 C18,13.9705627 13.9705627,18 9,18 Z M8,7 L8,13 L10,13 L10,7 L8,7 Z M8,4 L8,6 L10,6 L10,4 L8,4 Z"
                fillRule="nonzero"
            ></path>
        </svg>
    );

    icons['arrow-down-edge'] = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            height={height}
            width={width}
            viewBox="0 0 62.7 62.7"
            className={className}
        >
            <g>
                <polygon
                    fill={color || '#000000'}
                    points="44.6,22.8 49.6,27.7 36.2,41.1 31.5,36.7 31.5,45.8 31.5,45.7 31.5,45.7 31.5,45.7 29,43.2 13.5,27.7 18.4,22.8
                    31.4,35.7 	"
                />
            </g>
        </svg>
    );

    icons.arrowUpWithTail = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            x="0px"
            y="0px"
            viewBox="-14 -8 50 50"
            className={className}
        >
            <polygon
                fill={color || '#000000'}
                points="18 9.12836767 15.55 11.6133122 10.8 6.74484945 10.8 32 7.3 32 7.3 6.74484945 2.5 11.6133122 0 9.12836767 4.9 4.10776545 7.3 6.33914422 7.3 1.72424723 9 0"
            ></polygon>
        </svg>
    );

    icons.arrowDownWithTail = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            x="0px"
            y="0px"
            viewBox="-14 -8 50 50"
            className={className}
        >
            <polygon
                fill={color || '#000000'}
                points="1 22.8716323 3.45 20.3866878 8.2 25.2551506 8.2 0 11.7 0 11.7 25.2551506 16.5 20.3866878 19 22.8716323 14.1 27.8922345 11.7 25.6608558 11.7 30.2757528 10 32"
            ></polygon>
        </svg>
    );

    icons.arrowLeftWithTail = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            x="0px"
            y="0px"
            viewBox="0 0 62.7 62.7"
            className={className}
        >
            <polygon
                fill={color || '#000000'}
                points="2.3,28.6 -0.2,31.2 -0.2,31.2 -0.2,31.2 2.3,33.6 3.2,34.6 12.3,34.6 7.9,39.4 17.8,49.2 22.7,44.2 13.1,34.6 62.9,34.6 62.9,27.6 13.1,27.6 22.7,18.1 17.8,13.2 "
            />
        </svg>
    );

    icons.arrowRightWithTail = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            x="0px"
            y="0px"
            viewBox="0 0 62.7 62.7"
            className={className}
        >
            <polygon
                fill={color || '#000000'}
                points="60.4,33.8 62.9,31.2 62.9,31.2 62.9,31.2 60.4,28.8 59.5,27.8 50.4,27.8 54.8,23 44.9,13.2 40,18.2 49.6,27.8
            -0.2,27.8 -0.2,34.8 49.6,34.8 40,44.3 44.9,49.2"
            />
        </svg>
    );

    icons.rss = (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width={width || 48}
            height={height || 48}
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            className={className}
        >
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M119.9,336.1c-30.8,0-55.9,25.1-55.9,55.8c0,30.8,25.1,55.6,55.9,55.6c30.9,0,55.9-24.9,55.9-55.6C175.8,361.2,150.8,336.1,119.9,336.1z"
            />
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M64,192v79.9c48,0,94.1,14.2,128,48.1c33.9,33.9,48,79.9,48,128h80C320,308.1,204,192,64,192z"
            />
            <path
                fill={color || '#000000'}
                stroke={stroke || '#FFF'}
                d="M64,64v79.9c171,0,303.9,133,303.9,304.1H448C448,236.3,276,64,64,64z"
            />
        </svg>
    );

    icons.logout = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 48}
            height={height || 48}
            viewBox="0 0 64 64"
            className={className || ''}
        >
            <g>
                <polygon points="32.07,50.21 10.07,50.21 10.07,13.21 32.07,13.21 32.07,6.21 3.07,6.21 3.07,57.21 32.07,57.21 	" />
                <polygon
                    points="47.56,28.21 20.07,28.21 20.07,35.21 47.56,35.21 38.05,44.76 43.01,49.7 58.5,34.14 60.93,31.72 60.92,31.71
                    60.93,31.69 58.5,29.27 57.44,28.21 57.43,28.21 54.52,28.21 52.07,28.21 52.07,28.21 48.87,28.21 53.01,23.76 43.01,13.71
                    38.05,18.65 	"
                />
            </g>
        </svg>
    );

    icons.error = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path fill="none" d="M0 0h24v24H0V0z" />
            <path
                fill={color || '#000000'}
                d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
            />
        </svg>
    );

    icons.home = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 27 26"
            width={width || 26}
            height={height || 27}
            className={className || ''}
        >
            <g transform="translate(1, 1)">
                <g
                    transform="translate(1.000000, 0.000000)"
                    fillRule="evenodd"
                    fill="none"
                    strokeWidth="1"
                    stroke="none"
                    id="ic/home_1582537677051"
                >
                    <path
                        fill={color || '#000000'}
                        id="home_1582537677051"
                        d="M12.0003928,1 L23,10.7543709 L23,17.3133036 L21.0386744,20.3576488 L23,20.3576488 L23,23 L1,23 L1,10.7543709 L12.0003928,1 Z M12.0003928,4.68343285 L3.74970539,11.9998028 L3.74970539,20.2385102 L8.26432882,20.2385102 L8.26432882,12.5781376 L14.1793379,12.5781376 L14.1793379,15.3396274 L11.0140342,15.3396274 L11.0140342,20.2385102 L20.2502946,20.2385102 L20.2502946,11.9998028 L12.0003928,4.68343285 Z"
                        vectorEffect="non-scaling-stroke"
                    />
                </g>
            </g>
        </svg>
    );

    icons.receipt = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 26 26"
            width={width || 24}
            height={height || 24}
            className={className || ''}
        >
            <g transform="translate(1, 1)">
                <g
                    fillRule="evenodd"
                    fill="none"
                    strokeWidth="1"
                    stroke="none"
                    id="ic/document_1582538106260"
                >
                    <path
                        fill={color || '#000000'}
                        id="document_1582538106260"
                        d="M20.5828125,0.990375 L20.5828125,18.1837187 L18.6725937,20.584125 L20.5828125,20.584125 L20.5828125,22.990375 L3.0515625,22.990375 L3.0515625,0.990375 L20.5828125,0.990375 Z M18.1765625,3.740375 L5.4578125,3.740375 L5.4578125,20.584125 L18.1765625,20.584125 L18.1765625,3.740375 Z M13.3640625,10.959125 L13.3640625,13.365375 L7.8640625,13.365375 L7.8640625,10.959125 L13.3640625,10.959125 Z M16.1140625,6.834125 L16.1140625,9.240375 L7.8640625,9.240375 L7.8640625,6.834125 L16.1140625,6.834125 Z"
                        vectorEffect="non-scaling-stroke"
                    />
                </g>
            </g>
        </svg>
    );

    icons.messages = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 48}
            height={height || 48}
            viewBox="0 0 26 24"
            className={className || ''}
        >
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="transaktionslista"
                    transform="translate(-175.000000, -1796.000000)"
                    fill={color || '#000000'}
                    fillRule="nonzero"
                >
                    <g
                        id="message"
                        transform="translate(176.000000, 1797.000000)"
                    >
                        <path
                            d="M22.2103835,16.6314883 C23.9999302,14.6834389 25,12.3571195 25,9.9 C25,3.80392331 19.1729907,-1 12,-1 C4.82700932,-1 -1,3.80392331 -1,9.9 C-1,15.9960767 4.82700932,20.8 12,20.8 C13.2464246,20.8 14.3041717,20.6857286 15.3218824,20.4162672 L23.0040507,23.5672583 L22.2103835,16.6314883 Z M20.6323129,20.4327417 L15.4324718,18.2999255 L15.0922757,18.4028348 C14.1715398,18.6813574 13.206711,18.8 12,18.8 C5.85550816,18.8 1,14.7970051 1,9.9 C1,5.00299493 5.85550816,1 12,1 C18.1444918,1 23,5.00299493 23,9.9 C23,11.949463 22.0999983,13.9157167 20.4536016,15.5758333 L20.1155391,15.916713 L20.6323129,20.4327417 Z"
                            id="Path"
                        ></path>
                        <polygon points="7 8.5 18 8.5 18 6.5 7 6.5"></polygon>
                        <polygon points="7 13.5 14 13.5 14 11.5 7 11.5"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    );

    icons.card = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={className}
        >
            <path
                fill={color || '#000000'}
                d="M20.33,4H3.67C3.23,3.99,2.8,4.17,2.49,4.49C2.17,4.8,1.99,5.23,2,5.67v12.66  c-0.01,0.44,0.17,0.87,0.49,1.18c0.31,0.32,0.74,0.5,1.18,0.49h16.66c0.92,0,1.67-0.75,1.67-1.67l0,0V5.67C22,4.75,21.25,4,20.33,4  L20.33,4L20.33,4z M3.67,5.34h16.66c0.18,0.01,0.32,0.15,0.33,0.33V8H3.34V5.67C3.35,5.49,3.49,5.35,3.67,5.34  C3.67,5.34,3.67,5.34,3.67,5.34z M20.33,18.66H3.67c-0.18-0.01-0.32-0.15-0.33-0.33V12h17.32v6.33  C20.65,18.51,20.51,18.65,20.33,18.66L20.33,18.66z M7.33,17.33V16H4.67v1.33h2.67H7.33z M12.66,17.33V16h-4v1.33H12.66z"
            />
        </svg>
    );

    icons.roundedMinus = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width || 24}
            height={height || 24}
            viewBox="0 0 486 486"
        >
            <path
                fill={color || '#000000'}
                d="M458.736,181.097H26.334C11.793,181.097,0,192.884,0,207.425v70.215c0,14.541,11.787,26.328,26.334,26.328h432.402    c14.541,0,26.328-11.787,26.328-26.328v-70.215C485.07,192.884,473.283,181.097,458.736,181.097z"
            />
        </svg>
    );

    icons.calendar = (
        <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            overflow="visible"
            preserveAspectRatio="none"
            viewBox="0 0 26 26"
            height={height || 24}
            width={width || 24}
        >
            <g transform="translate(1, 1)">
                <desc>Created with Sketch.</desc>
                <g
                    fillRule="evenodd"
                    fill="none"
                    strokeWidth="1"
                    stroke="none"
                    id="ic/calendar_1576663167065"
                >
                    <path
                        fill="#000000"
                        id="calendar_1576663167065"
                        d="M8.22337748,2 L8.223,4.409 L15.776,4.409 L15.7762786,2 L18.184071,2 L18.184,4.409 L23,4.40964883 L23,16.6251062 L21.0758298,19.6010225 L23,19.6010225 L23,22 L1,22 L1,4.40964883 L5.815,4.409 L5.81558498,2 L8.22337748,2 Z M5.815,6.819 L3.40779249,6.81929767 L3.40779249,19.5903512 L20.5922075,19.5903512 L20.5922075,6.81929767 L18.184,6.819 L18.184071,8.88471096 L15.7762786,8.88471096 L15.776,6.819 L8.223,6.819 L8.22337748,8.88471096 L5.81558498,8.88471096 L5.815,6.819 Z"
                        vectorEffect="non-scaling-stroke"
                        style={{ fill: 'rgb(0, 51, 160)' }}
                    />
                </g>
            </g>
        </svg>
    );

    icons.bankid = <BankID width={width} height={height} />;

    if (!icons[name]) {
        return (
            <div>Coulnt find the correct icon with the prop name {name}</div>
        );
    }

    return icons[name];
};

Icons.propTypes = {
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    color: PropTypes.string,
    opacity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    stroke: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.oneOf([
        'arrow-right',
        'arrow-left',
        'error',
        'minus',
        'plus',
        'headset',
        'customer-support',
        'star',
        'avatar',
        'search',
        'closeCircle',
        'arrow-down',
        'document',
        'cross',
        'checkMark',
        'close',
        'helpCircled',
        'arrow-down-edge',
        'arrowUpWithTail',
        'arrowDownWithTail',
        'arrowRightWithTail',
        'arrowLeftWithTail',
        'rss',
        'logout',
        'card',
        'roundedMinus',
        'home',
        'messages',
        'calendar',
        'receipt',
        'bankid',
        'three-dots',
    ]),
};

export default Icons;
