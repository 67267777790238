import { useEffect } from 'react';
import intersection from 'lodash/intersection';

const checkIfClassnameInList = (classList, ignoreList) => {
    return !!intersection(classList, ignoreList).length;
};

const useOutsideClick = ({
    ref,
    callback,
    shouldExecute = true,
    dependencies,
    ignoreClasses = [],
}) => {
    const handleClick = e => {
        if (checkIfClassnameInList(e.target.classList, ignoreClasses)) {
            return;
        }
        if (!ref.current || ref.current.contains(e.target)) {
            return;
        }
        callback(e);
    };

    useEffect(() => {
        if (shouldExecute) {
            document.addEventListener('mousedown', handleClick, false);
            document.addEventListener('touchstart', handleClick, false);

            return () => {
                document.removeEventListener('mousedown', handleClick, false);
                document.removeEventListener('touchstart', handleClick, false);
            };
        }
    }, dependencies);
};

export default useOutsideClick;
