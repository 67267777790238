/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor'; // Change to react-anchor-link-smooth-scroll
import styled from '@emotion/styled';
import { withTheme } from 'emotion-theming';

import Grid from '../grid/grid';

import layoutStyles from './layout-styles';
import layoutConvert from './layout-converters';

const GRID_COLUMNS = 12;

const Section = styled.div`
    display: -ms-grid;
    -ms-grid-columns: 1fr 0rem ${props => props.sectionPadding}
        minmax(0, ${props => props.theme.maxWidth}px)
        ${props => props.sectionPadding} 0rem 1fr;
    position: relative;
    display: grid;
    margin: 0 auto;
    width: 100%;
    grid-template-columns:
        auto 0rem ${props => props.sectionPadding} minmax(
            0,
            ${props => props.theme.maxWidth}px
        )
        ${props => props.sectionPadding} 0rem auto;
`;

const Placer = styled.div`
    -ms-grid-column: 4;
    grid-column: 4 / auto;
    position: relative;
`;


const getConverterType = viewport => {
    const converterType = viewport.width <= 768 ? 'mobile' : 'identity';
    return converterType;
};

const getAlignment = value => {
    let alignment = 'start';
    switch (value) {
        case 'center':
            alignment = 'center';
            break;
        case 'bottom':
            alignment = 'end';
            break;
    }
    return alignment;
};

const SectionComponent = props => {
    const {
        viewport,
        children,
        template,
        theme,
        width,
        gap,
        equalHeight,
        useAnchorLink,
        anchorLink,
    } = props;
    const sectionItems = children
        ? Array.isArray(children)
            ? children
            : [children]
        : [];

    const { sectionCss, placerCss, backgroundImage } = layoutStyles(props);
    let sectionPadding = width === 'contained' ? 0 : theme.outerPadding;

    if (sectionItems.length == template.length) {
        const converterType = props.getConverterType(viewport);
        const { converted, templateRows } = layoutConvert(
            GRID_COLUMNS,
            converterType,
            template,
            sectionItems
        );
        const items = converted.children.map((child, i) => {
            const conversionItemConfig = converted.config.item
                ? converted.config.item[i]
                : {};
            const alignment = getAlignment(props.verticalAlignment);
            return (
                <Grid.Item
                    {...converted.placement[i]}
                    viewport={viewport}
                    theme={theme}
                    align={alignment}
                    equalHeight={equalHeight}
                    key={i}
                    {...conversionItemConfig}
                >
                    {child}
                </Grid.Item>
            );
        });

        const conversionGridConfig = converted.config.grid || {};

        const row = (
            <Section css={sectionCss} sectionPadding={sectionPadding}>
                {width === 'normal' ? null : backgroundImage}

                <Placer css={placerCss}>
                    <Grid
                        templateRows={templateRows}
                        cols={GRID_COLUMNS}
                        gap={gap || theme.gap}
                        equalHeight={equalHeight}
                        {...conversionGridConfig}
                    >
                        {width === 'normal' ? backgroundImage : null}
                        {items}
                    </Grid>
                </Placer>
            </Section>
        );

        return useAnchorLink && anchorLink ? (
            <ScrollableAnchor id={anchorLink.replace(/#/g, '')}>
                {row}
            </ScrollableAnchor>
        ) : (
            row
        );
    }

    console.warn(
        'Section template array does not match Sections children array in length',
        sectionItems.length,
        template.length
    );
    return null;
};

SectionComponent.defaultProps = {
    getConverterType,
    verticalPadding: 'medium',
    equalHeight: false,
};

SectionComponent.propTypes = {
    getConverterType: PropTypes.func,
    children: PropTypes.any,
    backgroundValue: PropTypes.any,
    backgroundType: PropTypes.string,
    width: PropTypes.string,
    template: PropTypes.arrayOf(
        PropTypes.shape({
            width: PropTypes.string.isRequired,
        })
    ).isRequired,
    viewport: PropTypes.any,
    theme: PropTypes.any,
    blockName: PropTypes.string,
    verticalPadding: PropTypes.string,
    verticalAlignment: PropTypes.string,
    gap: PropTypes.object,
    useAnchorLink: PropTypes.bool,
    anchorLink: PropTypes.string,
    equalHeight: PropTypes.bool,
};

SectionComponent.parseProps = atts => {
    const gap = atts.removeGridGap ? { col: '1px', row: '0rem' } : null;
    return {
        gap,
        anchorLink: atts.anchorName ? `#${atts.anchorName}` : '',
        useAnchorLink: !!atts.anchorEnable,
        width: atts.removeSectionPadding ? 'contained' : '',
        ...atts,
    };
};

export default withTheme(SectionComponent);
