import { fetchResource, parseHeaderData } from '../helpers/fetch-helper';

export const REQUEST_HEADER = 'REQUEST_HEADER';
export const RECEIVE_HEADER = 'RECEIVE_HEADER';
export const HEADER_ERROR = 'HEADER_ERROR';

function requestHeader() {
    return {
        type: REQUEST_HEADER
    };
}

function receivedHeader(data) {
    return {
        type: RECEIVE_HEADER,
        payload: data
    };
}

function errorHeader(error) {
    return {
        type: HEADER_ERROR,
        error
    };
}

export function fetchHeader() {

    return async function (dispatch, state, requestOptions) {

        let url = `${state.settings.apiUrl}/header`;
        if (state.header.loading) {
            return true;
        }

        dispatch(requestHeader());

        try {
            const header = await fetchResource(url, parseHeaderData(state.requestData, requestOptions));
            dispatch(receivedHeader(header));
            return Promise.resolve();
        } catch (err) {
            dispatch(errorHeader());
            throw err;
        }

    };

}
