import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'react-cookies';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Global, css } from '@emotion/core';
import { toggleCookieConsent, setView } from '../../actions/cookie-consent';
import ToggleCookiePreferences from '../toggle-cookie-preferences';
import Modal from '../../components/bank/modal';
import Inner from './components/inner';
import OldCookieAlert from '../../components/cookie-warning';
import useCookieState from './hooks/use-cookie-state';
import { setDataLayer } from '../../helpers/gtm';
import * as views from './views';

const COOKIE_NAME = 'cookie_consent';

function sendConsentEvent() {
    setDataLayer({ event: 'cookie_consent_set' });
}

const mapStateToProps = state => {
    const { cookieConsent, generalOptions } = state;
    return {
        show: cookieConsent.show,
        firstTimeVisit: cookieConsent.firstTimeVisit,
        view: cookieConsent.view,
        cookieOptions: generalOptions.cookieConsent,
    };
};

const mapDispatchToProps = {
    toggleConsent: toggleCookieConsent,
    setView,
};

const CookiePreferences = ({
    toggleConsent,
    show,
    view,
    setView,
    cookieOptions,
    location,
}) => {
    const cookieMaxAge = +cookieOptions.max_age || 30; // In days
    const cookieTypes = cookieOptions.types || [];
    const defaultSettings = cookieTypes.reduce((acc, curr) => {
        const standardValue = curr.can_toggle ? 0 : 1;
        acc[curr.name] = standardValue;
        return acc;
    }, {});
    const [preferences, setPreferences] = useCookieState(
        COOKIE_NAME,
        defaultSettings,
        false,
        {
            maxAge: cookieMaxAge * 24 * 60 * 60,
            path: '/',
            saveCallback: sendConsentEvent,
            domain: cookieOptions.domain,
        }
    );

    // Used for the edit mode. We only want to save the settings when
    // the user clicks the save button, and therefor we need a separate state
    // that is a copy of the original preferences.
    const [clonedPreferences, setClonedPreferences] = useState(preferences);

    // Check if cookie types have been added or removed from Wordpress.
    // If so, we should prompt the user with the consent modal.
    const cookieTypesChanged =
        cookieTypes.filter(
            type => !Object.keys(preferences).includes(type.name)
        ).length > 0 || Object.keys(preferences).length !== cookieTypes.length;

    const hasAcceptedCookies =
        !!cookies.load(COOKIE_NAME) && !cookieTypesChanged;

    function saveSettings(settings) {
        setPreferences(settings);
        setClonedPreferences(settings);
    }

    function handleCookieLinkClicks(event) {
        if (cookieOptions.modal_url) {
            if (
                event?.target?.href &&
                event.target.href.includes(cookieOptions.modal_url)
            ) {
                // We have found a cookie button! Prevent default link behaviour.
                event.preventDefault();
                toggleConsent(true);
            }
        }
    }

    useEffect(() => {
        document.body.addEventListener('click', handleCookieLinkClicks);

        return () => {
            document.body.removeEventListener('click', handleCookieLinkClicks);
        };
    }, []);

    useEffect(() => {
        if (show) {
            // Determine the view
            setView(hasAcceptedCookies ? views.EDIT : views.OVERVIEW);
        }
    }, [show]);

    useEffect(() => {
        if (cookieOptions?.allowed_urls?.[location.pathname]) {
            toggleConsent(false);
            return;
        }
        if (cookieTypesChanged) {
            setClonedPreferences(defaultSettings);
        }
        let shouldShow = false;
        if (!('empty' in preferences) && cookieTypes.length === 0) {
            shouldShow = true;
        } else if (cookieTypes.length > 0) {
            shouldShow = show || !hasAcceptedCookies;
        }
        toggleConsent(shouldShow);
    }, [location.pathname]);
    const labels = cookieOptions.labels || {};

    function cancel() {
        /**
         * This if-statement prevents user that hasn't accepted cookies
         * to close the modal
         */
        if (hasAcceptedCookies) {
            toggleConsent(false);
            // Reset the settings to original state
            setClonedPreferences(preferences);
        } else {
            setView(views.OVERVIEW);
        }
    }

    const buttonsViewMap = {
        [views.OVERVIEW]: [
            {
                text: labels.accept_button || '',
                onClick: () => {
                    const prefs = Object.keys(defaultSettings).reduce(
                        (acc, curr) => {
                            acc[curr] = 1;
                            return acc;
                        },
                        {}
                    );
                    toggleConsent(false);
                    saveSettings(prefs);
                },
            },
            {
                text: 'Bare nødvendige cookies',
                onClick: () => {
                    toggleConsent(false);
                    saveSettings(defaultSettings);
                },
            },
            {
                text: labels.edit_button || '',
                color: 'black',
                background: 'white',
                border: {
                    shorthand: 'border',
                    color: 'darkGrey',
                },
                onClick: () => {
                    setView(views.EDIT);
                },
            },
        ],
        [views.EDIT]: [
            {
                text: labels.cancel_button || '',
                background: 'white',
                color: 'black',
                border: {
                    shorthand: 'border',
                    color: 'darkGrey',
                },
                onClick: cancel,
            },
            {
                text: labels.save_button || '',
                onClick: () => {
                    saveSettings(clonedPreferences);
                    toggleConsent(false);
                },
            },
        ],
    };

    const viewConfigs = {
        [views.OVERVIEW]: {
            description: cookieOptions.overview_description,
        },
        [views.EDIT]: {
            description: cookieOptions.edit_description,
            cookieTypes,
            preferences: clonedPreferences,
            setPreferences: val => {
                setClonedPreferences({ ...clonedPreferences, ...val });
            },
            mandatoryText: cookieOptions.mandatory_text,
        },
    };

    const closeIcon = hasAcceptedCookies
        ? {
              onClick: () => {
                  toggleConsent(false);
                  // Reset the settings to original state
                  setClonedPreferences(preferences);
              },
          }
        : null;

    const buttonsConfig = buttonsViewMap[view];
    if (cookieTypes.length === 0) {
        return (
            <OldCookieAlert
                open={show}
                handleAccept={() => {
                    cookies.save(COOKIE_NAME, 'empty');
                    toggleConsent(false);
                }}
            />
        );
    }

    return (
        <React.Fragment>
            <ToggleCookiePreferences toggleConsent={toggleConsent} />
            {show && (
                <Global
                    styles={css`
                        html {
                            pointer-events: none;
                            overflow: hidden !important;
                        }
                    `}
                />
            )}
            <Modal usePortal open={show} overlay closeIcon={closeIcon}>
                <Inner
                    view={view}
                    buttonsConfig={buttonsConfig}
                    preferences={preferences}
                    configs={viewConfigs}
                    flexDirection={view === views.OVERVIEW ? 'column' : 'row'}
                />
            </Modal>
        </React.Fragment>
    );
};

CookiePreferences.propTypes = {
    toggleConsent: PropTypes.func,
    show: PropTypes.bool,
    view: PropTypes.oneOf(Object.values(views)),
    setView: PropTypes.func.isRequired,
    cookieOptions: PropTypes.object,
    location: PropTypes.object,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CookiePreferences)
);
