import styled from '@emotion/styled';
import Icons from '../../../icons';

export const Inner = styled.div`
    background-color: white;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
    ${p => p.innerStyle}
`;

export const StyledIcon = styled(Icons)`
    position: absolute;
    z-index: 100000;
    cursor: pointer;
    pointer-events: auto;
    right: 0.5rem;
    top: 0.5rem;
    padding: 1rem;
    width: calc(20px + 2rem);
    height: calc(20px + 2rem);
`;
