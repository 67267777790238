import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../../../../../components/icons';
import { useTheme } from 'emotion-theming';
import { SlideContentHeight } from '../../../../../components/bank/modal';
import {
    StyledMenuItemList,
    StyledMenuItemFlex,
    StyledMenuItem,
    StyledMenuItemChildList,
    StyledMenuItemMessages,
    StyledMenuItemLink,
} from './style';

const MenuItem = ({
    handleExpandedItems,
    hasChildren,
    idToAdd,
    isCurrentPage,
    isExpandedItem,
    item,
    onToogleMenu,
    renderMenu,
}) => {
    const theme = useTheme();

    const onHandleExpandedItems = e =>
        hasChildren ? handleExpandedItems(idToAdd, e) : null;

    const link = (
        <StyledMenuItemLink
            currentPage={isCurrentPage}
            depth={item.depth}
            to={item.url}
            target={item.target}
            look="light"
            onClick={onToogleMenu}
        >
            {item.title}
            {item.unreadMessages ? (
                <StyledMenuItemMessages>
                    {item.unreadMessages}
                </StyledMenuItemMessages>
            ) : null}
        </StyledMenuItemLink>
    );

    const listItem = (
        <StyledMenuItem
            onClick={onHandleExpandedItems}
            onKeyDown={onHandleExpandedItems}
            role="menuitem"
        >
            {item.children ? (
                <React.Fragment>
                    <StyledMenuItemFlex>
                        {link}
                        <Icons
                            name={isExpandedItem ? 'minus' : 'plus'}
                            width={15}
                            height={15}
                            color={theme.colors.white}
                        />
                    </StyledMenuItemFlex>
                    <SlideContentHeight open={isExpandedItem} usePortal={false}>
                        <StyledMenuItemChildList
                            depth={item.depth}
                            expanded={isExpandedItem}
                        >
                            {renderMenu(item.children, item.depth + 1)}
                        </StyledMenuItemChildList>
                    </SlideContentHeight>
                </React.Fragment>
            ) : (
                link
            )}
        </StyledMenuItem>
    );

    if (item.depth == 0) {
        return (
            <StyledMenuItemList depth={item.depth}>
                {listItem}
            </StyledMenuItemList>
        );
    }

    return listItem;
};

MenuItem.propTypes = {
    handleExpandedItems: PropTypes.func,
    hasChildren: PropTypes.bool,
    idToAdd: PropTypes.number,
    isCurrentPage: PropTypes.bool,
    isExpandedItem: PropTypes.bool,
    item: PropTypes.object,
    onToogleMenu: PropTypes.func,
    renderMenu: PropTypes.func,
};

export default MenuItem;
