import './style.styl';

import React from 'react';
import PropTypes from 'prop-types';
import Link from '../link';
import ClassNames from 'classnames';
import { Icon } from 'react-icons-kit';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import { setDataLayer } from '../../helpers/gtm';
import { sendAsGoal } from '../../helpers/ab-first.js';
import Icons from '../icons';
import style from '../../variables/style.js';

class Button extends React.Component {

    static propTypes = {
        bgImage: PropTypes.bool,
        children: PropTypes.any,
        className: PropTypes.any,
        color: PropTypes.oneOf(['orange', 'purple', 'blue', 'inherit', 'white', 'black', 'bank-blue', 'dark-blue', 'pink']),
        disabled: PropTypes.bool,
        dropDownStyle: PropTypes.bool,
        fullWidth: PropTypes.bool,
        gtmCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        gtmLabel: PropTypes.string,
        gtmValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        imageOverlayType: PropTypes.string,
        invertOverlayColors: PropTypes.bool,
        linkTo: PropTypes.string,
        market: PropTypes.oneOf(['private', 'corporate', 'neutral']),
        onClick: PropTypes.func,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,
        size: PropTypes.oneOf(['small', 'medium', 'large', 'full', 'input']),
        target: PropTypes.oneOf(['_blank', '_self', '']),
        type: PropTypes.oneOf(['primary', 'secondary', 'bank']),
        useMargin: PropTypes.bool,
        variant: PropTypes.oneOf(['a', 'b', 'c']),
        nativeProps: PropTypes.object,
        abFirstGoal: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        hideArrow: PropTypes.bool,
    };

    static defaultProps = {
        linkTo: null,
        size: 'medium',
        type: 'primary',
        dropDownStyle: false,
        disabled: false,
        useMargin: true,
        variant: 'a',
        hideArrow: false,
    };

    sendGtm = (e) => {
        let category = this.props.gtmCategory
            ? this.props.gtmCategory.title || this.props.gtmCategory
            : '';
        if (category.error) {
            category = 'click';
        }
        let value = this.props.gtmValue ? this.props.gtmValue : '';
        if (category !== '' || value !== '' || this.props.gtmLabel !== '') {
            setDataLayer({
                action: 'click',
                category,
                label: this.props.gtmLabel,
                value,
            });
        }
        if (typeof this.props.onClick === 'function') {
            this.props.onClick(e);
        }
    }

    handleClick = e => {
        const { abFirstGoal, linkTo } = this.props;
        if (abFirstGoal && abFirstGoal.useAsGoal) {
            sendAsGoal(abFirstGoal.abTestUuid);
        }

        if (!linkTo) {
            this.sendGtm(e);
        }
    };

    /*
        We decide the color on the button in the following order:
        1) If a color has been specified we use it
        2) If a market has been passed, we convert it to a color
        3) Orange will be default otherwise
    */
    getButtonColor() {
        const { market, color } = this.props;
        if (color && color !== 'inherit') {
            return color;
        }
        if (market) {
            switch (market) {
                case 'private':
                    return 'orange';
                case 'corporate':
                    return 'purple';
                case 'neutral':
                    return 'blue';
            }
        }
        return 'orange';
    }

    linkLook() {
        const { variant, type } = this.props;

        const looks = {
            'primary-a-orange': 'light',
            'primary-a-purple': 'light',
            'primary-a-white': 'dark',
            'primary-c-white': 'dark',
            'secondary-a-orange': 'light',
        };

        return looks[`${type}-${variant}-${this.getButtonColor()}`];
    }

    render() {

        let {
            useMargin,
            linkTo,
            children,
            size,
            type,
            market,
            target,
            imageOverlayType,
            bgImage,
            variant,
            fullWidth,
            nativeProps,
            hideArrow,
        } = this.props;


        let useArrow =
            type === 'bank' ||
            (type === 'primary' && variant === 'b') ||
            (type === 'secondary' && variant === 'a') ||
            (type === 'primary' && variant === 'c');

        // Not sure if we want to pass these classes when returning a Link Component
        // But when a button contains a link we want it to look like a button...
        const buttonColor = this.getButtonColor();
        const buttonClasses = ClassNames(
            this.props.className,
            { [`button--type-${type}`]: true },
            {
                [`button--type-secondary--color-${buttonColor}`]:
                    type === 'secondary',
            },
            {
                ['button--type-secondary--light-overlay']:
                    imageOverlayType === 'light'
                        ? type === 'secondary' && true
                        : null,
            },
            {
                ['button--type-secondary--on-overlay']: bgImage
                    ? type === 'secondary' &&
                      imageOverlayType !== 'light' &&
                      true
                    : null,
            },
            {
                [`button--type-primary--color-${buttonColor}`]:
                    type !== 'secondary',
            },
            { [`button--size-${size}`]: true },
            { [`button--variant-${variant}`]: true },
            { [`button--market-${market}`]: market },
            { ['button--no-margin']: !useMargin },
            { ['button--with-arrow']: useArrow },
            { ['button--full-width']: fullWidth },
            {
                ['button--type-secondary--bordered']:
                    type === 'secondary' && variant === 'b',
            },
            this.props.dropDownStyle ? 'button--dropdown-style' : ''
        );

        let arrow = null;
        if (useArrow) {
            arrow =
                type === 'primary' ? (
                    <div
                        className={`button--arrow-right-icon--${this.props.size}`}
                    >
                        <Icons
                            name="arrowRightWithTail"
                            color={
                                buttonColor !== 'white'
                                    ? style.colors.colorRawbWhite
                                    : style.colors.colorRawbBlack
                            }
                        />
                    </div>
                ) : (
                    <div
                        className={`button--arrow-right-icon--${this.props.size}`}
                    >
                        <Icons
                            name="arrowRightWithTail"
                            color={
                                type === 'bank'
                                    ? style.colors.colorRawbBlack
                                    : style.colors.colorResursOrange
                            }
                        />
                    </div>
                );
        }

        if (linkTo) {
            return (
                <Link
                    className={buttonClasses}
                    to={linkTo}
                    look={this.linkLook()}
                    onClick={this.handleClick}
                    target={target}
                >
                    <div className="button--text-content">{children}</div>
                    {!hideArrow && arrow}
                </Link>
            );
        }

        let chevron = null;
        if (this.props.dropDownStyle) {
            chevron = <Icon size={18} className="dropdown--chevron" icon={chevronDown} />;
        }

        return (
            <button
                className={buttonClasses}
                disabled={this.props.disabled}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                onClick={this.handleClick}
                {...nativeProps}
            >
                <span>{children || 'Button'}</span>
                {chevron}
            </button>
        );
    }
}

export default Button;
