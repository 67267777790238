/**
 * @file             : services/frontend/src/www-src/scripts/containers/page/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 15.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { fetchPage } from '../../actions/page';

import DynamicModuleGenerator from '../../components/dynamic-module-generator';
import ContentBlockModule from '../../components/content-block-module';
import Row from '../../components/row';
import Column from '../../components/column';
import Image from '../../components/image';
import Headline from '../../components/headline';
import { loadingOrErrorCheck, LoadingAndError } from '../loading-and-error';
import FeaturedImage from '../../components/featured-image';
import { pushToAnalyticsQueue } from '../../helpers/ab-first.js';

import { StyledPageContainer, StyledFeaturedImageContainer } from './style';

class Page extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        header: PropTypes.object,
        headerMenu: PropTypes.object,
        history: PropTypes.object,
        location: PropTypes.shape({
            search: PropTypes.string,
        }),
        language: PropTypes.string,
        match: PropTypes.object,
        mobile: PropTypes.string,
        page: PropTypes.object,
        user: PropTypes.object,
        showBackButton: PropTypes.bool,
        viewport: PropTypes.object,
        notFoundPage: PropTypes.string,
    };

    static contextTypes = {
        store: PropTypes.object,
        registerFetchAction: PropTypes.func,
        localize: PropTypes.func,
        imageHost: PropTypes.string,
    };

    componentWillMount() {
        const slug = (this.props.match.params.slug || 'start');

        this.context.registerFetchAction(
            fetchPage(slug),
            'fetch-page',
            this.props.match.params.slug
        );
    }

    trackEvent(page) {
        const { abFirst } = window;
        if (abFirst && abFirst.testsWithPageAsGoal && abFirst.userAssignments) {
            // Check if this page is the goal for any AB Tests
            const testWithPageAsGoal = abFirst.testsWithPageAsGoal.find(test => test.goalPageID === page.content.id);
            if (testWithPageAsGoal) {
                // Check if user is participant in the AB Test
                const assignment = abFirst.userAssignments.find(variant => variant.abTestUuid === testWithPageAsGoal.uuid);
                if (assignment && assignment.participant) {
                    pushToAnalyticsQueue('event', 'A/B First', testWithPageAsGoal.name, `${assignment.variant}:converted`);
                }
            }
        }
    }


    componentDidMount() {
        const { page } = this.props;
        if (page && page.content) {
            this.trackEvent(page);
        }
    }

    componentDidUpdate(prevProps) {
        const { page } = this.props;
        const prevPage = prevProps.page || {};
        if (page && page.content !== prevPage.content) {
            this.trackEvent(page);
        }
    }

    _getRoot() {
        const { market_type: marketType } = this.props.page.content;
        const marketItems = this.props.header.headerData.menu;
        const marketItem = marketItems.find(
            item => item.market_type === marketType
        );

        return {
            id: marketItem && marketItem.id,
            permalink: marketItem ? marketItem.url : '/',
            post_title: marketItem
                ? marketItem.title
                : this.context.localize('home'),
        };
    }

    _parseBreadcrumb(page) {
        let { permalink } = page;
        // previously, ancestors in public pages had title set instead of post_title. This has been changed (in headless-data.php)
        // but to avoid having to resave all pages to use the new format we check for both here.
        let title = page.post_title || page.title;

        return {
            ...page,
            title,
            permalink,
        };
    }

    getPageSettings() {
        const { dispatch, location, page } = this.props;
        const pageSettings = [page.content];

        if (page.content.ancestors) {
            pageSettings.push(...page.content.ancestors);
        }

        const currentQuery = queryString.parse(location.search);
        const params = {
            currentQuery,
            dispatch,
            store: this.context.store,
        };
        const result = {
            breadcrums: pageSettings.map(breadcrumb =>
                this._parseBreadcrumb(breadcrumb, params)
            ),
            backButton: pageSettings[0].back_button,
        };
        return result;
    }

    getPageTitle(page) {
        switch (page.title_type) {
            case 'custom':
                return page.custom_title;
            case 'none':
                return null;
            default:
                return page.post_title || page.title;
        }
    }

    pageTypeCheck() {
        return true;
    }

    renderHelmet() {
        const { page, language } = this.props;
        const { content } = page;

        const ogImage = (content.og_image || content.featured_image && content.featured_image.src) &&
            Image.getImageServiceSrcPath(
                this.context.imageHost,
                content.og_image || content.featured_image,
                1200,
                'AUTO'
            );

        return (
            <Helmet>
                {content.page_title &&
                    <title>{content.page_title}</title>
                }
                <link rel="canonical" href={content.canonical_url} />
                {content.meta_title &&
                    <meta
                        name="title"
                        content={content.meta_title}
                    />
                }
                {content.meta_description &&
                    <meta
                        name="description"
                        content={content.meta_description}
                    />
                }
                <link
                    rel="alternate"
                    hreflang={language}
                    href={content.canonical_url}
                />
                {(content.og_title || content.page_title) &&
                    <meta
                        property="og:title"
                        content={content.og_title || content.page_title}
                    />
                }
                {(content.og_description || content.meta_description) &&
                    <meta
                        property="og:description"
                        content={content.og_description || content.meta_description}
                    />
                }
                {ogImage && <meta property="og:image" content={ogImage} />}
                <meta property="og:url" content={content.canonical_url} />
            </Helmet>
        );
    }
    getContent(page, mobile, viewport) {
        if ((page.content.blocks && page.content.blocks.length !== 0) || page.content.vc_content) {

            // First look for gutenberg content, then fallback to vc content
            let content;
            if (page.content.blocks && page.content.blocks.length !== 0) {
                content = page.content.blocks;
            } else {
                content = page.content.vc_content;
            }


            return (
                <DynamicModuleGenerator
                    key='content'
                    permalink={page.content.permalink}
                    mobile={mobile}
                    content={content}
                    viewport={viewport}
                    flags={{
                        featuredImage: !!page.content.featured_image,
                    }}
                />
            );
        } else {
            return (
                <div className="page--standard-content">
                    <Row>
                        <Column width="1/1" viewport={viewport}>
                            <div className="page--standard-content-title">
                                <Headline tag={1}>
                                    {page.content.post_title}
                                </Headline>
                            </div>
                            <ContentBlockModule>
                                {page.content.post_content}
                            </ContentBlockModule>
                        </Column>
                    </Row>
                </div>
            );
        }
    }

    render() {
        const {
            headerMenu,
            mobile,
            page,
            notFoundPage,
            viewport,
        } = this.props;

        if (loadingOrErrorCheck(page, 'content')) {
            return (
                <LoadingAndError
                    item={page}
                    contentKey="content"
                    noInitialTransition={!!mobile}
                />
            );
        }

        const featuredImage =
            page.content.featured_image && page.content.featured_image.src;

        const featuredHeader = featuredImage && page.content.featured_header;

        const addExtraPaddingTop =
            !featuredImage && !!headerMenu.selectedSubMenuId;

        if (!this.pageTypeCheck()) {
            return <Redirect to={notFoundPage} />;
        }


        return (
            <StyledPageContainer paddingTop={addExtraPaddingTop} lightGray={page.content?.page_background_color === 'lightGray'}>
                {this.renderHelmet()}

                {featuredImage && (
                    <StyledFeaturedImageContainer>
                        <FeaturedImage
                            viewport={viewport}
                            featuredImage={page.content.featured_image}
                            featuredImageTextFromContent={
                                page.content.featured_image_text
                            }
                            featuredImageAltText={
                                page.content.featured_image_alt_text
                            }
                            featuredHeader={{
                                active: !!featuredHeader,
                                size: (featuredHeader && page.content.featured_header_size) || 'default',
                            }}
                        />
                    </StyledFeaturedImageContainer>
                )}

                {this.getContent(page, mobile, viewport)}
            </StyledPageContainer>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const slug = `${ownProps.match.params.slug || 'start'}`;
    const page = state.pages.list[slug];
    return {
        header: state.header,
        headerMenu: state.headerMenu,
        language: state.localization.language,
        mobile: state.settings.mobile,
        slug,
        page,
        user: state.user,
        viewport: state.viewport,
        notFoundPage: state.generalOptions.systemPages.notFoundPage,
    };
};

const PageContainer = connect(mapStateToProps)(Page);

export default PageContainer;
