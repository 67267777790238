/**
 * @file             : services/frontend/src/www-src/scripts/actions/post-resource.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 10.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import { fetchResource, parseHeaderData } from '../helpers/fetch-helper';
import { clearTitle, setTitle } from './info';

export const REQUEST_RESOURCE = 'REQUEST_RESOURCE';
export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE';
export const ACTIVATE_RESOURCE = 'ACTIVATE_RESOURCE';
export const RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND';
export const SORT_RESOURCES_BY_TAG = 'SORT_RESOURCES_BY_TAG';
export const ACTIVATE_CURRENT_RESOURCE = 'ACTIVATE_CURRENT_RESOURCE';
export const REQUEST_SINGLE_POST = 'REQUEST_SINGLE_POST';
export const RECEIVE_SINGLE_POST = 'RECEIVE_SINGLE_POST';
export const SINGLE_POST_NOT_FOUND = 'SINGLE_POST_NOT_FOUND';

const isCurrentTagComparator = (a, b, tagSlug) => {
    let tagsA = a.tags.map((tag) => { return tag.slug; });
    let tagsB = b.tags.map((tag) => { return tag.slug; });

    let tagAContains = false;
    if (tagsA.indexOf(tagSlug) !== -1) {
        tagAContains = true;
    }
    if (tagsB.indexOf(tagSlug) !== -1) {
        return tagAContains ? 0 : 1;
    }

    return tagAContains ? -1 : 1;
};

function requestResource(slug, postsPerPage, handlerId) {
    return {
        type: REQUEST_RESOURCE,
        slug,
        postsPerPage,
        handlerId,
    };
}

function requestSinglePost(postSlug) {
    return {
        type: REQUEST_SINGLE_POST,
        postSlug
    };
}

function activateResource(slug, handlerId) {
    return {
        type: ACTIVATE_RESOURCE,
        slug,
        handlerId,
    };
}

function receivedResource(slug, resources, handlerId) {
    return {
        type: RECEIVE_RESOURCE,
        slug,
        resources,
        handlerId,
    };
}

export function receivedSinglePost(post, postSlug) {
    return dispatch => {
        dispatch({
            type: RECEIVE_SINGLE_POST,
            post,
            postSlug
        });
    };
}

function notFound(slug, err) {
    return {
        type: RESOURCE_NOT_FOUND,
        slug,
        message: err
    };
}

function singlePostNotFound(postSlug, err) {
    return {
        type: SINGLE_POST_NOT_FOUND,
        postSlug,
        message: err
    };
}

function activateSortResourceByTag (resourceSlug, tagSlug, sortedResources) {
    return {
        type: SORT_RESOURCES_BY_TAG,
        slug: resourceSlug,
        tagSlug,
        sortedResources
    };
}

function _activateCurrentResource (resourceSlug, resourcePermalink) {
    return {
        type: ACTIVATE_CURRENT_RESOURCE,
        slug: resourceSlug,
        resourcePermalink
    };
}

/**
 * Fetches a list of posts
 * @param  {[String]} resourceSlug [Slug of the page/project that will contain the list of fetched posts]
 * @param  {[Object]} filter      [key/value object of the filter params, such as limit:10 or offset: 20 that will
 * affect the content service query. Supported keys in the content service is
 *     resourceDependsOn [string of resources]
 *     orderBy ['DESC|ASC'],
 *     order [column field in content service database],
 *     limit [10],
 *     offset [0]
 * ]
 */
export function fetchPostResources(resourceSlug, filter) {

    return async function (dispatch, state, requestOptions) {
        let handlerId = filter.handlerId;
        let postResources = state.postResources.list[resourceSlug];
        if (postResources && (postResources.loading || postResources.error)) {
            return true;
        }

        let postsPerPage = filter.limit ? filter.limit : 10;
        let queryStringKeyValues = [];
        for (let [k, v] of Object.entries(filter)) {
            queryStringKeyValues.push(`${k}=${v}`);
        }

        let queryString = queryStringKeyValues.join('&');
        let url = `${state.settings.apiUrl}/post-resources/fetch-posts/?${queryString}`;

        dispatch(requestResource(resourceSlug, postsPerPage, handlerId));

        if (postResources && !postResources.hasMore) {
            dispatch(activateResource(resourceSlug, handlerId));
        } 

        try {
            const fetchedPostResources = await fetchResource(url, parseHeaderData(state.requestData, requestOptions));
            dispatch(receivedResource(resourceSlug, fetchedPostResources, handlerId));
            return Promise.resolve();
        } catch (err) {
            dispatch(notFound(resourceSlug, err));
            throw err;
        }

    };

}

export function fetchSinglePost(postSlug) {

    return async function (dispatch, state, requestOptions) {

        let url = `${state.settings.apiUrl}/page/${postSlug}`;
        dispatch(requestSinglePost(postSlug));

        try {

            const post = await fetchResource(url, parseHeaderData(state.requestData, requestOptions));
            dispatch(receivedSinglePost(post, postSlug));

            // Clear the title of the page, since we know that we are in the root of a program
            dispatch(clearTitle());

            // The the first part of the title to the project title
            dispatch(setTitle(post.post_title, 0));

            return Promise.resolve();

        } catch (err) {
            dispatch(singlePostNotFound(postSlug, err));
            throw err;
        }

    };

}

export function activateCurrentPostResource(resourceSlug, resourcePermalink) {
    return async function (dispatch) {

        dispatch(_activateCurrentResource(resourceSlug, resourcePermalink));

        return Promise.resolve();
    };
}


export function sortPostResourceByTag(resourceSlug, tagSlug) {
    return async function (dispatch, state) {
        let postResources = state.postResources.list[resourceSlug].resources;
        postResources.sort((a, b) => {
            return isCurrentTagComparator(a, b, tagSlug);
        });

        dispatch(activateSortResourceByTag(resourceSlug, tagSlug, postResources));

        return Promise.resolve();
    };
}
