import styled from '@emotion/styled';

export const StyledMenuWapper = styled.div`
    padding: 3rem 3rem 80px;
    height: 100%;
    width: 100%;
    z-index: 200;
    background: white;
    overflow-y: scroll;
    background: ${p => p.theme.colors.darkBlue};
`;

export const StyledMenuWapperHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
`;
