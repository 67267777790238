import styled from '@emotion/styled';

export const StyledOrderedList = styled.ol`
    counter-reset: item;
    padding-left: 0.8em;
    margin-left: 0;

    li {
        list-style-type: none;
        counter-increment: item;
        margin-bottom: 2rem;
    }

    li:before {
        display: inline-block;
        min-width: 1.5em;
        text-align: center;
        background-color: #67bc44;
        color: white;
        border-radius: 3em;
        content: counter(item);
        margin-right: 0.7em;
    }
`;

export const StyledList = styled.ul`
    text-align: left;
    padding-left: 3.5rem;
    margin-bottom: .75em;
    margin-top: 1.3em;
    border-left: 5px solid ${ p => p.color ? p.theme.colors[p.color] : 'black'};

    li {
        line-height: 1.5em;
        margin-bottom: .4em;
        list-style: none;
    }
`;
