import React from 'react';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../../components/icons';
import AlertTicker from '../../components/alert-ticker';
import { showAlert, closeAlert } from '../../actions/alerts';
import './style.styl';

class Alerts extends React.Component {
    static propTypes = {
        alerts: PropTypes.array,
        fixed: PropTypes.bool,
        show: PropTypes.bool,
        closeAlert: PropTypes.func,
        shouldShowAlert: PropTypes.func,
        mobile: PropTypes.string,
    };

    state = {
        closeBtnFocused: false,
        isFocused: false,
    };

    componentDidMount() {
        this.props.shouldShowAlert(this.props.alerts);
    }

    handleKeyDown = e => {
        e.preventDefault();
        if (e.keyCode === 32) {
            this.props.closeAlert(this.props.alerts.map(alert => alert.id));
        }
    };

    render() {
        const { alerts, show, closeAlert, mobile, fixed } = this.props;

        if (!show) {
            return null;
        }

        if (!alerts || !alerts.length) {
            return null;
        }

        const alertsClassNames = cx({
            alerts: true,
            'alerts--fixed': fixed,
        });

        return (
            <div
                className="alerts--container"
                onMouseEnter={() =>
                    mobile ? null : this.setState({ isFocused: true })
                }
                onMouseLeave={() =>
                    mobile ? null : this.setState({ isFocused: false })
                }
                onClick={() =>
                    this.setState({ isFocused: !this.state.isFocused })
                }
            >
                <div
                    className="alerts--close-btn"
                    onClick={() => closeAlert(alerts.map(alert => alert.id))}
                    role="button"
                    tabIndex="0"
                    aria-label="Close alert"
                    onFocus={() => this.setState({ closeBtnFocused: true })}
                    onBlur={() => this.setState({ closeBtnFocused: false })}
                    onKeyDown={this.handleKeyDown}
                >
                    <Icon name="cross" color="white" height="20" width="20" />
                </div>
                <div className={alertsClassNames}>
                    <AlertTicker
                        speed={mobile ? 8 : 7}
                        alerts={alerts}
                        move={!this.state.isFocused}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ alerts, generalOptions, settings }) => {
    return {
        alerts: alerts.alerts,
        openAlerts: alerts.openAlerts,
        show: alerts.showAlert,
        interval: generalOptions.alertCarouselInterval,
        mobile: settings.mobile,
    };
};

const dispatchToProps = dispatch => ({
    shouldShowAlert: (ids = []) => {
        const closedAlerts = loadCookie() ? loadCookie().split(',') : [];
        ids.length > 0 && ids.length !== closedAlerts.length
            ? dispatch(showAlert())
            : dispatch(closeAlert(closedAlerts));
    },
    closeAlert: ids => {
        setCookie(ids);
        dispatch(closeAlert());
    },
});

const loadCookie = () => cookie.load('alert_closed') || '';

const setCookie = alertIds => {
    cookie.save('alert_closed', alertIds.join(','));
};

export default connect(mapStateToProps, dispatchToProps)(Alerts);
