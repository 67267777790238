import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import './style.styl';
import Icons from '../icons';

const ToolTip = ({
    className,
    info,
    width = 14,
    height = 14,
    tooltipClassName,
    effect,
    type = 'dark',
    html,
    dataClass,
    dataPlace = 'top',
    clickable,
}) => {
    if (!info) {
        return null;
    }
    return (
        <div className={className}>
            <div
                className="tooltip--icon"
                data-html={html}
                data-place={dataPlace}
                data-class={dataClass}
                data-tip={info}
            >
                <Icons width={width} height={height} name="helpCircled" />
            </div>
            <ReactTooltip
                html={html}
                className={tooltipClassName}
                effect={effect}
                type={type}
                {...(clickable && {
                    clickable: true,
                    event: 'click',
                    globalEventOff: 'click',
                })}
            />
        </div>
    );
};

ToolTip.propTypes = {
    className: PropTypes.string,
    effect: PropTypes.oneOf(['float, solid']),
    info: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    tooltipClassName: PropTypes.string,
    html: PropTypes.bool,
    dataClass: PropTypes.string,
    dataPlace: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    clickable: PropTypes.bool,
    type: PropTypes.oneOf([
        'dark',
        'success',
        'warning',
        'error',
        'info',
        'light',
    ]),
};

export default ToolTip;
