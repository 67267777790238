import PropTypes from 'prop-types';
import { css } from '@emotion/core';

import Input from '../../components/input';
import Button from '../../components/button-component';
import Text from '../../components/text';

import validators from '../../helpers/validators';

import { StyledContainer, StyledHeader } from './style';
import { useState } from 'react';

const Newsletter = ({ labels, title }) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const subscribeToNewsletter = async () => {
        try {
            setLoading(true);
            const url = '/api/newsletter';
            const options = {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                }),
            };

            const response = await fetch(url, options);
            const json = await response.json();
            setSubmitError(!json.success);
            setSubmitted(true);
        } catch (error) {
            setSubmitError(true);
            setSubmitted(false);
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = () => {
        if (!validators.email(email) || submitted || loading) {
            return;
        }

        subscribeToNewsletter();
    };

    return (
        <StyledContainer>
            <StyledHeader>{title}</StyledHeader>
            <Input
                disabled={loading}
                id="newsletter-email"
                name="newsletter-email"
                required={true}
                value={email}
                onChange={({ target: { value } }) => {
                    setEmail(value);
                }}
                validate={validators.email}
                placeholder={labels.emailPlaceholder}
                errorMessage={labels.emailErrorText}
                inputMode="email"
                showLabel={false}
                generalRequiredErrorMessage={labels.emailErrorText}
                css={css`
                    margin-bottom: 2rem;
                    max-width: 100%;
                    input {
                        max-width: 100%;
                    }
                `}
            />
            {submitted && !submitError && (
                <Text color="green">{labels.emailSuccess}</Text>
            )}
            <Button
                background="white"
                color="#683277"
                fullWidth
                textAlign="center"
                css={css`
                    font-weight: bold;
                `}
                onClick={onSubmit}
            >
                {labels.signUp}
            </Button>
        </StyledContainer>
    );
};

Newsletter.propTypes = {
    labels: PropTypes.object,
    title: PropTypes.string,
};

export default Newsletter;
