import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';

const sizeMap = {
    small: 'base',
    medium: 'medium',
    large: 'xlarge',
};

export const StyledSpacer = styled.div`
    background: transparent;
    padding: ${p => theme.spacing[sizeMap[p.size || 'medium']]} 0;
`;