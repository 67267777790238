
const setCommonReducerProperties = ({
    loading = false,
    loaded = false,
    error = false
} = {}) => ({
    loading,
    loaded,
    error
});

export default setCommonReducerProperties;