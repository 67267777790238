/**
 * The stack below converter, i.e it stacks columns as full width rows in same sequence as columns are.
 *
 * @param {Number} gridCols Number of grid columns, most likely 12.
 * @param {Array} template Array of objects with 'width' information, for example {width: '1/3'}
 * @param {Array} children Array of columns.
 * @returns placement, children and empty grid config.
 */
const stackBelow = (gridCols, template, children) => {
    let rowStart = 1;
    let rowEnd = 1;
    const placement = template.map(() => {
        return {
            col: {
                s: 1,
                e: 1 + gridCols
            },
            row: {
                s: rowStart++,
                e: ++rowEnd
            }
        };
    });

    return {
        placement,
        children,
        config: {}
    };
};

export default stackBelow;
