import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { shouldFetch } from '../../helpers/fetch-helper';
import { fetchFooter } from '../../actions/footer';
import FooterComponent from '../../components/footer';
import './style.styl';

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        if (shouldFetch({ entity: this.props.footer })) {
            this.context.registerFetchAction(fetchFooter(), 'fetch-footer');
        }
    }

    render() {
        const { footer, viewport, version, showTop, isLoggedIn } = this.props;
        const shouldRenderTopSection = isLoggedIn ? false : showTop;
        return (
            <FooterComponent
                copyright={footer.copyright}
                socialLinks={footer.social_links}
                columns={footer.columns}
                viewport={viewport}
                version={version}
                showTop={shouldRenderTopSection}
            />
        );
    }
}

Footer.contextTypes = {
    registerFetchAction: PropTypes.func,
};

Footer.propTypes = {
    footer: PropTypes.shape({
        copyright: PropTypes.string,
        // eslint-disable-next-line camelcase
        social_links: PropTypes.arrayOf(
            PropTypes.shape({
                network: PropTypes.string.isRequired,
                href: PropTypes.string.isRequired,
            })
        ).isRequired,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })
        ).isRequired,
    }).isRequired,
    viewport: PropTypes.object,
    version: PropTypes.string,
    showTop: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
};

const mapStateToProps = state => ({
    footer: state.footer,
    isLoggedIn: state.user.isAuthenticated,
});

export default connect(mapStateToProps)(Footer);
