const initialState = {
    languages: {},
    language: ''
};

function localization(state = initialState) {
    return state;
}

export default localization;
