import React from 'react';
import PropTypes from 'prop-types';
import { StyledSvg } from './svg-icon.style';

const SvgIcon = ({
    background,
    backgroundOpacity = 0,
    children,
    className,
    color,
    fill,
    height,
    fullHeight,
    inButton,
    onClick,
    padding,
    placement,
    placementValue,
    shape,
    version = '1.1',
    viewBox,
    width,
    x = '0px',
    xmlns = 'http://www.w3.org/2000/svg',
    y = '0px',
}) => {
    return (
        <StyledSvg
            className={className}
            version={version}
            xmlns={xmlns}
            x={x}
            y={y}
            viewBox={viewBox}
            width={width}
            height={height}
            fullHeight={fullHeight}
            background={background}
            backgroundOpacity={backgroundOpacity}
            color={color}
            inButton={inButton}
            fill={fill || color || 'currentColor'}
            onClick={onClick}
            padding={padding}
            placement={placement}
            placementValue={placementValue}
            {...(shape && { $shape: shape })}
        >
            {children}
        </StyledSvg>
    );
};

SvgIcon.propTypes = {
    background: PropTypes.string,
    backgroundOpacity: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    fill: PropTypes.string,
    fullHeight: PropTypes.bool,
    height: PropTypes.number,
    inButton: PropTypes.bool,
    onClick: PropTypes.func,
    padding: PropTypes.string,
    placement: PropTypes.oneOf(['left', 'right']),
    placementValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shape: PropTypes.oneOfType([
        PropTypes.oneOf(['square', 'circle']),
        PropTypes.bool,
    ]),
    version: PropTypes.string,
    viewBox: PropTypes.string,
    width: PropTypes.number,
    x: PropTypes.string,
    xmlns: PropTypes.string,
    y: PropTypes.string,
};

export default SvgIcon;
