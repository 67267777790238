const analyzeDecoratedData = (decorated = {}) => {

    if (decorated.abTests) {
        if (decorated.abTests.userAssignments) {
            window.abFirst.userAssignments = window.abFirst.userAssignments || [];
            window.abFirst.userAssignments.push(...decorated.abTests.userAssignments);
        }
        if (decorated.abTests.testsWithPageAsGoal) {
            window.abFirst.testsWithPageAsGoal = window.abFirst.testsWithPageAsGoal || [];
            window.abFirst.testsWithPageAsGoal.push(...decorated.abTests.testsWithPageAsGoal);
        }

    }

};

module.exports = {
    analyzeDecoratedData,
};

