import React from 'react';
import PropTypes from 'prop-types';
import DescriptionBox from '../../description-box';
import Text from '../../../../../components/text';
import ToggleSection from '../../toggle-section';
import { StyledToggleSectionsContainer } from './style';

const Edit = ({ config }) => {
    const {
        description,
        cookieTypes,
        preferences,
        setPreferences,
        mandatoryText,
    } = config;

    return (
        <React.Fragment>
            <DescriptionBox>
                <Text as="div" dangerouslySetInnerHTML>
                    {description}
                </Text>
            </DescriptionBox>
            <StyledToggleSectionsContainer>
                {cookieTypes.map((type, index, arr) => {
                    const currentPreferenceValue = preferences[type.name];
                    const enabled = currentPreferenceValue == 1;
                    const typeRequiredBy = arr.find(
                        t => t.cookie_requires === type.name
                    );
                    function handleOnToggle() {
                        const newToggleState =
                            currentPreferenceValue == 1 ? 0 : 1;
                        const newPrefs = {
                            [type.name]: currentPreferenceValue == 1 ? 0 : 1,
                        };
                        // Check if this type requires another type to be active
                        if (type.cookie_requires) {
                            newPrefs[type.cookie_requires] = 1;
                        }

                        /**
                        * If the toggle we are untoggling is required by another type
                        * we need to untoggle that one aswell.
                        */
                        if (newToggleState == 0 && typeRequiredBy) {
                            newPrefs[typeRequiredBy.name] = 0;
                        }
                        setPreferences(newPrefs);
                    }
                    return (
                        <ToggleSection
                            key={index}
                            title={type.title}
                            description={type.description}
                            canToggle={type.can_toggle}
                            mandatoryText={mandatoryText}
                            toggled={enabled}
                            onToggle={handleOnToggle}
                        />
                    );
                })}
            </StyledToggleSectionsContainer>
        </React.Fragment>
    );
};

Edit.propTypes = {
    config: PropTypes.shape({
        description: PropTypes.string,
        cookieTypes: PropTypes.array,
        preferences: PropTypes.object,
        setPreferences: PropTypes.func,
        mandatoryText: PropTypes.string,
    }),
};

export default Edit;
