export const SET_HUMANY_VIEW = 'SET_HUMANY_VIEW';
export const LIST_VIEW = 'LIST_VIEW';
export const GUIDE_VIEW = 'GUIDE_VIEW';

export function setView(view) {
    return async function(dispatch) {
        dispatch({
            type: SET_HUMANY_VIEW,
            view,
        });
    };
}
