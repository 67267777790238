// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { getItemByUrl, getParentsByItem } from './';

export const useExpandedItems = ({ menuItems, currentPage }) => {
    const [expandedItems, setExpandedItems] = useState([]);

    useEffect(() => {
        if (menuItems) {
            const currentPageItem = getItemByUrl(menuItems, currentPage);

            if (!currentPageItem) {
                return;
            }

            const isTopLevelItem =
                currentPageItem.menu_item_parent === '0' &&
                currentPageItem.has_children;

            const parentsIds = getParentsByItem(
                menuItems,
                currentPageItem.ID,
                Number(currentPageItem.menu_item_parent)
            );

            // prettier-ignore
            setExpandedItems(
                isTopLevelItem
                    ? [currentPageItem.ID]
                    : parentsIds
            );
        }
    }, [menuItems, currentPage]);

    return {
        expandedItems,
        setExpandedItems,
    };
};
