// Create a basic skeleton for a single row column
const getVirtualSection = ({width, start, end}) => {
    return {
        blockName: 'next24hr/section',
        verticalPadding: 'big',
        verticalAlignment: 'top',
        contentFullWidth: false,
        ['template']: [
            {
                width,
                start,
                end,
            }
        ],
        blocks: [
            {
                blockName: 'next24hr/column',
                blocks: []
            }
        ]
    };
};



// First version (1.0.0) of a wordpress blocks content converter to our classic row/column
// structure.
// This function makes sure that any none-columns blocks are wrapped in a row/column.
const convertToVirtualSections = (content) => {

    if (!content) {
        return content;
    }


    let parsedContent = [];

    let current = getVirtualSection({width: '1/1'});

    // Iterate through all top level blocks and save all blocks that
    // arent in core/columns or core/blocks in an array
    // until we encounter a core/columns, core/blocks or we hit the end.
    // When we do that, we push the array into the content array.
    // If we encounter a core/columns, we just add it.
    // The same for core/block, but we make sure that their inner content is
    // also wrapped in a row/columns with the same rules as above.
    for (let i = 0, ii = content.length; i < ii; i++) {

        let block = content[i];

        if (block.blockName === 'next24hr/section') {
            if (current.blocks[0].blocks.length > 0) {
                parsedContent.push(current);
                current = getVirtualSection({width: '1/1'});
            }
            parsedContent.push(block);
        } else {
            current.blocks[0].blocks.push(block);
        }
    }

    if (current.blocks.length > 0 && current.blocks[0].blocks.length > 0) {
        parsedContent.push(current);
    }

    return parsedContent;

};

export default convertToVirtualSections;
