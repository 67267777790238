import produce from 'immer';

import {
    SET_SUBMIT_ERROR,
    SET_SUBMIT_LOADING,
    SET_BUSINESS_LOAN_CREDIT_CHECK_ERROR
} from '../actions/forms';

const initialState = {
    submitError: {},
    submitLoading: {},
    businessLoanCreditCheckError: {}
};

function forms(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case SET_SUBMIT_ERROR:
                draft.submitError[action.formId] = action.error;
                break;
            case SET_SUBMIT_LOADING:
                draft.submitLoading[action.formId] = action.loading;
                break;
            case SET_BUSINESS_LOAN_CREDIT_CHECK_ERROR:
                draft.businessLoanCreditCheckError[action.formId] = action.error;
                break;
        }
    });
}

export default forms;
