import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import {
    StyledOrderedList,
    StyledList,
} from './style';


const List = ({
    content,
    ordered,
    unorderedListColor,
    theme
}) => {
    const ListComponent = ordered ? StyledOrderedList : StyledList;
    const color = unorderedListColor ? unorderedListColor.name : 'darkGreen';

    // const ListComponent = StyledOrderedList;

    return (
        <ListComponent
            color={color}
            theme={theme}
            dangerouslySetInnerHTML={{ __html: content }}
        />
    );
};

List.defaultProps = {
    children: '',
    fontSize: 'normal',
    fontStyle: 'normal',
    spacing: 'base',
    padding: 'base'
};

List.propTypes = {
    content: PropTypes.string,
    ordered: PropTypes.bool,
    unorderedListColor: PropTypes.string,
    theme: PropTypes.object
};

export default withTheme(List);
