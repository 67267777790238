import React from 'react';
import PropTypes from 'prop-types';
import Hamburger from 'react-hamburgers';

import './style.styl';

const CustomHamburgers = ({ active, className, type, onClick }) => {
    return (
        <Hamburger
            {...(className && { className })}
            active={active}
            type={type || 'slider'}
            onClick={onClick}
        />
    );
};

CustomHamburgers.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
};

export default CustomHamburgers;
