import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../link';
import Headline from '../../../../headline';

import './style.styl';

class SingleDocument extends React.Component {

    static propTypes = {
        item: PropTypes.object,
        onSearchResultSelected: PropTypes.func
    };

    constructor (props) {
        super(props);
    }

    getFullUrl () {
        const { permalink } = this.props.item;

        return (permalink)
            ? `${window.location.protocol}//${window.location.host}/${permalink.substr(1)}`
            : '';
    }

    render () {
        const { item, onSearchResultSelected } = this.props;

        let permalink = item.permalink || '';
        if (item.template === 'post') {
            permalink = '/posts' + permalink;
        }

        return (
            <div className="single-document-result-item">
                <Link
                    className="single-document-result-item--title"
                    to={permalink}
                    onClick={onSearchResultSelected}
                    look="default"
                >
                    <Headline tag={3} marginBottom="small">{item.post_title}</Headline>
                </Link>
                <div className="single-document-result-item--highlight" dangerouslySetInnerHTML={{ __html: item._formatted.post_content }} />
                <div className="single-document-result-item--url">
                    <Link
                        className="single-document-result-item--url--link"
                        to={permalink}
                        onClick={onSearchResultSelected}
                        look="default"
                    >
                        {this.getFullUrl()}
                    </Link>
                </div>
            </div>
        );
    }

}

export default SingleDocument;
