import React from 'react';
import PropTypes from 'prop-types';
import { StyledGrid } from './style';
import Item, { gridHookValue } from './item';

/**
 * This is the magic behind placing items in multiRow mode.
 * If cols = 12, then a full row is start = 1 and end = 13.
 * Width of a child is 1 (if not specified)
 */
const multiRowMode = ({ elements, cols, gap }) => {
    const columnCount = elements.length;

    let colStart = 1;
    let rowStart = 1;

    let cloned = [];
    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
        const child = elements[columnIndex];

        const childWidth = child.props.width || 1;

        if (childWidth > cols) {
            throw new Error(
                'Not ok to have a Grid.Item width larger than number of columns',
                childWidth,
                cols
            );
        }

        let colEnd = colStart + childWidth;
        let rowEnd = rowStart + 1;

        if (colEnd > cols + 1) {
            rowStart++;
            rowEnd = rowStart + 1;
            colStart = 1;
            colEnd = colStart + childWidth;
        }

        const clone = React.cloneElement(child, {
            cols,
            columnCount,
            columnIndex,
            gap,
            col: {
                s: colStart,
                e: colEnd,
            },
            row: {
                s: rowStart,
                e: rowEnd,
            },
        });

        colStart = colEnd;

        cloned.push(clone);
    }

    return cloned;
};

// Helper to generate IE11 compatible CSS variables.
const IE11Dynga = cols => {
    let gridColumns = '';
    for (let i = 0; i < cols; i++) {
        gridColumns += '1fr ';
    }
    return {
        gridColumns,
    };
};

// Walk through the grid and check if fixed placement or automatic should be used.
// Fixed placement means that the items have 'col' and 'row' configuration.
// If there is no 'col' or 'row' configuration we will use automatic placement
// based on no configuration and optionally 'width' configuration.
const analyseGridChildren = elements => {
    return elements.reduce(
        (acc, child) => {
            const { width, col, row, gridHookId } = child.props;
            if (gridHookId != gridHookValue) {
                console.warn('Only Grid.Items are allowed inside a Grid'); // eslint-disable-line
            }
            if (width) {
                acc.widthRequested += width;
            }
            if (col || row) {
                acc.fixedPlacement = true;
            }
            return acc;
        },
        {
            widthRequested: 0,
            fixedPlacement: false,
        }
    );
};

const Grid = props => {
    const {
        children,
        cols,
        gap,
        justifyItems,
        alignItems,
        templateRows,
        equalHeight,
    } = props;
    const passProps = () => {
        // All the grid children elements.
        const elements = React.Children.toArray(children);

        // Run the grid analysis.
        let analysis = analyseGridChildren(elements);

        // If fixed placement is detected, we know that the user wan't full control of the grid,
        // thus, we just return the grid items.
        if (analysis.fixedPlacement) {
            return children;
        }

        return multiRowMode({ elements, cols, analysis, gap });
    };

    const IE11Values = IE11Dynga(cols);

    const gridProps = {
        IE11Values,
        cols,
        templateRows,
        gap,
        justifyItems,
        alignItems,
        equalHeight,
    };

    return <StyledGrid {...gridProps}>{passProps(children)}</StyledGrid>;
};

Grid.defaultProps = {
    cols: 12,
    templateRows: 'auto',
    gap: {
        col: 16,
        row: 0,
    },
    justifyItem: 'start',
    alignItems: 'stretch',
    multiRow: false,
    equalHeight: false,
};

Grid.propTypes = {
    children: PropTypes.any,
    cols: PropTypes.number,
    templateRows: PropTypes.string,
    gap: PropTypes.shape({
        col: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        row: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    justifyItems: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    equalHeight: PropTypes.bool,
};

Grid.Item = Item;

export default Grid;
