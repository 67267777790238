import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { withTheme } from 'emotion-theming';
import { withRouter } from 'react-router-dom';
import { TextCss } from './style';
import { switchCase } from '../../helpers/switch';

const Text = ({
    as = 'p',
    children,
    backgroundColor,
    contentPadding,
    history,
    htmlFor,
    size = 'normal',
    weight = 'normal',
    dangerouslySetInnerHTML = false,
    color,
    italic,
    padding,
    margin,
    align = 'left',
    style = {},
    className,
    onClick,
}) => {
    const contentRef = useRef(null);

    useEffect(() => {
        const allATags = contentRef.current.querySelectorAll('a');

        allATags.forEach(a => {
            const url =
                a.href &&
                a.href
                    .replace(
                        `${window.location.protocol}//${window.location.hostname}`,
                        ''
                    )
                    .replace(/\/$/, '');

            if (!url.match(/^http/) && !url.match(/#humany/g)) {
                a.addEventListener('click', e => {
                    e.preventDefault();
                    history.push(url);
                });
            }
        });
    }, []);

    const Component = styled[as]`
        ${TextCss}
    `;

    return (
        <Component
            color={color}
            italic={italic}
            padding={padding}
            margin={margin}
            ref={contentRef}
            size={size}
            weight={weight}
            align={align}
            style={style}
            className={className}
            onClick={onClick}
            backgroundColor={backgroundColor}
            contentPadding={contentPadding}
            {...(as === 'label' && { htmlFor })}
            {...(dangerouslySetInnerHTML
                ? { dangerouslySetInnerHTML: { __html: children } }
                : { children })}
        />
    );
};

Text.propTypes = {
    backgroundColor: PropTypes.string,
    contentPadding: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.any,
    color: PropTypes.string,
    history: PropTypes.object,
    italic: PropTypes.bool,
    htmlFor: PropTypes.string,
    size: PropTypes.oneOf(['xxsmall', 'xsmall', 'small', 'normal', 'large']),
    weight: PropTypes.oneOf(['normal', 'bold', 'bolder']),
    theme: PropTypes.object, // TODO: specify shape with colors when they have been confirmed.
    dangerouslySetInnerHTML: PropTypes.bool,
    padding: PropTypes.string,
    margin: PropTypes.string,
    align: PropTypes.oneOf(['left', 'center', 'right']),
    style: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Text.parseProps = ({ margin, ...atts }) => {
    const size = {
        small: '1rem',
        medium: '1.5rem',
        large: '2rem',
    };

    const marginSize = size[atts.marginSize];

    return {
        align: atts.align,
        color: atts.color,
        backgroundColor: atts.backgroundColor,
        size: atts.fontSize,
        as: 'p',
        dangerouslySetInnerHTML: true,
        children: atts.children,
        margin: switchCase({
            none: '0',
            top: `${marginSize} 0 0`,
            bottom: `0 0 ${marginSize}`,
            topAndBottom: `${marginSize} 0`,
        })('none')(margin),
        contentPadding: atts.contentPadding,
    };
};

export default withRouter(withTheme(Text));
