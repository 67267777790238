/**
 * @file             : services/frontend/src/www-src/scripts/reducers/header-menu-mobile.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 25.10.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import produce from 'immer';

import {
    TOGGLE_MOBILE_MENU,
    SET_ACTIVE_MENU_ITEMS,
    OPEN_MENU_ITEM,
    CLOSE_MENU_ITEM,
} from '../actions/header-menu-mobile';

const initialState = {
    error: false,
    loading: false,
    mobileMenuOpen: false,
    activeMenuItems: {},
    openMenuItems: {},
};

function headerMenuMobile(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case TOGGLE_MOBILE_MENU:
                draft.mobileMenuOpen  = action.open;
                break;

            case SET_ACTIVE_MENU_ITEMS:
                draft.activeMenuItems = action.activeMenuItems;
                break;

            case OPEN_MENU_ITEM:
                Object.assign(draft.openMenuItems, action.levelAndIdPair);
                break;

            case CLOSE_MENU_ITEM:
                draft.openMenuItems[action.key] = null;
                break;
        }
    });
}

export default headerMenuMobile;
