import React from 'react';
import PropTypes from 'prop-types';

import { StyledSpacer } from './style';

const Spacer = ({ size = 'medium' }) => {
    return <StyledSpacer size={size} />;
};

Spacer.parseProps = atts => {
    let newProps = {
        size: atts.size,
    };
    return newProps;
};

Spacer.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Spacer;
