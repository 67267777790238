import { css } from '@emotion/core';
import theme from '@24hr/resursbank-theme';

const fontSizes = (size = 1) => ({
    h1: `${4.75 * size}rem`,
    h2: `${3.875 * size}rem`,
    h3: `${3 * size}rem`,
    h4: `${2.5 * size}rem`,
    h5: `${2.5 * size}rem`,
    h6: `${2 * size}rem`,
});

const fontFamily = '"Montserrat", Arial, sans-serif';

const h1 = css`
    font-family: ${fontFamily};
    font-weight: 700;
    line-height: 1.2em;
`;

const h2 = css`
    font-family: ${fontFamily};
    font-weight: 700;
    line-height: 1.15789;
`;

const h3 = css`
    font-family: ${fontFamily};
    font-weight: 700;
    line-height: 1.2em;
`;

const h4 = css`
    font-family: ${fontFamily};
    font-weight: 700;
    line-height: 1.2em;
`;

const h5 = css`
    font-family: ${fontFamily};
    font-weight: 600;
    line-height: 1.2em;
`;

const h6 = css`
    font-family: ${fontFamily};
    font-weight: 400;
    line-height: 1.2em;
`;

// eslint-disable-next-line
const spacing = {
    xsmall: '.5em',
    small: '1em',
    medium: '1.5em',
    large: '2em',
};

const tags = {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
};

export const HeadingCss = ({
    align,
    tag,
    color,
    italic,
    padding,
    margin,
    size = 1,
}) => css`
    ${tags[tag] || tags.h2};
    color: ${theme.colors[color] && theme.colors[color]};
    font-style: ${italic ? 'italic' : 'initial'};
    text-align: ${align};
    ${fontSizes(size)[tag] &&
        css`
            font-size: ${fontSizes(size)[tag]};
            ${theme.mediaQueries.mobileMax} {
                font-size: ${fontSizes(1)[tag]};
            }
        `};
    ${padding &&
        css`
            padding: ${padding};
        `};
    ${margin &&
        css`
            margin: ${margin};
        `};
`;
