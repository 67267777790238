let retries = 0;
let timeout = -1;

const send = function({type, category, action, label}) {

    if (process.browser) {

        try {

            if(typeof window.ga === 'function') {

                let command = '';

                if(typeof google_tag_manager !== 'undefined') {
                    command = window.ga.getAll()[0].get('name') + '.';
                }

                command += 'send';

                window.ga(command, type, {
                    'eventCategory': category,
                    'eventAction': action,
                    'eventLabel': label,
                });

            } else {

                if (retries++ < 10) {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                        send({type, category, action, label});
                    }, 500);
                } else {
                    console.log('window.ga is undefined, analytics not working'); // eslint-disable-line
                }
            }
        } catch (err) {
            if (retries++ < 10) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    send({type, category, action, label});
                }, 500);
            } else {
                console.log('Couldnt send data to ga', err); // eslint-disable-line
            }
        }

    }

};

module.exports = send;
