import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import validators from '../../helpers/validators';
import styleVariables from '../../variables/style';

import Label from '../label';
import Icons from '../icons';

import './style.styl';

class Input extends React.Component {
    static propTypes = {
        ariaLabel: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        hasError: PropTypes.bool,
        fieldErrorMessage: PropTypes.string,
        fieldSuccessMessage: PropTypes.string,
        fieldIsLoading: PropTypes.bool,
        generalRequiredErrorMessage: PropTypes.string,
        fieldLoadingMessage: PropTypes.string,
        id: PropTypes.string.isRequired,
        identicalTo: PropTypes.string,
        infoText: PropTypes.string,
        label: PropTypes.string,
        language: PropTypes.string,
        maxLength: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
        name: PropTypes.string.isRequired,
        getFocusStatus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func.isRequired,
        placeholder: PropTypes.string,
        reportValidStatus: PropTypes.func,
        required: PropTypes.bool,
        rows: PropTypes.number,
        showLabel: PropTypes.bool,
        type: PropTypes.string,
        verticalPadding: PropTypes.string,
        validate: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.oneOf([
                'date',
                'email',
                'phone',
                'companyRegistrationNumber',
                'socialSecurityNumber',
                'identical',
            ]),
        ]),
        value: PropTypes.string,
        innerRef: PropTypes.object,
    };

    static defaultProps = {
        rows: 1,
        type: 'text',
        value: '',
        showLabel: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            infoMessageVisible: false,
            valid: this.validate(props.value),
            visited: false,
        };
    }

    async componentDidUpdate(prevProps) {
        const { id, identicalTo, reportValidStatus, value } = this.props;

        if (
            value !== prevProps.value ||
            identicalTo !== prevProps.identicalTo
        ) {
            const valid = await this.validate(value);

            if (valid !== this.state.valid) {
                this.setState({ valid });

                if (reportValidStatus) {
                    reportValidStatus(id, valid);
                }
            }
        }
    }

    onBlur = ev => {
        if (this.props.getFocusStatus) {
            this.props.getFocusStatus(false);
        }
        if (this.props.onBlur) {
            this.props.onBlur(ev);
        }
        this.visit();
    };

    onFocus = () => {
        if (this.props.getFocusStatus) {
            this.props.getFocusStatus(true);
        }
    };

    toggleInfoMessage = () => {
        this.setState({
            infoMessageVisible: !this.state.infoMessageVisible,
        });
    };

    visit(visited) {
        this.setState({ visited: visited !== false });
    }

    isValid() {
        return this.state.valid;
    }

    validate(value) {
        const { identicalTo, language, required, validate } = this.props;

        if (!value) {
            return !required;
        }

        if (typeof validate === 'function') {
            return validate(value);
        }

        if (validate === 'identical') {
            return value === identicalTo;
        }

        return !validate || validators[validate](value, language);
    }

    renderErrorMessage() {
        if (!this.state.visited || this.state.valid) {
            return null;
        }

        if (!this.props.value) {
            return (
                <p className="input--error-message">
                    {this.props.generalRequiredErrorMessage}
                </p>
            );
        }

        return (
            <p className="input--error-message">
                {this.props.fieldErrorMessage}
            </p>
        );
    }

    renderLoadingMessage() {
        if (!this.props.fieldIsLoading) {
            return null;
        }

        return (
            <p className="input--loading-message">
                {this.props.fieldLoadingMessage}
            </p>
        );
    }

    renderSuccessMessage() {
        if (!this.state.visited || !this.state.valid || !this.props.value) {
            return null;
        }

        if (!this.props.fieldSuccessMessage) {
            return;
        }

        return (
            <p className="input--success-message">
                {this.props.fieldSuccessMessage}
            </p>
        );
    }

    renderStatusIcon() {
        if (
            !this.state.visited ||
            (!this.props.required && !this.props.value) || this.props.disabled
        ) {
            return null;
        }

        const icon =
            !this.props.hasError && this.state.valid
                ? {
                    name: 'checkMark',
                    color: styleVariables.colors.formValid,
                }
                : {
                    name: 'close',
                    color: styleVariables.colors.formInvalid,
                };

        return (
            <label
                className={cx(
                    'input--status-icon',
                    this.props.rows > 1 && 'input--textarea--status-icon'
                )}
                htmlFor={this.props.id}
            >
                <Icons
                    name={icon.name}
                    color={icon.color}
                    className="input--status-icon--icon"
                />
            </label>
        );
    }

    render() {
        const {
            ariaLabel,
            className,
            disabled,
            id,
            infoText,
            maxLength,
            name,
            onChange,
            placeholder,
            required,
            rows,
            showLabel,
            type,
            value,
            verticalPadding,
            innerRef
        } = this.props;

        const label = showLabel && (this.props.label || placeholder);

        const { infoMessageVisible, valid, visited } = this.state;
        const { hasError } = this.props;

        const Tag = rows && rows > 1 ? 'textarea' : 'input';

        const elementStyle = {
            paddingTop: verticalPadding,
            paddingBottom: verticalPadding,
        };

        return (
            <div
                className={cx('input', className, {
                    'input--valid': visited && value && valid,
                    'input--invalid': hasError || (visited && !valid),
                })}
            >
                {label ? (
                    <Label
                        htmlFor={id}
                        id={`${name}-${id}`}
                        className="input--label"
                    >{`${label}${required ? ' *' : ''}`}</Label>
                ) : null}
                <div className="input--container">
                    {type === 'non-input' ? (
                        <p className="input--non-input">{value}</p>
                    ) : (
                        <Tag
                            ref={ innerRef } 
                            aria-label={ariaLabel}
                            aria-labelledby={`${name}-${id}`}
                            className="input--input"
                            disabled={disabled}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            onBlur={this.onBlur}
                            onFocus={this.onFocus}
                            onChange={onChange}
                            rows={rows}
                            type={type}
                            value={value}
                            maxLength={maxLength}
                            style={ verticalPadding ? elementStyle : {}}
                        />
                    )}
                    {this.renderStatusIcon()}
                    {infoText ? (
                        <div
                            className={cx('input--info-icon', {
                                'input--textarea--info-icon':
                                    Tag === 'textarea',
                                'input--non-input--info-icon':
                                    type === 'non-input',
                            })}
                            onClick={this.toggleInfoMessage}
                        >
                            <Icons
                                name="helpCircled"
                                color={styleVariables.colors.colorRawbBlack}
                                className="input--info-icon--icon"
                            />
                        </div>
                    ) : null}
                </div>
                {infoMessageVisible ? (
                    <div className="input--info-text">{infoText}</div>
                ) : null}
                {visited ? this.renderErrorMessage() : null}
                {visited ? this.renderSuccessMessage() : null}
                {visited ? this.renderLoadingMessage() : null}
            </div>
        );
    }
}

export default Input;
