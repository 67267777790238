import { fetchResource, parseHeaderData } from '../helpers/fetch-helper';
import { setDataLayer } from '../helpers/gtm';

export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const SEARCH_NOT_FOUND = 'SEARCH_NOT_FOUND';
export const TOGGLE_SEARCH_MODAL = 'TOGGLE_SEARCH_MODAL';

export function requestSearch(query, prevQuery, page) {
    return {
        type: REQUEST_SEARCH,
        query,
        page: query === prevQuery ? page : 1 // Reset to page 1 if there is a new query
    };
}

export function resetSearch() {
    return {
        type: RESET_SEARCH,
    };
}

export function receivedSearch(query, data) {
    const { hits, total } = data;
    const numPages = Math.ceil(total / 10);
    return {
        type: RECEIVE_SEARCH,
        query,
        hits,
        total,
        numPages
    };
}

export function notFound(query, error) {
    return {
        type: SEARCH_NOT_FOUND,
        query,
        error
    };
}

export function toggleSearchModal (open) {
    return {
        type: TOGGLE_SEARCH_MODAL,
        open
    };
}

/**
 * @param  {[number]} page  [e.g page 1|2|3 etc there is no page 0]
 */
export function search(query, page) {

    return async function (dispatch, state, requestOptions) {

        if (state.search.query === query && state.search.page === page) {
            return true;
        }

        const isClusterPage = state.info.marketType === 'cluster';
        let index = '';
        let rootPath = '';
        if (isClusterPage) {
            const language = state.localization.currentLanguage;
            const role = state.settings.role || 'draft';
            rootPath = `&rootPath=${state.pages.current.match(/^[a-z-]+/)[0]}`;
            index = `${role}-single-cluster-document-${language}`;
        }

        let url = `${state.settings.apiUrl}/search/?query=${encodeURIComponent(query)}&page=${page}&index=${index}${rootPath}`;
        dispatch(requestSearch(query, state.search.query, page));

        setDataLayer({ action: 'search', category: 'search', label: query });

        try {
            const searchResults = await fetchResource(url, parseHeaderData(state.requestData, requestOptions));
            if (searchResults.hits && searchResults.hits.length > 0) {
                dispatch(receivedSearch(query, searchResults));
            } else {
                dispatch(notFound(query, { statusCode: 404, message: 'No results found' }));
            }
            return Promise.resolve();
        } catch (err) {
            dispatch(notFound(query, err));
        }
    };
}

export function reset() {
    return function (dispatch) {
        dispatch(resetSearch());
    };
}

export function openSearchModal() {
    return function (dispatch) {
        dispatch(toggleSearchModal(true));
    };
}

export function closeSearchModal() {
    return function (dispatch) {
        dispatch(toggleSearchModal(false));
    };
}

