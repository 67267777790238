require('./style.styl');

import React from 'react';
import PropTypes from 'prop-types';
import Ticker from 'react-ticker';

const AlertTicker = ({ alerts, move, direction, mode, offset, speed, height }) => {
    return (
        <Ticker move={move}
            direction={direction}
            mode={mode}
            offset={offset}
            speed={speed}
            height={height}>
            {() => (
                <div className="alert-ticker">
                    {alerts.map(alert => (
                        <span
                            key={alert.id}
                            className={`alert-ticker--message alert-ticker--message--${alert.severity ? alert.severity : 'medium'}`}>
                            <span className="alert-ticker--bold-start-of-message">{alert['bold_start_of_message']}</span>
                            {alert.message}
                        </span>
                    ))}
                </div>
            )}
        </Ticker>
    );
};

AlertTicker.propTypes = {
    alerts: PropTypes.array,
    move: PropTypes.bool,
    direction: PropTypes.string,
    mode: PropTypes.string,
    offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    speed: PropTypes.number,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default AlertTicker;
