import { fetchResource, parseHeaderData } from '../../helpers/fetch-helper';

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';

export function requestCategories() {
    return {
        type: REQUEST_CATEGORIES,
    };
}

export function receivedCategories(categories) {
    return {
        type: RECEIVE_CATEGORIES,
        categories,
    };
}

export function categoriesError(error) {
    return {
        type: CATEGORIES_ERROR,
        error,
    };
}

export function getCategories() {
    return async function(dispatch, state, requestOptions) {
        const url = `${state.settings.apiUrl}/humany/categories`;
        dispatch(requestCategories());
        try {
            const categories = await fetchResource(
                url,
                parseHeaderData(state.requestData, requestOptions)
            );
            dispatch(receivedCategories(categories.Children));
        } catch (err) {
            dispatch(categoriesError(err));
            return err;
        }
    };
}
