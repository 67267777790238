import styled from '@emotion/styled';

export const StyledSvgButton = styled.button`
    position: fixed;
    bottom: 3rem;
    left: 3rem;
    z-index: 10000;
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.9);
    line-height: 0;
    box-shadow: 0 2px 16px 0 rgba(33, 37, 41, 0.1);

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: #aa3200;
        }
    }
`;
