import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../../../link';
import Excerpt from '../excerpt'; // Local component for shared templates
import styles from '../../../../../variables/style';

import './style.styl';

class HumanyItem extends React.Component {

    static propTypes = {
        item: PropTypes.object,
        highlight: PropTypes.object,
        viewport: PropTypes.object,
        onSearchResultSelected: PropTypes.func
    };

    constructor (props) {
        super(props);
    }

    onLinkClick () {
        this.props.onSearchResultSelected();
        window.Humany.Widgets.Coordinator.trigger(document.getElementById('humany-trigger'), { widgetName: 'resursbank_se_kundservice_lightbox'});
    }

    render () {
        const { viewport, item, highlight } = this.props;
        const lightBoxBaseUrl = '#humany-resursbank_se_kundservice_lightbox=/';
        const permalink = lightBoxBaseUrl + item.RelativeUrl;

        const style = {
            borderLeft: `2px solid ${styles.colors.humanyColor}`,
            padding: '0 0 0 20px'
        };


        return (
            <div id="humany-trigger" className="humany-item" style={style}>
                <Link
                    className="humany-item--title"
                    to={permalink}
                    onClick={this.onLinkClick.bind(this)}>
                    {item.Title}
                </Link>
                <Excerpt item={item} highlight={highlight} viewport={viewport} />
            </div>
        );
    }

}

export default HumanyItem;
