export const CONNECTION_UPDATE_TYPE = 'CONNECTION_UPDATE_TYPE';

function updateConnection(connectionType) {
    return {
        type: CONNECTION_UPDATE_TYPE,
        connectionType,
    };
}

const onConnectionUpdate = function(dispatch) {
    if (process.title === 'browser') {
        dispatch(updateConnection(navigator.connection.effectiveType));
    }
};

export function subscribe() {
    return function(dispatch, state) {
        const { getState } = state;
        const {
            settings: { mobile, userAgent },
        } = getState();
        const browserName = userAgent.browser.name;

        if (process.title === 'browser' && navigator.connection) {
            // Maybe we dont want to change the type of connection each time it happens. Just once on load
            // navigator.connection.addEventListener('change', onConnectionUpdate.bind(null, dispatch));
            onConnectionUpdate(dispatch);
        }
        if (
            !mobile &&
            (browserName === 'Safari' ||
                browserName === 'IE' ||
                browserName === 'Firefox')
        ) {
            /**
             * Safari and IE does not have the connection object on navigator.
             * To work around this we can check if we're not on mobile and if the
             * user-agent is either Safari or IE, to load higher res images.
             */
            dispatch(updateConnection('4g'));
        }
    };
}

export function unsubscribe() {
    return function() {
        if (process.title === 'browser') {
            navigator.connection.removeEventListener(
                'change',
                onConnectionUpdate
            );
        }
    };
}
