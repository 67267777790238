import React from 'react';
import SvgIcon from './svg-icon';
import PropTypes from 'prop-types';

const Plus = ({ viewBox = '0 0 14 14', ...rest }) => (
    <SvgIcon viewBox={viewBox} {...rest}>
        <path d="M.778 7.778h5.444v5.444c0 .227.073.413.219.56A.757.757 0 0 0 7 14a.757.757 0 0 0 .559-.219.757.757 0 0 0 .219-.559V7.778h5.444a.757.757 0 0 0 .56-.219A.757.757 0 0 0 14 7a.757.757 0 0 0-.219-.559.757.757 0 0 0-.559-.219H7.778V.778a.757.757 0 0 0-.219-.56A.757.757 0 0 0 7 0a.757.757 0 0 0-.559.219.757.757 0 0 0-.219.559v5.444H.778a.757.757 0 0 0-.56.219A.757.757 0 0 0 0 7c0 .227.073.413.219.559a.757.757 0 0 0 .559.219z" />
    </SvgIcon>
);

Plus.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string,
};

export default Plus;
