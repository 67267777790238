import styled from '@emotion/styled';
import { css } from '@emotion/core';
import Link from '../../../../../components/link';
import Hamburger from '../../../../../components/hamburgers';

export const StyledMenuBar = styled.div`
    height: ${props => props.theme.menuBar.height};
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 199;
    background-color: ${p => p.theme.colors.darkBlue};
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 2rem;
    transform: translate3d(0, 0, 0);
`;

export const StyledMenuBarItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: calc(33.3333333333% - 1rem);
    text-align: center;
    margin: 1rem 1rem calc(1rem + 4.5px);
`;

export const StyledBarItemLink = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        border-bottom: 2px solid ${p => p.theme.colors.white};
        z-index: 1;
        left: 0;
        right: 0;
        bottom: -7px;
        width: 0;
        transition: width 0.2s ease-out;
    }

    ${p =>
        p.active &&
        css`
            &:after {
                width: 100%;
            }
        `}
`;

export const StyledHamburger = styled(Hamburger)`
    padding-top: 2px;

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background: ${p => p.theme.colors.white};
    }

    .hamburger-inner::before {
        top: 7px;
    }

    .hamburger-inner::after {
        bottom: 7px;
    }
`;
