import Loadable from 'react-loadable';
import React from 'react'; // eslint-disable-line
import Loading from '../../components/loading';

const LoadableComponent = Loadable({
    loader: () => import(/* webpackChunkName = "contact-form" */ './contact-form'),
    loading: Loading,
    render(loaded, props) {
        let mergedProps;
        if (props.blockName) { // eslint-disable-line
            mergedProps = {
                ...props,
                ...loaded.default.WrappedComponent.parseProps(props),
            };
        } else {
            mergedProps = {
                ...props,
                ...loaded.default.WrappedComponent.vcConvertToProps(props),
            };
        }
        let Component = loaded.default;
        return <Component {...mergedProps} />;
    }
});

export default LoadableComponent;
