import Loadable from 'react-loadable';
import React from 'react';
import Loading from '../loading';

const LoadableComponent = Loadable({
    loader: () => import(/* webpackChunkName = "iframe" */ './iframe'),
    loading: Loading,
    render(loaded, props) {
        let mergedProps;
        if (props.blockName) { // eslint-disable-line
            mergedProps = {
                ...props,
                ...loaded.default.parseProps(props),
            };
        } else {
            mergedProps = {
                ...props,
                ...loaded.default.vcConvertToProps(props),
            };
        }
        let Component = loaded.default;
        return <Component {...mergedProps} />;
    },
});

export default LoadableComponent;
