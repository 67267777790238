import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';

export const StyledInnerContainer = styled.div`
    min-height: 400px;
    max-width: 40vw;
    min-width: 700px;
    max-height: 90vh;
    overflow: auto;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${theme.colors.white};
    & > *:first-of-type {
        flex: 1;
    }
    ${theme.mediaQueries.mobileMax} {
        min-width: 97vw;
        max-width: 97vw;
        max-height: 80vh;
    }
`;
