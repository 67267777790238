import queryString from 'query-string';

/**
 * Combines current query params with a given url's query params.
 * @param {string} currentQuerystring - Current location's query string. Must include the question mark (?).
 * @param {string} targetUrl - Url to combine the current location search with.
 * @return {string} Target url + current location's query params.
 */
function combineQueryParamsToUrl(currentQuerystring, targetUrl) {
    const currentQueryparams = queryString.parse(currentQuerystring);
    const parsedUrl = queryString.parseUrl(targetUrl);
    const merged = { ...currentQueryparams, ...parsedUrl.query };
    return queryString.stringifyUrl(
        { url: parsedUrl.url, query: merged },
        { sort: false }
    );
}

export { combineQueryParamsToUrl };
