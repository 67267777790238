import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../button';
import Icons from '../icons';
import Headline from '../headline';
import style from '../../variables/style';
import './style.styl';
import Copy from '../copy';
import sendToGa from './send-to-ga.js';

const WarningComponent = (
    { buttonText, error, fullError, headline, message, onClickButton, title },
    { localize }
) => {
    const errorContent = {
        Message:
            message ||
            (error && error.body && error.body.message) ||
            'no message',
        Name: (error && error.body && error.body.name) || 'no name',
        TraceId:
            error &&
            error.body &&
            error.body.details &&
            error.body.details.traceId,
        RequestId: error && error.requestId && error.requestId,
        Status: (error && error.body && error.body.status) || 'no name',
        ValidationError:
            error &&
            error.body &&
            error.body.details &&
            JSON.stringify(error.body.details.validationErrors),
    };

    const [expand, setExpand] = useState(true);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        let label = errorContent.TraceId || errorContent.RequestId ? 
            JSON.stringify(errorContent) :
            JSON.stringify(errorContent) + ' - ' + (error ? error.stack : 'No error object');
        sendToGa({
            type: 'event',
            action: 'frontend-error', 
            category: 'requestId', //: ' + errorContent.RequestId, 
            label,
        });
    }, []);

    const onSetExpand = e => {
        e.stopPropagation();
        setExpand(!expand);
    };

    return (
        <div className="warning--wrapper">
            {fullError ? (
                <Fragment>
                    <div
                        aria-label="Open error details"
                        className="warning--header"
                        onKeyPress={onSetExpand}
                        onClick={onSetExpand}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        role="button"
                        tabIndex={0}
                    >
                        <Icons
                            color={style.colors.colorRawbRed}
                            name="error"
                            width={40}
                        />
                        <h1
                            className={classNames(
                                'warning--heading',
                                focused && 'warning--header-focus'
                            )}
                        >
                            {title || 'An error occured'}
                        </h1>
                    </div>

                    {expand && (
                        <div className="warning--details">
                            {Object.keys(errorContent).map((item, index) => {
                                const renderCopy = (item === 'TraceId' ||
                                    item === 'RequestId') && (
                                    <Copy string={errorContent[item]} />
                                );

                                return (
                                    errorContent[item] && (
                                        <p
                                            key={index}
                                            className="warning--detail"
                                        >
                                            {`${item}: ${errorContent[item]}`}
                                            {renderCopy}
                                        </p>
                                    )
                                );
                            })}
                        </div>
                    )}
                </Fragment>
            ) : (
                <div className="warning--details-light">
                    <Headline
                        className="warning--details-light-headline"
                        colorScheme="dark-blue"
                        marginBottom="medium"
                        tag={3}
                    >
                        {headline}
                    </Headline>
                    <div className="warning--details-light-content">
                        <div className="warning--details-light-icon">
                            <Icons
                                color={style.colors.colorRawbRed}
                                name="error"
                                width={40}
                            />
                        </div>
                        <p className="warning--details-light-message">
                            {message}
                        </p>
                    </div>
                    <Button
                        className="warning--details-light-button"
                        onClick={() => onClickButton()}
                    >
                        {buttonText || localize('close')}
                    </Button>
                </div>
            )}
        </div>
    );
};

WarningComponent.contextTypes = {
    localize: PropTypes.func,
};

WarningComponent.propTypes = {
    buttonText: PropTypes.string,
    error: PropTypes.object,
    fullError: PropTypes.bool,
    headline: PropTypes.string,
    message: PropTypes.string,
    onClickButton: PropTypes.func,
    title: PropTypes.string,
};

WarningComponent.defaultProps = {
    fullError: true,
};

export default WarningComponent;
