import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '../../components/button-component';
import Icons from '../../components/icons';
import Link from '../../components/link';
import Input from '../../components/input';
import validators from '../../helpers/validators';

import './style.styl';

class DocumentHandlerListItem extends React.Component {

    static propTypes = {
        post: PropTypes.object,
        language: PropTypes.string.isRequired,
    }

    static contextTypes = {
        localize: PropTypes.func
    }

    state = {
        versionBoxOpen: false,
        generatedUrl: null,
        formValue: '',
        formError: null,
    }

    getDateSpecificUrl(id, date) {
        const error = { status: 'error', message: 'Found no documents valid for date'};
        const enteredDateTime = new Date(date).getTime() / 1000; // convert to seconds to account for difference between timestamps in php and js
        const urlsInPost = this.props.post['url_versions'];

        const urlForDate = urlsInPost.reduce((bestMatch, url) => {
            const emptyBestMatch = Object.keys(bestMatch).length === 0;
            const urlDateTime = url['document_url_date'];

            if (enteredDateTime >= urlDateTime) {
                if (emptyBestMatch) {
                    bestMatch = url;
                } else {
                    const bestMatchTime = bestMatch['document_url_date'];

                    if (urlDateTime > bestMatchTime) {
                        bestMatch = url;
                    }
                }
            }
            return bestMatch;
        },{});

        return Object.keys(urlForDate).length ? urlForDate : error;
    }

    onDateInputChange = (event) => {
        this.setState({
            formValue: event.target.value,
            formError: null,
        });
    }

    onGenerateButtonClick = (id) => {
        const dateInputValue = this.state.formValue;

        if (!validators.date(dateInputValue, this.props.language)) {
            this.setState({
                formError: this.context.localize('invalid-date')
            });
        } else {
            const url = this.getDateSpecificUrl(id, dateInputValue);
            this.setState({
                generatedUrl: url,
            });
        }
    }

    onVersionButtonClick = () => {
        this.setState({
            versionBoxOpen: !this.state.versionBoxOpen,
        });
    }


    renderGeneratedUrl() {
        const url = this.state.generatedUrl;

        if (!url) {
            return null;
        }
        
        if ( url.status === 'error' ) {
            return (
                <div className="document-handler-list--link-item--error-message">
                    { this.context.localize('no-documents-for-date') }
                </div>
            );
        }

        return (
            <ul className="document-handler-list--sub-category--list">
                <li className="document-handler-list--sub-category--link">
                    <Link to={url['document_url']} target="_blank" look="default" className="document-handler-list--link-item--link--generated">{this.props.post.name}</Link>
                </li>
            </ul>
        );
    }

    renderVersionControls(hasVersions) {
        const versionButtonIconName = this.state.versionBoxOpen ? 'cross' : 'arrow-down-edge';

        if ( hasVersions ) {
            return (
                <div>
                    {/* <p className="document-handler-list--link-item--version--info-text">
                        { this.context.localize('has-versions-text') }
                    </p> */}
                    <div
                        className="document-handler-list--link-item--version--button"
                        onClick={this.onVersionButtonClick}
                    >
                        <Icons name={versionButtonIconName} className="document-handler-list--link-item--arrow-down-icon" color={'white'}/>
                    </div>
                </div>
            );
        }
    }

    render() {
        const {
            language,
            post,
        } = this.props;

        const {
            formError,
            formValue,
            versionBoxOpen,
        } = this.state;

        const hasVersions = post['url_versions'].length > 1;
        const currentLinkUrl = hasVersions ? this.getDateSpecificUrl(post.id, Date.now())['document_url'] : post['url_versions'][0]['document_url'];
        const versionBoxClasses = cx(
            'document-handler-list--link-item--version-box',
            { 'document-handler-list--link-item--version-box--open': versionBoxOpen }
        );

        return (
            <li className="document-handler-list--sub-category--link">
                <div className="document-handler-list--link-item--wrapper">
                    <div className="document-handler-list--link-item">
                        <Link to={currentLinkUrl} target="_blank" look="default" className="document-handler-list--link-item--link">{post.name}</Link>
                        { hasVersions
                            ? (
                                <p className="document-handler-list--link-item--version--info-text">
                                    { this.context.localize('has-versions-text') }
                                </p>
                            )
                            : null }
                    </div>
                    { this.renderVersionControls(hasVersions) }
                </div>
                <div className={versionBoxClasses}>
                    <div className="document-handler-list--link-item--version-box--instruction-text">
                        <p>{ this.context.localize('has-versions-instruction') }</p>
                    </div>
                    <div className="document-handler-list--link-item--version-box--form">
                        <div className="document-handler-list--link-item--version-box--form--date-input">
                            <Input
                                id={`${post.id}dateInput`}
                                label={this.context.localize('date')}
                                onChange={this.onDateInputChange}
                                placeholder={this.context.localize('date-placeholder')}
                                type="text"
                                validate="date"
                                language={language}
                                value={formValue}
                                name={`${post.id}dateInput`}
                            />
                            {formError}
                        </div>
                        <Button
                            className="document-handler-list--link-item--version-box--form--generate-btn"
                            onClick={this.onGenerateButtonClick}
                            size="small"
                        >
                            { this.context.localize('generate') }
                        </Button>
                    </div>
                    <div className="document-handler-list--link-item--version-box--generated-link">
                        {this.renderGeneratedUrl(post.name) }
                    </div>
                </div>
            </li>
        );
    }
}

export default DocumentHandlerListItem;
