export const SET_TITLE = 'SET_TITLE';
export const CLEAR_TITLE = 'CLEAR_TITLE';
export const SET_MARKET = 'SET_MARKET';

function setTitleAction(value, index) {
    return {
        type: SET_TITLE,
        value,
        index
    };
}

function clearTitleAction() {
    return {
        type: CLEAR_TITLE,
    };
}

export function clearTitle() {
    return async function (dispatch) {
        dispatch(clearTitleAction());
        return true;
    };
}
export function setTitle(value, index) {
    return async function (dispatch) {
        dispatch(setTitleAction(value, index));
        return true;
    };
}
export function setMarket(marketType) {
    return async function (dispatch) {
        dispatch({
            type: SET_MARKET,
            marketType: marketType || 'private',
        });
        return true;
    };
}
