import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.styl';

class Headline extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        className: PropTypes.string,
        weight: PropTypes.oneOf(['medium']),
        marginBottom: PropTypes.oneOf([ 'large', 'demi', 'medium', 'small', null, '']),
        marginTop: PropTypes.oneOf([ 'large', 'medium', 'small', null, '']),
        tag: PropTypes.number,
        wordbreak: PropTypes.bool,
        colorScheme: PropTypes.string
    }

    render () {

        const {
            marginBottom,
            marginTop,
            tag,
            weight,
            wordbreak,
            colorScheme
        } = this.props;

        const HeadlineTag = `h${this.props.tag}`;

        let classes = classNames(
            'headline',
            `headline--${tag}`, {
                'headline--medium': weight === 'medium'
            }, {
                'headline--wordbreak': wordbreak
            }, {
                '': !marginBottom,
                'margin-btm-small': marginBottom === 'small',
                'margin-btm-medium': marginBottom === 'medium',
                'margin-btm-demi': marginBottom === 'demi',
                'margin-btm-large': marginBottom === 'large'
            }, {
                '': !marginTop,
                'margin-top-small': marginTop === 'small',
                'margin-top-medium': marginTop === 'medium',
                'margin-top-large': marginTop === 'large'
            },
            this.props.className || '',
            {[`headline--colorscheme-${colorScheme}`]: colorScheme}
        );

        return (
            <HeadlineTag className={classes}>{ this.props.children } </HeadlineTag>
        );
    }

}

export default Headline;
