import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import ScrollableAnchor from 'react-scrollable-anchor';
import Column from '../column';
import style from '../../variables/style';
import Image from '../image';
import { pushToAnalyticsQueue } from '../../helpers/ab-first.js';

import './style.styl';

class Row extends React.Component {

    static vcConvertToProps(atts){

        let paddingBottom = parseInt(atts.padding_bottom, 10);
        let paddingTop = parseInt(atts.padding_top, 10);

        let newProps = {
            stretchWidth: atts.full_width === 'stretch',
            colorScheme: atts.color_scheme ? atts.color_scheme : '',
            viewport: atts.viewport ? atts.viewport : {},
            image: atts.image === '' ? null : atts.image,
            paddingBottom: !isNaN(paddingBottom) ? paddingBottom : 0,
            paddingTop: !isNaN(paddingTop) ? paddingTop : 0,
            numberOfChildren: atts.numberOfChildren ? atts.numberOfChildren : 1,
            equalHeight: atts.equal_height === 'yes',
            useAnchorLink: atts.use_anchor_link === 'yes',
            anchorLink: atts.anchor_link ? atts.anchor_link : '',
            arc: atts.arc ? atts.arc === 'yes' : false,
            imageOverlayType: atts.image_overlay_type ? atts.image_overlay_type : 'dark',
            imageAltText: atts.image_alt_text ? atts.image_alt_text : '',
            abFirst: atts.abFirst ? atts.abFirst : {},
            backgroundColor: atts.background_color ? atts.background_color : '',
            positionFixed: atts.position_fixed ? true : false,
        };

        return newProps;
    }

    static propTypes = {
        stretchWidth: PropTypes.bool,
        stretchRow: PropTypes.bool,
        colorScheme: PropTypes.string,
        children: PropTypes.any,
        equalHeight: PropTypes.bool,
        viewport: PropTypes.object,
        paddingTop: PropTypes.number,
        paddingBottom: PropTypes.number,
        numberOfChildren: PropTypes.number,
        useAnchorLink: PropTypes.bool,
        anchorLink: PropTypes.string,
        mobile: PropTypes.string,
        height: PropTypes.string,
        image: PropTypes.object,
        arc: PropTypes.bool,
        imageOverlayType: PropTypes.string,
        imageAltText: PropTypes.string,
        fullWidthRowBackground: PropTypes.bool,
        rowBackgroundColor: PropTypes.string,
        bankPage: PropTypes.bool,
        indexes: PropTypes.shape({
            row: PropTypes.number,
        }),
        featuredImage: PropTypes.bool,
        abFirst: PropTypes.object,
        backgroundColor: PropTypes.string,
        positionFixed: PropTypes.bool,
    }

    componentDidMount() {
        const { abFirst } = this.props;
        if (abFirst && abFirst.useAbTesting) {
            const testInfo = window.abFirst && window.abFirst.userAssignments && window.abFirst.userAssignments.find(variant => variant.abTestUuid === abFirst.abTestUuid);
            if (testInfo && testInfo.participant) {
                pushToAnalyticsQueue('event', 'A/B First', testInfo.abTestName, testInfo.variant);
            }
        }
    }


    backgroundImage (width, height, host, image) {
        let imageUrl = Image.getImageServiceSrcPath(host, image, width, height, false);

        let imageStyle = {
            backgroundImage: `url(${imageUrl})`
        };

        if (image.focus) {
            imageStyle.backgroundPosition = `${image.focus.x * 100}% ${image.focus.y * 100}%`;
        }

        const key = `${width}x${height}`;

        const imageItem = (
            <div key={key} className='row--extra' style={imageStyle} aria-label={this.props.imageAltText} role="img"></div>
        );

        return imageItem;

    }

    render () {
        const {
            stretchWidth,
            stretchRow,
            colorScheme,
            paddingTop,
            paddingBottom,
            equalHeight,
            useAnchorLink,
            anchorLink,
            height,
            image,
            arc,
            viewport,
            imageOverlayType,
            bankPage,
            indexes,
            backgroundColor,
            positionFixed,
        } = this.props;

        const rowClass = ClassNames(
            'row',
            { [`row--padding-top-${paddingTop || 0}`]: true },
            { [`row--padding-bottom-${paddingBottom || 0}`]: true },
            { ['row--stretch-row']: stretchRow ? true : null },
            { ['row--has--stretch-width']: stretchWidth ? true : null },
            { 'row--padding-top--bank--first-row': bankPage && indexes.row === 0 && paddingTop === 1 }
        );

        const sectionClass = ClassNames(
            'row-section',
            { ['row--section-stretch-height']: height === '100%' },
            { ['row--section-arc']: arc },
            { ['row--section-bg-image']: image},
            { [`row--section-colorscheme-${colorScheme}`]: colorScheme && !stretchWidth? true : null },
            { [`row--section-colorscheme-${colorScheme}-stretch`]: colorScheme && stretchWidth ? true : null },
            { [`row--section-background-color--${backgroundColor}--stretch`]: backgroundColor && backgroundColor ? true : null },
            { ['row--section--fixed']: positionFixed },
        );

        const children = this.props.children;

        let allColumnWidths = React.Children.map(children, (child) => {
            return child ? child.props.width : null;
        });

        let minHeight = 0;
        let largestChildHeight = 0;
        let clonedWithProps = null;

        // This is not the most beautiful peace of code, but since we are calculating the height of each item, and they might
        // have padding attached, we asume that we need to remove the last padding
        let isDesktop = viewport && viewport.width > style.breakpoints.mobileMaxWidth;
        let childBottomPadding = !isDesktop ? style.padding.mobile.bottom : style.padding.desktop.bottom;

        if (isDesktop && equalHeight && this.refs.row) {

            [].forEach.call(this.refs.row.children, (child) => { // HTMLCollections are not iterable like a normal array (i.e. forEach() does not work)
                let totalChildHeight = 0;
                [].forEach.call(child.childNodes, (child) => { // Add upp all childnodes clientHeights and store in totalChildHeight
                    totalChildHeight += child.clientHeight; // - childBottomPadding;
                });
                if (child && child.clientHeight > minHeight) {
                    minHeight = child.clientHeight; // - childBottomPadding;
                }
                if (totalChildHeight > largestChildHeight) {
                    largestChildHeight = totalChildHeight; // if the totalChildHeight is the largest in the current row, set largestChildHeight to it
                }
            });

            if (minHeight > largestChildHeight) {
                minHeight = largestChildHeight; // If minHeight is too large, we have to set it to the largest child height.
            }

            minHeight -= childBottomPadding;

            clonedWithProps = React.Children.map(children, (child, index) => {
                if (!child) {
                    return;
                }
                if (child && child.type && child.type !== Column) {
                    return child;
                }
                return React.cloneElement(child, {
                    minHeight: minHeight,
                    columnIndex: index,
                    columnLength: children.length,
                    allColumnWidths,
                    colorScheme,
                });
            });

        } else {

            clonedWithProps = React.Children.map(children, (child, index) => {
                if (!child) {
                    return;
                }
                if (child && child.type && child.type !== Column) {
                    return child;
                }
                return React.cloneElement(child, {
                    columnIndex: index,
                    columnLength: children.length || 1,
                    allColumnWidths,
                    colorScheme,
                    stretchRow: this.props.stretchRow,
                    imageOverlayType: imageOverlayType,
                    bgImage: image ? true : false
                });
            });
        }

        let extraRow = [];

        if (image) {
            let host = this.context.imageHost;
            let width = 'AUTO';
            let height = 1000;

            if (this.context.connectionType() != '4g' || this.context.isMobile()) {
                width = width * .50;
                height = height * .50;
            }

            extraRow.push(this.backgroundImage(width, height, host, image));

        } else if (this.props.fullWidthRowBackground) {

            const rowStyle = {
                backgroundColor: this.props.rowBackgroundColor || '#FFF',
            };

            extraRow = (
                <div className='row--extra' style={rowStyle} />
            );

        }

        let row = (
            <section className={sectionClass}>
                {extraRow}
                <div ref="row" className={rowClass}>
                    { clonedWithProps }
                </div>
            </section>
        );

        return useAnchorLink ? (<ScrollableAnchor id={anchorLink.replace(/#/g, '')}>{row}</ScrollableAnchor>) : row;
    }
}

Row.contextTypes = {
    imageHost: PropTypes.string,
    featureSupport: PropTypes.object,
    connectionType: PropTypes.func,
    isMobile: PropTypes.func,
};

export default Row;
