import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { transparentize } from 'color2k';

const size = {
    normal: 25,
    circle: 40,
    square: 50,
};

const background = p =>
    p.theme.colors[p.background] || p.theme.colors.superLightGrey;

export const StyledSvg = styled.svg`
    fill: ${({ fill }) => fill || 'inherit'};
    cursor: ${p => (p.onClick || p.inButton ? 'pointer' : 'initial')};
    width: ${p => p.width || size[p.$shape] || size.normal}px;
    height: ${p => p.height || p.width || size[p.$shape] || size.normal}px;

    ${p =>
        p.fullHeight &&
        css`
            height: 100%;
        `};

    ${p =>
        p.inButton &&
        css`
            position: absolute;
            ${p.placement}: ${p.placementValue || 0};
            top: 0;
            bottom: 0;
        `};

    ${p =>
        p.$shape === 'square' &&
        css`
            background: ${transparentize(background(p), p.backgroundOpacity)};
            padding: ${p.padding || p.padding === 0 ? p.padding : '1.7rem'};
        `};

    ${p =>
        p.$shape === 'circle' &&
        css`
            background: ${transparentize(background(p), p.backgroundOpacity)};
            padding: ${p.padding || '1.2rem'};
            border-radius: 50%;
        `};
`;
