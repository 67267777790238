import styled from '@emotion/styled';
import Icons from '../../../icons/index';

export const Inner = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    ${p => p.innerStyle}
`;

export const StyledIcon = styled(Icons)`
    position: absolute;
    right: 0.5rem;
    z-index: 100000;
    top: 0.5rem;
    padding: 1rem;
    width: calc(20px + 2rem);
    height: calc(20px + 2rem);
`;
