/**
 * @file             : services/frontend/src/www-src/scripts/components/wrapped-image/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 15.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Image from '../image';
import Link from '../link';
import { parseGutenbergImage } from '../../helpers/gutenberg-helpers';

import './style.styl';

class WrappedImage extends React.Component {

    static vcConvertToProps(atts){
        const image = atts.image || {};

        return {
            altText: image.alt_text,
            customAltText: atts.custom_src_alt_text,
            customImageHeight: atts.custom_image_height,
            heightAspectRatio: image.height_aspect_ratio,
            imageId: image.image_id,
            imageIsLink: atts.image_is_link ? true : false,
            imageLinkUrl: atts.link_url,
            imageText: atts.image_text ? atts.image_text : '',
            jwt: image.jwt,
            noLeftPadding: atts.no_left_padding,
            noRightPadding: atts.no_right_padding,
            originalHeight: image.original_height,
            originalWidth: image.original_width,
            service: image.service,
            src: image.src,
            focus: image.focus,
            useNativeWidth: atts.use_native_width,
            removePadding: atts.remove_padding,
            width: '100%',
        };
    }

    static parseProps(atts) {
        const parsedImage = parseGutenbergImage(atts);
        return parsedImage;
    }

    static propTypes = {
        altText: PropTypes.string,
        className: PropTypes.string,
        customImageHeight: PropTypes.string,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        heightAspectRatio: PropTypes.number,
        imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        imageIsLink: PropTypes.bool,
        imageLinkUrl: PropTypes.object,
        focus: PropTypes.object,
        imageText: PropTypes.string,
        jwt: PropTypes.string,
        minHeight: PropTypes.number,
        noLeftPadding: PropTypes.string,
        noRightPadding: PropTypes.string,
        originalHeight: PropTypes.number,
        originalWidth: PropTypes.number,
        service: PropTypes.oneOf(['aws-s3', 'local']),
        src: PropTypes.string,
        useNativeWidth: PropTypes.string,
        removePadding: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }

    constructor (props) {
        super(props);
    }

    imageElement() {
        const {
            minHeight,
            customImageHeight,
        } = this.props;

        if (minHeight) {
            return (
                <Image fit="false" height={minHeight} {...this.props} />
            );
        }
        if (customImageHeight) {
            return (
                <Image fit="false" height={customImageHeight} {...this.props} />
            );
        }
        return (
            <Image fit="true" {...this.props} />
        );
    }

    render () {

        const {
            height,
            imageIsLink,
            imageLinkUrl,
            noLeftPadding,
            noRightPadding,
            originalWidth,
            useNativeWidth,
            removePadding,
        } = this.props;

        const imageElement = this.imageElement();

        const classes = classNames('wrapped-image', {
            'wrapped-image--full-width': !useNativeWidth && !height,
            'wrapped-image--no-right-padding': noRightPadding === 'yes',
            'wrapped-image--no-left-padding': noLeftPadding === 'yes',
            'wrapped-image--no-padding': removePadding,
        });

        const style = {
            maxWidth: !useNativeWidth && !height ? `${originalWidth}px` : null,
        };

        if (imageIsLink) {
            return (
                <Link to={imageLinkUrl.url} target={imageLinkUrl.target} className={classes}>
                    {imageElement}
                </Link>
            );
        } else {
            return (
                <div className={classes} style={style}>
                    {imageElement}
                </div>
            );
        }
    }

}

export default WrappedImage;
