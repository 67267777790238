export const TOGGLE_COOKIE_CONSENT = 'TOGGLE_COOKIE_CONSENT';
export const SET_COOKIE_CONSENT_VIEW = 'SET_COOKIE_CONSENT_VIEW';

/**
 * @param {boolean} show - Whether or not to show the cookie pref modal.
 * @param {boolean} firstTime - If this is the first time the user sees the cookie consent modal.
 */
export const toggleCookieConsent = (show = true, firstTime = true) => {
    return {
        type: TOGGLE_COOKIE_CONSENT,
        payload: {
            show,
            firstTime,
        },
    };
};

export const setView = view => {
    return {
        type: SET_COOKIE_CONSENT_VIEW,
        payload: { view },
    };
};
