import theme from '@24hr/resursbank-theme/ya-bank';

/* eslint-disable-next-line */
const oldTheme = {
    borderRadius: {
        base: '16px',
        large: '26px',
    },
    colors: {
        darkBlue: '#211551',
        white: '#ffffff',
        black: '#000000',
        superLightGrey: '#eeeeee',
        lightGrey: '#dcdcdc',
        darkGrey: '#646464',
        orange: '#e15500',
        transparent: 'transparent',
    },
    headerHeight: '70px',
    contentMaxWidthExtra: '1440px',
    contentMaxWidth: '1150px',
    maxWidth: 1150,
    formPadding: {
        desktop: '40px',
        mobile: '20px',
    },
    maxInputWidth: '560px',
    outerPadding: '3.5vw',
    gap: {
        col: '1.875rem',
        row: '3.75rem',
    },
    baseSize: 16,
    breakpoints: {
        smallSmartphone: 320,
        smartphone: 380,
        bigSmartphone: 480,
        tablet: 768,
    },
    mediaQueries: {
        // Tablet and larger
        tabletMin: '@media only screen and (min-width: 768px)',
        // Only mobile
        mobileMax: '@media only screen and (max-width: 767px)',
        // Desktop and larger
        desktopMin: '@media only screen and (min-width: 1152px)',
    },
    spacing: {
        tiny: '0.5rem' /** 8px */,
        // (smaller is basesize, which is 16px)
        smaller: '1rem' /** 16px */,
        small: '1.5rem' /** 24px */,
        base: '2rem' /** 32px */,
        medium: '3.75rem' /** 60px */,
        large: '4rem' /** 64px */,
        xlarge: '6rem' /** 96px */,
    },
    menuBar: {
        height: '80px',
    },
};
export default theme;
