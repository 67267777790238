import Loadable from 'react-loadable';
import React from 'react'; // eslint-disable-line
import Loading from '../loading';

const LoadableComponent = loader =>
    Loadable({
        loader,
        loading: Loading,
        render(loaded, props) {
            const parseProps =
                loaded.default?.WrappedComponent?.parseProps ||
                loaded.default?.parseProps;
            const Component = loaded.default;
            let mergedProps = {
                ...props,
                ...(typeof parseProps === 'function' && parseProps(props)),
            };
            return <Component {...mergedProps} />;
        },
    });

export default LoadableComponent;
