import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';

export const StyledControllersContainer = styled.div`
    padding: ${theme.spacing.base};
    display: flex;
    gap: 2rem;
    flex-direction: ${props => props.$flexDirection || 'row'};
    width: 100%;
    min-height: 90px;
    justify-content: space-between;
    ${theme.mediaQueries.mobileMax} {
        flex-direction: column-reverse;
        align-items: center;
    }
    button {
        flex: 0 1 100%;
    }
    ${theme.mediaQueries.mobileMax} {
        min-height: 190px;
        justify-content: flex-end;
        button {
            max-height: 50px;
        }
    }
    @media all and (-ms-high-contrast: none) {
        display: block;
        height: 100px;
        button {
            width: 40%;
            margin: ${theme.spacing.tiny} ${theme.spacing.small};
        }
    }
`;
