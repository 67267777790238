import React from 'react';
import PropTypes from 'prop-types';
import Headline from '../headline';
import Row from '../row';
import Column from '../column';
import style from '../../variables/style';
import './style.styl';

const getFeaturedImageHeight = (viewport, isBank) => {
    if (viewport && !isBank) {
        return viewport.width > style.breakpoints.mobileMaxWidth ? '225' : '165';
    } else if(isBank){
        return viewport.width > style.breakpoints.mobileMaxWidth ? '225' : '180';
    }
};

const getFeaturedImageText = (featuredImage, featuredImageTextFromContent) => (
    featuredImage['featured_image_title'] ? featuredImage['featured_image_title'] : featuredImageTextFromContent
);

const FeaturedImage = ({
    viewport,
    featuredImage,
    featuredImageAltText,
    featuredImageTextFromContent,
    isBank
}) => {
    const headingContainerStyle = { height: `${getFeaturedImageHeight(viewport, isBank)}px` };

    return (
        <Row image={featuredImage} imageAltText={featuredImageAltText}>
            <Column width="1/2" viewport={viewport} className="column--grid-column-stretch-first">
                <div className="featured-image--heading--container" style={headingContainerStyle}>
                    <Headline tag={1}>
                        { getFeaturedImageText(featuredImage, featuredImageTextFromContent) }
                    </Headline>
                </div>
            </Column>
        </Row>
    );

};

FeaturedImage.propTypes = {
    viewport: PropTypes.object,
    featuredImage: PropTypes.object,
    featuredImageAltText: PropTypes.string,
    featuredImageTextFromContent: PropTypes.string,
    isBank: PropTypes.bool
};

export default FeaturedImage;
