import React from 'react';
import PropTypes from 'prop-types';
import DescriptionBox from '../../description-box';
import Text from '../../../../../components/text';
import { StyledSvgContainer } from '../../description-box/style';
import { Cookie } from '../../../../toggle-cookie-preferences/cookie';

const Overview = ({ config }) => {
    const { description } = config;
    return (
        <DescriptionBox>
            <StyledSvgContainer>
                <Cookie />
            </StyledSvgContainer>
            <Text as="div" dangerouslySetInnerHTML>
                {description}
            </Text>
        </DescriptionBox>
    );
};

Overview.propTypes = {
    config: PropTypes.shape({
        description: PropTypes.string,
    }),
};

export default Overview;
