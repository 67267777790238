import fetch from 'isomorphic-fetch';
const dataHelper = require('./data-helper');
const { analyzeDecoratedData } = require('../helpers/analyze-decorated-data.js');

async function fetchResource(permalink, options = {}) {
    options.credentials = 'include';

    const fetchPromise = await fetch(permalink, options);
    const data = await fetchPromise.json();

    if (fetchPromise.ok) {
        // If we got a redirect msg from server, we redirect there
        if (data.redirectTo && process.title === 'browser') {
            // TODO: We should push to history location here instead of a window.location. To avoid a full refresh
            // Possibly by using { push } from react-router-redux
            window.location = data.redirectTo;
        }
        if (data.decorated && process.title === 'browser') {
            analyzeDecoratedData(data.decorated);
        }
        return data;
    }

    if (data.status === 401) {
        /**
         * Don't consider a 401 to be a severe error.
         * If we throw 401 errors then Sentry will pick it up and spam the logs.
         */
        return;
    }

    data.status = fetchPromise.status;
    throw data;
}

async function postResource(url, bodyData = {}, options = {}) {
    const commonOptions = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    };

    if (bodyData instanceof FormData) {
        commonOptions.body = bodyData;
    } else {
        commonOptions.body = JSON.stringify(bodyData);
        commonOptions.headers['Content-Type'] = 'application/json';
    }

    const mergedOptions = dataHelper.mergeDeep(commonOptions, options);

    const fetchPromise = await fetch(url, mergedOptions);

    if (fetchPromise.ok) {
        const data = await fetchPromise.json();

        // If we got a redirect msg from server, we redirect there
        if (data.redirectTo && process.title === 'browser') {
            // TODO: We should push to history location here instead of a window.location. To avoid a full refresh
            // Possibly by using { push } from react-router-redux
            return (window.location = data.redirectTo);
        } else {
            return data;
        }
    }

    let err;

    try {
        err = await fetchPromise.json();
        err.status = fetchPromise.status;
    } catch (_) {
        const err = new Error(fetchPromise.statusText);
        err.status = fetchPromise.status;

        throw err;
    }

    throw err;
}

async function patchResource(url, bodyData = {}, options = {}) {
    const commonOptions = {
        method: 'PATCH',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
        credentials: 'include',
    };

    const mergedOptions = dataHelper.mergeDeep(commonOptions, options);

    const fetchPromise = await fetch(url, mergedOptions);
    const data = await fetchPromise.json();
    if (fetchPromise.ok) {
        // If we got a redirect msg from server, we redirect there
        if (data.redirectTo && process.title === 'browser') {
            // TODO: We should push to history location here instead of a window.location. To avoid a full refresh
            // Possibly by using { push } from react-router-redux
            window.location = data.redirectTo;
        } else {
            return data;
        }
    }
    throw data;
}

async function putResource(url, bodyData = {}, options = {}) {
    const commonOptions = {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
        credentials: 'include',
    };

    const mergedOptions = dataHelper.mergeDeep(commonOptions, options);

    const fetchPromise = await fetch(url, mergedOptions);
    const data = await fetchPromise.json();
    if (fetchPromise.ok) {
        // If we got a redirect msg from server, we redirect there
        if (data.redirectTo && process.title === 'browser') {
            // TODO: We should push to history location here instead of a window.location. To avoid a full refresh
            // Possibly by using { push } from react-router-redux
            window.location = data.redirectTo;
        } else {
            return data;
        }
    }
    throw data;
}

/** checks whether an entity should try to fetch content or not **/
function shouldFetch({ entity, slug, timeout }) {
    const requireKeys = ['loaded', 'loading', 'error'];
    const hasRequiredKeys =
        !entity || requireKeys.every(key => Object.keys(entity).includes(key));

    // This set a maximum period for each we don't refetch and requires a lastFetch props coming from the entity
    const fetchTimeOut = 1000 * timeout;
    const now = new Date().getTime();
    const delta = entity ? now - entity.lastFetch : 0;
    const forceFetch = timeout ? delta > fetchTimeOut : false;

    if (!hasRequiredKeys) {
        throw new Error('Entity is missing common reducer properties');
    }

    if (!entity) {
        return true;
    }

    let load = entity && !entity.loaded && !entity.loading && !entity.error;

    if (slug) {
        load = load && entity.slug !== slug;
    }

    if (forceFetch && !entity.loading) {
        load = forceFetch;
    }

    return load;
}

function parseHeaderData(requestData, options = {}) {
    options.headers = options.headers ? options.headers : {};

    options.headers.query = JSON.stringify(requestData.query);

    return options;
}

export {
    fetchResource,
    patchResource,
    putResource,
    shouldFetch,
    parseHeaderData,
    postResource,
};
