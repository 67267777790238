require('./style.styl');

import React from 'react';
import PropTypes from 'prop-types';

import Snackbar from '../snackbar';
import Button from '../button';
import Link from '../link';

class CookieWarning extends React.Component {
    static propTypes = {
        open: PropTypes.bool.isRequired,
        handleAccept: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.open,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            isOpen: nextProps.open,
        });
    }

    handleOkClick(event, reason) {
        // Only set the cookie and dissmiss the msg if user clicked the button, not just anywhere on screen
        if (reason === 'clickaway') {
            return;
        }
        this.props.handleAccept();
    }

    render() {
        const snackbarAction = [
            <div
                key="cookie-warning"
                className="cookie-warning--button--wrapper"
            >
                <Button
                    key={'action-1'}
                    onClick={this.handleOkClick.bind(this)}
                    size="small"
                    className="cookie-warning--button"
                >
                    {this.context.localize('i-understand')}
                </Button>
            </div>,
        ];

        const cookiesEnabled = process.browser && navigator.cookieEnabled || !process.browser;

        const cookieMessageContent = cookiesEnabled
            ? this.context.localize('cookie-message')
            : this.context.localize('cookies-disabled-message');

        const link = this.context.generalOptions.cookieReadMorePage;
        const cookieMessage = (
            <div className="cookie-warning--container">
                <p className="cookie-warning--message">
                    {cookieMessageContent}
                </p>
                <span className="cookie-warning--link">
                    <Link to={link} target="_blank" rel="noopener">
                        {this.context.localize('learn-more')}
                    </Link>
                </span>
            </div>
        );

        return (
            <div className="cookie-warning">
                <Snackbar
                    handleRequestClose={this.handleOkClick.bind(this)}
                    open={this.state.isOpen}
                    message={cookieMessage}
                    horizontal="center"
                    action={snackbarAction}
                />
            </div>
        );
    }
}

CookieWarning.contextTypes = {
    localize: PropTypes.func,
    generalOptions: PropTypes.object,
    settings: PropTypes.object,
};

export default CookieWarning;
