import React from 'react';
import PropTypes from 'prop-types';
import SlideContentHeightModal from './slide-content-height';
import AppearModal from './appear';

export const Appear = AppearModal;
export const SlideContentHeight = SlideContentHeightModal;

const BankModal = ({ mobile, ...rest }) => {
    if (mobile) {
        return <SlideContentHeight {...rest} />;
    } else {
        return <Appear mobile={mobile} {...rest} />;
    }
};

BankModal.propTypes = {
    mobile: PropTypes.bool,
};

export default BankModal;
