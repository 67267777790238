import queryString from 'query-string';
import { fetchResource, parseHeaderData } from '../../helpers/fetch-helper';

export const REQUEST_GUIDES = 'REQUEST_GUIDES';
export const RECEIVE_GUIDES = 'RECEIVE_GUIDES';

export function requestGuides() {
    return {
        type: REQUEST_GUIDES,
    };
}

export function receivedGuides({ guides, reset }) {
    return {
        type: RECEIVE_GUIDES,
        guides,
        reset,
    };
}

export function getGuides({ take = 10, reset, categories, phrase, skip }) {
    return async function (dispatch, state, requestOptions) {
        const query = queryString.stringify({
            categories,
            skip,
            phrase,
        });

        const url = `${state.settings.apiUrl}/humany/guides?take=${take}&${query}`;

        dispatch(requestGuides());
        try {
            const guides = await fetchResource(
                url,
                parseHeaderData(state.requestData, requestOptions)
            );
            dispatch(receivedGuides({ guides, reset }));
        } catch (err) {
            // dispatch(categoriesError(err));
            return err;
        }
    };
}
