import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';

export const StyledDescriptionBoxContainer = styled.div`
    background-color: ${theme.colors.white};
    padding: ${theme.spacing.indent};
    border-bottom: 1px solid #dcdcdc;
    white-space: pre-wrap;
    margin: 1rem 0 2rem;
    @media all and (-ms-high-contrast: none) {
        display: block;
        min-height: 300px;
        height: 100px;
    }
    h1 {
        line-height: 5rem;
    }
`;

export const StyledSvgContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 3rem;

    ${theme.mediaQueries.mobileMax} {
        padding-bottom: 2rem;
    }

    svg {
        width: 100px;
        height: 100px;

        ${theme.mediaQueries.mobileMax} {
            width: 50px;
            height: 50px;
        }

        path {
            fill: #aa3200;
        }
    }
`;
