export const CLOSE_ALERT = 'CLOSE_ALERT';
export const SHOW_ALERT = 'SHOW_ALERT';

export function closeAlert() {
    return {
        type: CLOSE_ALERT,
    };
}

export function showAlert() {
    return {
        type: SHOW_ALERT,
    };
}

