/* eslint-disable */
import React from 'react';

import Loading from '../../components/loading';
import ErrorMessage from '../../components/error-message';

function LoadingAndErrorSimple(props) {

    let { contentKey, height, item } = props;

    let errorModule = item && item.error ? (<ErrorMessage error={item.error} />) : null;
    let loadingModule = (!item || item.loading || !item[contentKey] && !item.error) ? (<Loading height={height} />) : null;

    let projectWrapper = (
        <div>
            {errorModule}
            {loadingModule}
        </div>
    );

    if (errorModule || loadingModule) {
        return projectWrapper;
    }

    return null;

}

function LoadingAndError(props) {

    let { contentKey, height, item, noInitialTransition } = props;

    let errorModule = item && item.error ? (<ErrorMessage error={item.error} />) : null;
    let loadingModule = (!item || item.loading || !item[contentKey] && !item.error) ? (<Loading height={height} noInitialTransition={noInitialTransition} />) : null;

    let projectWrapper = (
        <div className="item--page">
            <div className="item--page-top">
                {errorModule}
                {loadingModule}
            </div>
        </div>
    );

    if (errorModule || loadingModule) {
        return projectWrapper;
    }

    return null;

}

function loadingOrErrorCheck(item, contentKey) {
    let errorModule = item && item.error;
    let loadingModule = (!item || item.loading || !item[contentKey] && !item.error);
    return errorModule || loadingModule;
}

export { loadingOrErrorCheck, LoadingAndError, LoadingAndErrorSimple };
