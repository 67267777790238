function loadScript(url, nonce) {
    return new Promise((resolve, reject) => {
        if (typeof document === 'undefined') {
            return resolve();
        }

        const element = document.createElement('script');

        element.src = url;
        element.nonce = nonce;

        element.onload = resolve;
        element.onerror = reject;

        document.body.appendChild(element);
    });
}

export default loadScript;
