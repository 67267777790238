import { hot } from 'react-hot-loader/root';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Global } from '@emotion/core';
import globalStyles from './global-styles';
import style from '../../variables/style';
import {
    unsubscribe as unsubscribeToViewport,
    subscribe as subscribeToViewport,
} from '../../actions/viewport';
import {
    unsubscribe as unsubscribeToConnection,
    subscribe as subscribeToConnection,
} from '../../actions/connection';
import Footer from '../../containers/footer';
import CookiePreferences from '../cookie-preferences';
import PageContainer from '../../containers/page';
import getFeatureSupport from '../../helpers/feature-support';
import SearchContainer from '../../containers/search';
import { configureAnchors } from 'react-scrollable-anchor';
import Header from '../../containers/header';
import { logOutUser } from '../../actions/user';
import { startHeatbeat, stopHeartbeat } from '../../actions/heartbeat';

import './style.styl';

class App extends React.Component {
    static propTypes = {
        alertShown: PropTypes.bool,
        anchorpointOffset: PropTypes.number,
        dispatch: PropTypes.func,
        generalOptions: PropTypes.object,
        history: PropTypes.object,
        localization: PropTypes.object,
        menu: PropTypes.array,
        mobile: PropTypes.string,
        pageTitle: PropTypes.string,
        registerFetchAction: PropTypes.func,
        renderType: PropTypes.string,
        settings: PropTypes.object,
        showFooter: PropTypes.bool,
        showFooterTop: PropTypes.bool,
        showHeader: PropTypes.bool,
        isBankPage: PropTypes.bool,
        user: PropTypes.object,
        viewport: PropTypes.object,
        subMenuOpen: PropTypes.number,
        isClusterPage: PropTypes.bool,
        isBankPageMobile: PropTypes.bool,
        location: PropTypes.shape({
            pathname: PropTypes.string,
            search: PropTypes.string,
        }),
    };

    static childContextTypes = {
        connectionType: PropTypes.func,
        featureIsEnabled: PropTypes.func,
        featureSupport: PropTypes.object,
        generalOptions: PropTypes.object,
        imageHost: PropTypes.string,
        isMobile: PropTypes.func,
        localize: PropTypes.func,
        registerFetchAction: PropTypes.func,
        renderType: PropTypes.string,
        settings: PropTypes.object,
    };

    static contextTypes = {
        store: PropTypes.object,
    };

    componentDidMount() {
        // Potential bottleneck when using subscribeToViewport(). See actions/viewport.js file for an explanation.
        subscribeToViewport()(this.props.dispatch);
        subscribeToConnection()(this.props.dispatch, this.context.store);
        configureAnchors({ offset: this.props.anchorpointOffset });
        this.startHeartbeatIfAuthenticated();
    }

    componentDidUpdate(prevProps) {
        // Transitioning from !isAuthenticated to isAuthenticated should start
        // the heartbeat polling. Transitioning The other way should stop it.
        if (!prevProps.user.isAuthenticated) {
            this.startHeartbeatIfAuthenticated();
        } else if (!this.props.user.isAuthenticated) {
            stopHeartbeat();
        }
    }

    componentWillUnmount() {
        unsubscribeToViewport()(this.props.dispatch);
        unsubscribeToConnection()(this.props.dispatch);
    }

    componentWillReceiveProps(nextProps) {
        const nextAnchorpointOffset = nextProps.anchorpointOffset;
        if (this.props.anchorpointOffset !== nextAnchorpointOffset) {
            configureAnchors({ offset: nextAnchorpointOffset });
        }
    }

    getChildContext() {
        return {
            registerFetchAction: function(...args) {
                this.props.registerFetchAction(...args);
            }.bind(this),

            // Holds the url to the image service, will be used by the image component
            imageHost: this.props.settings.imageHost,
            renderType: this.props.renderType,
            settings: this.props.settings,
            featureSupport: getFeatureSupport(this.props.settings.userAgent),
            localize: (id, defaultString) => {
                let language = this.props.localization.languages[
                    this.props.localization.language
                ];
                if (!language) {
                    return `No language strings found for "${this.props.localization.language}"`;
                }
                if (language[id]) {
                    return language[id].split('~').join('\u00AD');
                }
                if (defaultString === '' || defaultString) {
                    return defaultString.split('~').join('\u00AD');
                }
                return `No localization for "${id}"`;
            },
            generalOptions: this.props.generalOptions,
            connectionType: () => this.props.settings.connectionType,
            isMobile: () => this.props.settings.mobile,
            featureIsEnabled: key => {
                const correctFlags = this.props.settings.featureFlags;

                // Check the chain of feature flags
                const keys = key.split('.');
                const result = keys.reduce(
                    (result, keyPart) => {
                        const delimiter = result.key ? '.' : '';
                        result.key = `${result.key}${delimiter}${keyPart}`;

                        if (
                            correctFlags[result.key] === true ||
                            correctFlags[result.key] === false
                        ) {
                            result.value =
                                result.value && correctFlags[result.key];
                            result.hits++;
                        }
                        return result;
                    },
                    { hits: 0, value: true, key: '' }
                );

                return result.hits === keys.length && result.value;
            },
        };
    }

    render() {
        const {
            mobile,
            showFooter,
            showHeader,
            isBankPage,
            isBankPageMobile,
            subMenuOpen,
            isClusterPage,
            showFooterTop,
            alertShown,
            viewport,
        } = this.props;

        const columnClasses = classNames(
            'app--column',
            { 'app--column-desktop-padding': subMenuOpen === 0 },
            { 'app--column-desktop-padding-submenu-1': subMenuOpen === 1 },
            { 'app--column-desktop-padding-submenu-2': subMenuOpen === 2 },
            {
                'app--column-desktop-padding with-alert':
                    subMenuOpen === 0 && alertShown,
            },
            {
                'app--column-desktop-padding-submenu-1 with-alert':
                    subMenuOpen === 1 && alertShown,
            },
            {
                'app--column-desktop-padding-submenu-2 with-alert':
                    subMenuOpen === 2 && alertShown,
            },
            { 'app--column-cluster-page-padding': isClusterPage },
            { 'app--column-mobile-padding': viewport.width < style.breakpoints.desktopMaxWidth },
            {
                'app--column-bank-mobile-padding': isBankPageMobile,
            }
        );

        return (
            <div className="app">
                <Global styles={globalStyles} />
                <CookiePreferences />

                {showHeader && <Header />}

                <Helmet>
                    <title>{this.props.pageTitle}</title>
                </Helmet>

                <main className={columnClasses}>
                    <Route
                        path="/:slug*"
                        render={props => {
                            const wrapperClasses = classNames(
                                'app--page-wrapper',
                                isBankPage && 'app--page-wrapper--bank'
                            );
                            const key =
                                props.location.pathname + props.location.search;

                            return mobile ? (
                                <TransitionGroup component={null}>
                                    <CSSTransition
                                        key={key}
                                        classNames="app--page-wrapper--transition"
                                        timeout={200}
                                    >
                                        <div className={wrapperClasses}>
                                            <PageContainer {...props} />
                                        </div>
                                    </CSSTransition>
                                </TransitionGroup>
                            ) : (
                                <div key={key} className={wrapperClasses}>
                                    <PageContainer {...props} />
                                </div>
                            );
                        }}
                    />
                    <SearchContainer history={this.props.history} />
                </main>

                {showFooter && (
                    <Footer
                        showTop={showFooterTop}
                        version={this.props.settings.version}
                        viewport={this.props.viewport}
                        className="app--column"
                    />
                )}
            </div>
        );
    }

    startHeartbeatIfAuthenticated() {
        if (this.props.user.isAuthenticated) {
            startHeatbeat(this.props.dispatch, this.context.store);
        }
    }

    onLogoutButtonClick() {
        logOutUser()(this.props.dispatch, this.context.store.getState());
    }
}

const mapStateToProps = state => {
    const page = state.pages && state.pages.list[state.pages.current];
    const isBankPageMobile =
        state.pages.isBankPage && state.viewport.isMobileDevice;

    return {
        anchorpointOffset: state.anchorpoint.offset,
        generalOptions: state.generalOptions,
        localization: state.localization,
        loginUrl: state.settings.loginUrl,
        mobile: state.settings.mobile,
        pageTitle: state.info.title,
        settings: state.settings,
        showFooterTop:
            !isBankPageMobile &&
            !(page && page.content && page.content.cluster_page),
        showFooter:
            !isBankPageMobile &&
            !(page && page.content && page.content.remove_footer),
        showHeader: !(page && page.content && page.content.remove_header),
        subMenuOpen: state.headerMenu.subMenuOpen,
        isBankPage: state.pages.isBankPage,
        ui: state.ui,
        user: state.user,
        viewport: state.viewport,
        isClusterPage: page && page.content && page.content.cluster_page,
        alertShown: state.alerts.showAlert,
        cookiesEnabled: state.settings.cookiesEnabled,
        isBankPageMobile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    };
};

const AppPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
export default hot(AppPage);
