import React from 'react';
import PropTypes from 'prop-types';
import { useExpandedItems } from '../../helpers';
import MenuBar from './menu-bar';
import Wrapper from './wrapper';
import { Appear as Modal } from '../../../../components/bank/modal';
import './style.styl';
import { css } from '@emotion/core';

const MobileMenuComponent = ({
    currentPage,
    isOpen,
    labels,
    logOut,
    menuBar,
    menuItems,
    onToggleMenu,
}) => {
    const { expandedItems, setExpandedItems } = useExpandedItems({
        menuItems,
        currentPage,
    });

    const toggleMenu = () => onToggleMenu(isOpen);

    return (
        <>
            <Modal
                animation={{
                    to: { top: '0', opacity: 1 },
                    from: { top: '100vh', opacity: 1 },
                }}
                open={isOpen}
                usePortal={true}
                overlay={false}
                useDisableScroll={true}
                innerStyle={css`
                    width: 100%;
                    height: 100%;
                `}
            >
                <Wrapper
                    currentPage={currentPage}
                    expandedItems={expandedItems}
                    isOpen={isOpen}
                    logOut={logOut}
                    menu={menuItems}
                    onToogleMenu={toggleMenu}
                    setExpandedItems={setExpandedItems}
                />
            </Modal>
            <MenuBar
                currentPage={currentPage}
                labels={labels}
                menu={menuBar}
                onToogleMenu={toggleMenu}
            />
        </>
    );
};

MobileMenuComponent.propTypes = {
    currentPage: PropTypes.string,
    isOpen: PropTypes.bool,
    labels: PropTypes.object,
    logOut: PropTypes.object,
    menuBar: PropTypes.array,
    menuItems: PropTypes.array,
    onToggleMenu: PropTypes.func,
};

export default MobileMenuComponent;
