import produce from 'immer';

import {
    USER_LOGGED_IN,
    LOG_OUT,
    RECEIVE_ERROR,
    REQUEST_RESOURCE,
    RECEIVE_RESOURCE,
} from '../actions/user';

import { HEARTBEAT } from '../actions/heartbeat';

import setCommonReducerProperties from '../helpers/set-common-reducer-properties';

const initialState = {
    errorMessage: null,
    details: {},
    isAuthenticated: false,
    tokenCreated: null,
    tokenExpires: null,
    ...setCommonReducerProperties(),
};

function user(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case USER_LOGGED_IN:
                // TODO: Include user details
                draft.details = {};
                draft.error = false;
                draft.loading = false;
                draft.loaded = true;
                draft.isAuthenticated = true;
                draft.termsAndConditionsPending =
                    action.termsAndConditionsPending;
                draft.termsAndConditionsUrl = action.termsAndConditionsUrl;
                break;

            case LOG_OUT:
                draft.details = {};
                draft.error = false;
                draft.loading = false;
                draft.loaded = false;
                draft.isAuthenticated = false;
                break;


            case HEARTBEAT:
                (draft.tokenExpires = action.tokenExpires),
                (draft.isAuthenticated = action.authenticated);
                draft.tokenCreated = Date.now();
                draft.termsAndConditionsPending =
                    action.termsAndConditionsPending;
                draft.hasTopUpOffer = action.hasTopUpOffer;
                draft.termsAndConditionsUrl = action.termsAndConditionsUrl;
                break;

            // TODO: this action is defined and used in both post-resource and here
            case RECEIVE_ERROR:
                draft.error = true;
                draft.errorMessage = action.errorMessage;
                draft.loading = false;
                draft.loaded = false;
                break;

            case REQUEST_RESOURCE: {
                draft.loading = true;
                break;
            }

            case RECEIVE_RESOURCE: {
                draft.loading = false;
                draft.loaded = true;
                draft.error = false;
                break;
            }
        }
    });
}

export default user;
