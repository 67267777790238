import { fetchResource, parseHeaderData } from '../../helpers/fetch-helper';

export const REQUEST_GUIDE = 'REQUEST_GUIDE';
export const RECEIVE_GUIDE = 'RECEIVE_GUIDE';

export function requestGuide() {
    return {
        type: REQUEST_GUIDE,
    };
}

export function receivedGuide({ guide }) {
    return {
        type: RECEIVE_GUIDE,
        guide,
    };
}

export function getGuide(guideId) {
    return async function(dispatch, state, requestOptions) {
        if (guideId === state.humany.guide.Id) {
            return;
        }

        const url = `${state.settings.apiUrl}/humany/guides/${guideId}`;
        dispatch(requestGuide());
        try {
            const guide = await fetchResource(
                url,
                parseHeaderData(state.requestData, requestOptions)
            );
            dispatch(receivedGuide({ guide }));
        } catch (err) {
            return err;
        }
    };
}
