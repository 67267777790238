import styled from '@emotion/styled';
import { rgba } from 'emotion-rgba';
import { css } from '@emotion/core';
import Link from '../../../../../components/link';

export const StyledMenuItemList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    ${p =>
        p.depth === 0 &&
        css`
            border-bottom: 1px solid ${rgba(p.theme.colors.white, 0.2)};
            display: block;
            padding: 1.5rem 0;
            &:first-of-type {
                border-top: 1px solid ${rgba(p.theme.colors.white, 0.2)};
            }
        `}
`;

export const StyledMenuItem = styled.li``;

export const StyledMenuItemChildList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    width: 100%;
    padding: 1.2rem 0 0;
`;

export const StyledMenuItemLink = styled(Link)`
    font-weight: 400;
    font-size: 2rem;
    margin: 0.25rem 0;
    padding: 0.25rem 0;
    display: block;
    ${p =>
        p.currentPage &&
        css`
            font-weight: bold;
        `}
    ${p =>
        p.depth === 0 &&
        css`
            font-weight: bold;
            padding: 0;
            margin: 0;
        `}
`;

export const StyledMenuItemFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledMenuItemMessages = styled.span`
    display: inline-flex;
    text-align: center;
    position: relative;
    margin-left: 1.9rem;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    &:before {
        content: '';
        background-color: red;
        position: absolute;
        width: 23px;
        height: 23px;
        z-index: -1;
        border-radius: 50%;
    }
`;
