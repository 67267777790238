import produce from 'immer';

import * as connectionActions from '../actions/connection';

const initialState = {
    apiUrl: '',
    imageHost: '',
    language: 'en',
    connectionType: '',
};

function settings (state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            // TODO this action does not seem to be dispatched anywhere
            case 'SET_API_URL':
                draft.apiUrl = action.apiUrl;
                break;

            // TODO this action does not seem to be dispatched anywhere
            case 'SET_IMAGE_HOST':
                draft.imageHost = action.imageHost;
                break;

            case connectionActions.CONNECTION_UPDATE_TYPE:
                draft.connectionType = action.connectionType;
                break;
        }
    });
}

export default settings;
