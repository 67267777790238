import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import Text from '../../../../../components/text';
import Icons from '../../../../../components/icons';
import {
    StyledMenuBar,
    StyledMenuBarItem,
    StyledBarItemLink,
    StyledHamburger,
} from './style';

const MenuBar = ({ currentPage, labels, menu = [], onToogleMenu }) => {
    const theme = useTheme();

    const hamburger = (
        <StyledMenuBarItem key={'hamburger'} onClick={onToogleMenu}>
            <StyledHamburger active={false} type="slider" />
            <Text
                color={theme.colors.white}
                margin=".5rem 0 0"
                size={'xxsmall'}
            >
                {labels.hamburger}
            </Text>
        </StyledMenuBarItem>
    );

    const items = menu.map((item, index) => (
        <StyledMenuBarItem key={`${item.link.url}-${index}`}>
            <StyledBarItemLink
                active={currentPage === item.link.url}
                to={item.link.url}
            >
                {item.icon && (
                    <Icons
                        color={theme.colors.white}
                        height={24}
                        name={item.icon}
                        width={24}
                    />
                )}
                <Text
                    color={theme.colors.white}
                    margin=".5rem 0 0"
                    size={'xxsmall'}
                >
                    {item.link.title}
                </Text>
            </StyledBarItemLink>
        </StyledMenuBarItem>
    ));

    return <StyledMenuBar>{[...items, hamburger]}</StyledMenuBar>;
};

MenuBar.propTypes = {
    currentPage: PropTypes.string,
    labels: PropTypes.object,
    menu: PropTypes.array,
    onToogleMenu: PropTypes.func,
};

export default MenuBar;
