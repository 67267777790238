import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const StyledTableWrapper = styled.div`
    width: 100%;

    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1rem;
        line-height: 1.5;

        @media screen and (max-width: ${p => p.theme.breakpoints.bigSmartphone}px) {
            font-size: 1.8rem;
        }
    }

    tbody tr:hover {
        background-color: ${p => p.theme.colors.superLightGrey};
    }

    tfoot tr {
        border-top: 2px solid ${p => p.theme.colors.darkGrey};
        border-bottom: 0;
    }

    td {
        @media screen and (min-width: ${p => p.theme.breakpoints.bigSmartphone}px) {
            &:first-of-type {
                padding-top: 1.5rem;
            }

            &:last-of-type {
                padding-bottom: 1.5rem;
            }

            :empty {
                padding: 0;
            }
        }
    }

    th {
        border-bottom: 2px solid ${p => p.theme.colors.darkGrey};
    }

    td,
    th {
        padding: 1rem .5rem;

        @media screen and (min-width: ${p => p.theme.breakpoints.bigSmartphone}px) {
            padding: 2rem 1rem;
        }
    }

    tr {
        border-bottom: 1px solid ${p => p.theme.colors.superLightGrey};
    }

    .has-text-align-left {
        text-align: left;
    }

    .has-text-align-center {
        text-align: center;
    }

    .has-text-align-right {
        text-align: right;
    }

    .is-style-stripes tbody tr:nth-of-type(even) {
        background-color: ${p => p.theme.colors.superLightGrey};
    }

    .has-fixed-layout {
        table-layout: fixed;
    }

    @media screen and (max-width: ${p => p.theme.breakpoints.bigSmartphone}px) {
        figure:not(.no-mobile-layout) table {
            thead,
            tfoot {
                display: none;
            }

            tbody tr {
                border-top: 1px solid ${p => p.theme.colors.superLightGrey};
                &:first-of-type {
                    border: 0;
                }
                &:hover {
                    background-color: ${p => p.theme.colors.superLightGrey};
                }
            }

            td {
                display: flex;
                padding: .5rem;

                &:first-of-type {
                   padding-top: 1.5rem;
                }

                &:last-of-type {
                    padding-bottom: 1.5rem;
                }
            }

            thead + tbody td::before {
                content: attr(label);
                font-weight: bold;
                flex: 1;
                text-align: left;
                padding-right: 1rem;

                :empty {
                    display: none;
                }
            }

            ${p => p.hasFooters && tdWithFooter}
        }
    }
`;

const tdWithFooter = css`
    table td::after {
        content: attr(footer);
        font-weight: bold;
        flex: 1;
        text-align: right;
        padding-left: 1rem;
    }
`;
