import PropTypes from 'prop-types';

import React from 'react';
import ErrorMessage from '../error-message';

import './style.styl';

class DynamicModuleGeneratorErrorBoundary extends React.Component {

    static propTypes = {
        children: PropTypes.any,
        name: PropTypes.string,
    }

    static getDerivedStateFromError (error) {
        error = error || {};
        if (!error.body) {
            error.body = {
                name: 'Javascript frontend error.'
            };
        }

        return { hasError: true, error };
    }

    constructor (props) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        };
    }

    render () {

        if (this.state.hasError) {
            console.error('Error in "%s"', this.props.name, this.state.error); // eslint-disable-line
            return (
                <div className="dynamic-module-generator-error-boundry">
                    <ErrorMessage error={this.state.error}>
                        {this.props.name} has crashed
                    </ErrorMessage>
                </div>
            );
        }

        return (
            <React.Fragment> { this.props.children } </React.Fragment>
        );

    }

}

export default DynamicModuleGeneratorErrorBoundary;
