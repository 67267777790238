import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../../components/button-component';
import { StyledControllersContainer } from './style';

const Controllers = ({ configs = [], flexDirection = 'row' }) => {
    return (
        <StyledControllersContainer $flexDirection={flexDirection}>
            {configs.map((conf, index) => {
                return (
                    <Button textAlign="center" fullWidth {...conf} key={index}>
                        {conf.text}
                    </Button>
                );
            })}
        </StyledControllersContainer>
    );
};

Controllers.propTypes = {
    configs: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.any,
            background: PropTypes.string,
            color: PropTypes.string,
            border: PropTypes.object,
        })
    ),
    flexDirection: PropTypes.oneOf(['row', 'column']),
};

export default Controllers;
