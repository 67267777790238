const currencies = {
    dk: 'DKK',
    fi: 'EUR',
    no: 'NOK',
    se: 'SEK',
};

export function getCurrencyCode(locale = 'sv-SE') {
    const [, country] = locale.toLowerCase().split('-');

    return currencies[country];
}

export function getCurrencySymbol(
    locale,
    currencyCode = getCurrencyCode(locale)
) {
    return (0)
        .toLocaleString(locale, {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
}

function currencyWithTrailingSymbol(number, locale, decimals, currencyCode) {
    return (
        number.toLocaleString(locale, {
            style: 'decimal',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }) +
        ' ' +
        getCurrencySymbol(locale, currencyCode)
    );
}

function shouldForceTrailingSymbol(locale) {
    const [, country] = locale.toLowerCase().split('-');

    return ['dk', 'no'].includes(country);
}

export function currency({ number, locale, decimals, currencyCode }) {
    if (typeof number !== 'number') {
        number = parseFloat(number);
    }

    const decimalNumbers =
        decimals &&
        decimals.show &&
        (decimals.withZeros || !Number.isInteger(number))
            ? 2
            : 0;

    if (shouldForceTrailingSymbol(locale)) {
        return currencyWithTrailingSymbol(
            number,
            locale,
            decimalNumbers,
            currencyCode
        );
    }

    const numberToString = number.toLocaleString(locale, {
        style: 'currency',
        currency: currencyCode || getCurrencyCode(locale),
        currencyDisplay: 'symbol',
        minimumFractionDigits: decimalNumbers,
        maximumFractionDigits: decimalNumbers,
    });

    return numberToString;
}

export function percent(number, locale = 'sv-SE') {
    if (typeof number !== 'number') {
        number = parseFloat(number);
    }

    return (
        number.toLocaleString(locale, {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + ' %'
    );
}

export function removeNonNumerals(string) {
    return string.split(/[^\d]/).join('');
}

export function maxMobilePhoneLength(phone, country) {
    const internationalFormat = phone && phone[0] === '+';
    switch (country) {
        case 'sv-se':
            return internationalFormat ? 12 : 10;
        case 'nb-no':
            return internationalFormat ? 11 : 8;
        case 'fi-fi':
            return internationalFormat ? 14 : 11;
        case 'da-dk':
            return internationalFormat ? 14 : 11;
        default:
            return 15;
    }
}
