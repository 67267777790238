/**
 * @file             : services/frontend/src/www-src/scripts/containers/header-menu/desktop/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 15.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */

import './style.styl';

import React from 'react';
import PropTypes from 'prop-types';
import { fetchHeaderMenu, fetchClusterMenu } from '../../../actions/header-menu';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderMenuItem from '../../../components/header-menu-item';
import classNames from 'classnames';
import Link from '../../../components/link';
import Logo from '../../../components/logo';
import Row from '../../../components/row';
import Column from '../../../components/column';
import style from '../../../variables/style';
import Icons from '../../../components/icons';
import Alerts from '../../alerts';
import { openSearchModal } from '../../../actions/search';
import { subMenuOpen } from '../../../actions/header-menu';
import { setAnchorPointOffset } from '../../../actions/anchorpoint';

class HeaderMenu extends React.Component {
    static propTypes = {
        dispatch: PropTypes.func,
        headerMenuLoading: PropTypes.bool,
        headerMenuNotFound: PropTypes.bool,
        params: PropTypes.object,
        match: PropTypes.object,
        headerMenu: PropTypes.object,
        registerFetchAction: PropTypes.func,
        history: PropTypes.object,
        user: PropTypes.object,
        location: PropTypes.object,
        marketType: PropTypes.string,
        currentPage: PropTypes.string,
        currentPageRoot: PropTypes.string,
        loginButton: PropTypes.object,
        onSearchButtonClick: PropTypes.func,
        onSubMenuOpen: PropTypes.func,
        marketMenu: PropTypes.array,
        filterVisibleMenuItems: PropTypes.func.isRequired,
    };

    constructor(props, context) {

        super(props);

        // This internal state is only ever set as a reaction to prop changes,
        // i.e. it is not really internal state, but an internal derivative
        // of global state, and is thus safe.
        this.state = this.stateFromProps(props);
        
        let { marketType } = props;
    
        if (marketType) {
            const slug = this.getMenuSlugByMarketType(marketType);
            context.registerFetchAction(
                marketType === 'cluster'
                    ? fetchClusterMenu(slug)
                    : fetchHeaderMenu(slug),
                'fetch-header-menu'
            );
        }
    }

    getMenuSlugByMarketType(marketType) {
        const { currentPageRoot } = this.props;
        // For cluster pages, use same menu for both desktop and mobile
        return marketType === 'cluster' ? currentPageRoot : `header_menu_${marketType}`;
    }

    componentWillReceiveProps(newProps) {
        if (newProps.marketType !== this.props.marketType) {
            const slug = this.getMenuSlugByMarketType(newProps.marketType);
            this.context.registerFetchAction(
                newProps.marketType === 'cluster'
                    ? fetchClusterMenu(slug)
                    : fetchHeaderMenu(slug),
                'fetch-header-menu'
            );
        }

        if (
            (newProps.headerMenu &&
                newProps.currentPage != this.props.currentPage) ||
            newProps.headerMenu != this.props.headerMenu
        ) {
            this.setState(this.stateFromProps(newProps));
        }
    }

    stateFromProps({headerMenu, currentPage, onSubMenuOpen}) {
        if (!headerMenu) {
            return {};
        }

        let menuItems =
            headerMenu.items && headerMenu.items.allItems
                ? headerMenu.items.allItems
                : [];

        // If a top-level menu is already selected, change the search order
        // so that the selected menu is always checked first. This avoids
        // switching from one top-level to another when the same sub-item
        // exists in several sub-menus.
        if (this.state && this.state.selectedFirstLevelMenuId) {
            let selectedMenu = menuItems.find(
                menu => menu.ID === this.state.selectedFirstLevelMenuId
            );
            menuItems = [selectedMenu].concat(
                menuItems.filter(m => m !== selectedMenu)
            );
        }
        // Find the first menu and/or sub-menus respective items that matches the active
        // page URL and store their IDs as selected.
        const selectedItemIds = this.getSelectedItemIds(
            menuItems,
            currentPage
        );
        onSubMenuOpen(selectedItemIds.menuLevel);

        return selectedItemIds;
    }

    getSelectedItemIds(menuItems, currentPageUrl, menuLevel = 0) {
        const levelStringCapitalized = this.getLevelString(menuLevel, true);

        let selectedMenuItemIds = {};

        const selectedMenuItem = this.getSelectedItem(
            menuItems,
            currentPageUrl
        );
        if (selectedMenuItem) {
            selectedMenuItemIds = {
                menuLevel,
                ...selectedMenuItemIds,
                ...{
                    [`selected${levelStringCapitalized}LevelMenuId`]: selectedMenuItem.ID,
                },
            };
            if (selectedMenuItem.children) {
                const subSelectedMenuItemId = this.getSelectedItemIds(
                    selectedMenuItem.children,
                    currentPageUrl,
                    menuLevel + 1
                );
                if (subSelectedMenuItemId) {
                    selectedMenuItemIds = {
                        ...selectedMenuItemIds,
                        ...subSelectedMenuItemId,
                    };
                }
            }
        } else {
            selectedMenuItemIds = {
                menuLevel,
                ...selectedMenuItemIds,
                ...{ [`selected${levelStringCapitalized}LevelMenuId`]: null },
            };
        }

        return selectedMenuItemIds;
    }

    getSelectedItem(menuItems, currentPageUrl) {
        if (!menuItems) {
            return null;
        }

        let selectedItem = menuItems.find(
            item =>
                item &&
                this.isPageInCurrentUrl(item.url, currentPageUrl) &&
                !item.place_in_top_menu
        );

        if (!selectedItem) {
            selectedItem = menuItems.find(
                item =>
                    item && this.isPageInCurrentUrl(item.url, currentPageUrl)
            );
        }

        return selectedItem;
    }

    isPageInCurrentUrl(url, currentPage) {
        const currentPageUrl = '/' + currentPage + '/';

        if (url === currentPageUrl) {
            return true;
        } else if (currentPageUrl.includes(url)) {
            return true;
        } else {
            return false;
        }
    }

    getLevelString(level, capitalized = false) {
        const numberToMenuLevel = [
            'first',
            'second',
            'third',
            'fourth',
            'fifth',
        ];
        return capitalized
            ? `${numberToMenuLevel[level]
                .slice(0, 1)
                .toUpperCase()}${numberToMenuLevel[level].slice(1)}`
            : numberToMenuLevel[level];
    }

    getNotificationsForMenuItem(menuItem) {
        function recurse(menuItem) {
            if (
                menuItem.badge_type &&
                !notifications.includes(menuItem.badge_type)
            ) {
                notifications.push(menuItem.badge_type);
            }

            if (Array.isArray(menuItem.children)) {
                menuItem.children.forEach(recurse);
            }
        }

        const notifications = [];

        recurse(menuItem);

        return notifications.reduce((result) => {
            return result;
        }, 0);
    }

    getRowBackgroundColor(level) {
        const colors = [
            style.colors.colorResursBlue,
            style.colors.colorResursDarkBlue,
        ];
        return level % 2 == 0 ? colors[0] : colors[1];
    }

    getSubMenusHtml(subMenuItems, level = 1) {
        const levelString = this.getLevelString(level);
        const levelStringCapitalized = this.getLevelString(level, true);

        let subChildren = null;

        const itemsHtml = subMenuItems.map(item => {
            const active =
                this.state[`selected${levelStringCapitalized}LevelMenuId`] ===
                item.ID;
            const menuItemClasses = classNames({
                ['header-menu--sub-list--item']: true,
                ['header-menu--sub-list--item--active--with-children']:
                    active && item.children,
                [`header--${levelString}-level-menu--list--item`]: true,
                [`header--${levelString}-level-menu--list--item--active`]: active,
            });
            if (item.children && active) {
                subChildren = this.getSubMenusHtml(item.children, level + 1);
            }
            const notifications = this.getNotificationsForMenuItem(item);

            const linkClasses = classNames({
                ['header-menu--sub-list--item--link']: true,
                ['header-menu--sub-list--item--link--active']: active,
            });

            const notificationsClasses = classNames({
                'header-menu--sub-list--item--messages-badge': true,
                'header-menu--sub-list--item--messages-badge-too-many':
                    notifications > 99,
            });

            return (
                <li key={item.ID} className={menuItemClasses}>
                    <Link className={linkClasses} to={item.url} look="light">
                        {item.title}
                    </Link>
                    {notifications > 0 ? (
                        <div className={notificationsClasses}>
                            {notifications < 99 ? notifications : '+10'}
                        </div>
                    ) : null}
                </li>
            );
        });

        return (
            <div className={`header--${levelString}-level-menu`}>
                <Row
                    shallow={false}
                    fullWidthRowBackground={true}
                    rowBackgroundColor={this.getRowBackgroundColor(level)}
                >
                    <Column width="11/12">
                        <ul
                            className={`header-menu--sub-list header--${levelString}-level-menu--list`}
                        >
                            {itemsHtml}
                        </ul>
                    </Column>
                </Row>
                {subChildren}
            </div>
        );
    }

    getTopLevelMenuHtml(headerMenu) {
        const { items, loading } = headerMenu || {};

        let topLevelMenu = items
            ? items.firstLevelMenuItems.map(menuItem => {
                const active =
                      this.state.selectedFirstLevelMenuId === menuItem.ID;

                const menuItemClasses = classNames({
                    'header-menu-item': true,
                    'header-menu-item--active': active,
                    'header-menu-item--active--with-children':
                          active && menuItem.children,
                });

                const notifications = this.getNotificationsForMenuItem(
                    menuItem
                );

                return (
                    <div key={menuItem.ID} className={menuItemClasses}>
                        <HeaderMenuItem
                            badgeType={menuItem.badge_type}
                            linkProp={menuItem.url}
                            title={menuItem.title}
                            active={active}
                            notifications={notifications}
                        />
                    </div>
                );
            })
            : [];

        if (loading) {
            topLevelMenu = [<div>Loading...</div>];
        }

        return topLevelMenu;
    }

    getMarketMenuItems() {
        const marketMenu = this.props.marketMenu;

        let marketItems = marketMenu.map(menu => {
            let isActive = this.props.marketType === menu.market_type;
            const liClassNames = classNames(
                'header--top-bar--market-menu--item',
                { 'header--top-bar--market-menu--item--active': isActive }
            );
            const linkClassNames = classNames(
                'header--top-bar--market-menu--item--link',
                { 'header--top-bar--market-menu--item--active': isActive }
            );

            return (
                <li key={menu.ID} className={liClassNames}>
                    <Link to={menu.url} className={linkClassNames} look="dark">
                        {menu.title}
                    </Link>
                </li>
            );
        });

        return marketItems;
    }

    getTopMenuItems() {
        const { headerMenu } = this.props;

        const topMenu =
            headerMenu && headerMenu.items
                ? headerMenu.items.topMenuItems
                : null;

        const topMenuItems = topMenu
            ? topMenu.map(menuItem => {
                // even though this menu is in another location on screen. It basically
                // is a part of the header menu first level menu.
                const active =
                      this.state.selectedFirstLevelMenuId === menuItem.ID;

                const liClassNames = classNames(
                    'header--top-menu--list--item',
                    { 'header--top-menu--list-item--active': active }
                );
                const linkClassNames = classNames(
                    'header--top-menu--list-item--link',
                    { 'header--top-menu--list-item--link--active': active }
                );
                return (
                    <li key={menuItem.ID} className={liClassNames}>
                        <Link
                            to={menuItem.url}
                            className={linkClassNames}
                            look="dark"
                        >
                            {menuItem.title}
                        </Link>
                    </li>
                );
            })
            : null;

        return topMenuItems;
    }

    headerTop() {
        const marketItems = this.getMarketMenuItems();
        const topMenuItems = this.getTopMenuItems();

        return (
            <div className="header--top-bar">
                <Row>
                    <Column width="1/2" className="header--markets">
                        <ul className="header--top-bar--market-menu">
                            {marketItems}
                        </ul>
                    </Column>
                    <Column width="1/2" className="header--top-menu">
                        <ul className="header--top-bar--top-menu">
                            {topMenuItems}
                        </ul>
                    </Column>
                </Row>
            </div>
        );
    }

    getMenuNotFoundHtml() {
        return (
            <nav className="header">
                <div className="header--menu">
                    <div className="header--menu-error">
                        Couldnt find a menu
                    </div>
                </div>
            </nav>
        );
    }

    render() {
        const {
            headerMenu,
            loginButton,
            onSearchButtonClick,
            marketType,
            currentPage,
        } = this.props;

        if (headerMenu && headerMenu.loading) {
            return (
                <nav className="" onClick={e => e.stopPropagation()}>
                    <div className="header--menu">Loading...</div>
                </nav>
            );
        }

        let mainMenu = [];
        if (
            !headerMenu ||
            !headerMenu.items ||
            !headerMenu.items.firstLevelMenuItems ||
            headerMenu.items.firstLevelMenuItems.length === 0
        ) {
            mainMenu = []; // return this.getMenuNotFoundHtml();
        } else {
            mainMenu = headerMenu.items.allItems.find(
                i => i.ID === this.state.selectedFirstLevelMenuId
            );
        }

        // const mainMenu = headerMenu.items.allItems.find(i => i.ID === this.state.selectedFirstLevelMenuId);
        let subMenusHtml = null;

        if (mainMenu && mainMenu.children) {
            subMenusHtml = this.getSubMenusHtml(mainMenu.children);
        }

        let userButton;

        userButton = (
            <div className="header--main--utility--buttons--login">
                <Link
                    to={loginButton.url || '#'}
                    className="header--main--utility--button"
                    gtmLabel = 'ya login click'
                    gtmValue = ''
                    gtmCategory = 'MyPages'
                >
                    <Icons name="avatar" width="24" height="24" />
                    <p className="header--main--utility--buttons--text">
                        {this.context.localize('log-in')}
                    </p>
                </Link>
            </div>
        );

        let startUrl = '/';
        if (marketType === 'cluster' && currentPage) {
            // For cluster pages the logotype should point to the topmost page in that cluster
            startUrl = `/${currentPage.split('/')[0]}/`;
        }

        const searchButton = (
            <div
                className="header--main--utility--button header--main--utility--buttons--search"
                onClick={onSearchButtonClick}
            >
                <Icons name="search" color="black" width="24" height="24" />
                <p className="header--main--utility--buttons--text">
                    {this.context.localize('search')}
                </p>
            </div>
        );

        return (
            // app container handles global close menu event. This container stop this execution.
            <div className="header header-menu">
                <Alerts ticker />
                {this.headerTop()}
                <div className="header--main">
                    <Row
                        shallow={false}
                        fullWidthRowBackground={true}
                        rowBackgroundColor="white"
                    >
                        <Column>
                            <div className="header--main--top">
                                <div className="header--main--left">
                                    <div className="header--logo-container">
                                        <Link
                                            to={startUrl}
                                            className="header--logo"
                                        >
                                            <Logo className="header--logo--img" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="header--main--right">
                                    <div className="header--main--top">
                                        <div className="header--main--top--nav">
                                            <nav
                                                className=""
                                                onClick={e =>
                                                    e.stopPropagation()
                                                }
                                            >
                                                <div className="header--menu">
                                                    {this.getTopLevelMenuHtml(
                                                        headerMenu
                                                    )}
                                                </div>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="header--main--utility--buttons">
                                        {marketType !== 'cluster' &&  (
                                            <React.Fragment>
                                                {userButton}
                                            </React.Fragment>
                                        )}
                                        {searchButton}
                                    </div>
                                </div>
                            </div>
                        </Column>
                    </Row>
                    {subMenusHtml}
                </div>
            </div>
        );
    }
}

HeaderMenu.contextTypes = {
    store: PropTypes.object,
    localize: PropTypes.func,
    registerFetchAction: PropTypes.func,
    onSearchButtonClick: PropTypes.func,
    featureIsEnabled: PropTypes.func,
};

const mapStateToProps = (state, props) => {
    let headerMenu = state.headerMenu.list[state.headerMenu.current];

    if (headerMenu && headerMenu.items) {

        // firstLevelMenuItems contains items that doesn't have "Place in top menu" checked in WP
        // allItems contains firstLevelMenuItems and "Place in top menu"
        // topMenuItems contains items that have "Place in top menu" checked in WP
        headerMenu.items.firstLevelMenuItems = props.filterVisibleMenuItems(
            headerMenu.items.firstLevelMenuItems
        );
        headerMenu.items.allItems = props.filterVisibleMenuItems(
            headerMenu.items.allItems
        );
        headerMenu.items.topMenuItems = props.filterVisibleMenuItems(
            headerMenu.items.topMenuItems
        );
    }

    return {
        headerMenu,
        currentPage: state.pages.current,
        currentPageRoot: state.pages.current.replace(/\/.*$/g, ''),
        user: state.user,
        marketType: state.info.marketType,
        language: state.localization.language,
        loginUrl: state.settings.loginUrl,
    };
};

const mapDispatchToProps = dispatch => ({
    dispatch,
    onSearchButtonClick: () => {
        dispatch(openSearchModal());
    },
    onSubMenuOpen: status => {
        const desktopPadding = style.desktopMenu.paddingTop;
        const submenuHeight = style.desktopMenu.submenuHeight;
        const offset = -(desktopPadding + (submenuHeight * status));
        dispatch(setAnchorPointOffset(offset));
        dispatch(subMenuOpen(status));
    },
});

const HeaderMenuContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(HeaderMenu)
);

export default HeaderMenuContainer;
