import loadScript from './load-script';

let loadingPromise;

function load(key, nonce) {
    loadingPromise = loadScript(`https://www.google.com/recaptcha/api.js?render=${key}`, nonce);

    if (typeof document !== 'undefined') {
        loadingPromise = loadingPromise.then(() => (
            new Promise(resolve => {
                /* global grecaptcha */
                grecaptcha.ready(resolve);
            })
        ));
    }

    return loadingPromise;
}

function execute(key) {
    return (loadingPromise || load(key)).then(() => grecaptcha.execute(key, { action: 'homepage' }));
}

export default {
    load,
    execute,
};
