import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Input from '../../components/input';
import Button from '../../components/button';
import Loading from '../../components/loading';
import Headline from '../../components/headline';
import MessageBox from '../../components/message-box';
import captcha from '../../helpers/captcha';
import { currency } from '../../helpers/numbers';

import './style.styl';

class GiftCardBalance extends React.Component {
    static vcConvertToProps(attrs) {
        const newProps = {
            currentBalanceLabel: attrs.current_balance_label,
            cardNumberInputErrorMessage: attrs.card_number_input_error_message,
            cardNumberInputInfoText: attrs.card_number_input_info_text,
            cardNumberLabel: attrs.card_number_label,
            errors: {
                403:
                    attrs.too_many_attempts_error ||
                    'Too many attempts. Wait 10 minutes then try again.',
                404: attrs.unknown_card_error || 'Unknown card',
                500:
                    attrs.server_error_error ||
                    'Server error, try again or contact support if the error continues.',
            },
            expirationDateLabel: attrs.expiration_date_label,
            formText: attrs.form_text,
            headline: attrs.headline,
            submitButtonText: attrs.submit_button_text,
            tryAgainButtonText: attrs.try_again_button_text,
        };

        return newProps;
    }

    static parseProps(attrs) {
        const newProps = {
            currentBalanceLabel: attrs.labels.currentBalanceLabel,
            cardNumberInputErrorMessage: attrs.labels.cardNumberInputErrorMessage,
            cardNumberInputInfoText: attrs.labels.cardNumberInputInfoText,
            cardNumberLabel: attrs.labels.cardNumberLabel,
            errors: {
                403:
                    attrs.labels.tooManyAttemptsError ||
                    'Too many attempts. Wait 10 minutes then try again.',
                404: attrs.labels.unknownCardError || 'Unknown card',
                500:
                    attrs.labels.serverError ||
                    'Server error, try again or contact support if the error continues.',
            },
            expirationDateLabel: attrs.labels.expirationDateLabel,
            formText: attrs.labels.formText,
            headline: attrs.labels.headline,
            submitButtonText: attrs.labels.submitButtonText,
            tryAgainButtonText: attrs.labels.tryAgainButtonText,
        };

        return newProps;
    }

    static propTypes = {
        captchaKey: PropTypes.string.isRequired,
        cardNumberInputErrorMessage: PropTypes.string.isRequired,
        cardNumberInputInfoText: PropTypes.string.isRequired,
        cardNumberLabel: PropTypes.string.isRequired,
        countryCode: PropTypes.oneOf(['DK', 'FI', 'NO', 'SE']).isRequired,
        currentBalanceLabel: PropTypes.string,
        errors: PropTypes.shape({
            403: PropTypes.string.isRequired,
            404: PropTypes.string.isRequired,
            500: PropTypes.string.isRequired,
        }).isRequired,
        expirationDateLabel: PropTypes.string,
        formText: PropTypes.string,
        headline: PropTypes.string,
        locale: PropTypes.string.isRequired,
        submitButtonText: PropTypes.string.isRequired,
        tryAgainButtonText: PropTypes.string.isRequired,
        location: PropTypes.object,
        history: PropTypes.object,
        nonce: PropTypes.string.isRequired,
    };

    state = this.getInitialState();

    constructor(...args) {
        super(...args);
        captcha.load(this.props.captchaKey, this.props.nonce);
    }

    componentDidMount() {
        const { cardnumber: cardNumber } = queryString.parse(
            this.props.location.search
        );
        if (cardNumber) {
            this.setState({ loading: true, inputValue: cardNumber });
            this.requestBalance(cardNumber);
        }
    }

    componentWillUnmount() {
        if (document.getElementsByClassName('grecaptcha-badge').length > 0) {
            document.getElementsByClassName('grecaptcha-badge')[0].remove();
        }
    }

    getInitialState() {
        return {
            balance: null,
            error: null,
            loading: false,
            inputValue: '',
        };
    }

    onInputChange = e => {
        this.setState({
            inputValue: e.target.value
                .split(/[^\d]+/)
                .join('')
                .slice(0, 16),
        });
    };

    async requestBalance(cardNumber) {
        try {
            const token = await captcha.execute(this.props.captchaKey);

            const url = '/api/gift-card-balance';
            const options = {
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                },
                body: JSON.stringify({
                    captchaToken: token,
                    cardNumber,
                }),
            };

            const response = await fetch(url, options);

            if (!response.ok) {
                this.setState({
                    loading: false,
                    error: this.props.errors[response.status],
                });

                return;
            }

            const { balance, expirationDate } = await response.json();

            this.setState({
                balance: currency({
                    number: balance,
                    locale: this.props.locale,
                    decimals: {
                        show: true,
                        withZeros: true,
                    },
                }),
                expirationDate,
                error: null,
                loading: false,
            });
        } catch (e) {
            this.setState({
                loading: false,
                error: this.props.errors[500],
            });
        }
    }

    onSubmit = async e => {
        e.preventDefault();

        if (!this.input.isValid()) {
            return;
        }

        this.setState({ loading: true });

        await this.requestBalance(this.state.inputValue);
    };

    reset = () => {
        this.setState(this.getInitialState());
        const { location, history } = this.props;
        const query = queryString.parse(location.search);
        delete query.cardNumber;

        history.replace({
            search: location.search.length
                ? '?' + queryString.stringify(query)
                : '',
        });
    };

    render() {
        const {
            cardNumberLabel,
            cardNumberInputErrorMessage,
            cardNumberInputInfoText,
            currentBalanceLabel,
            expirationDateLabel,
            headline,
            formText,
            submitButtonText,
            tryAgainButtonText,
        } = this.props;

        const {
            balance,
            error,
            expirationDate,
            inputValue,
            loading,
        } = this.state;

        let content;

        if (loading) {
            content = <Loading height={120} />;
        } else if (balance) {
            content = (
                <div className="gift-card-balance--result">
                    <div className="gift-card-balance--result--row">
                        <div className="gift-card-balance--result--label">
                            {currentBalanceLabel}
                        </div>
                        <div className="gift-card-balance--result--value gift-card-balance--result--value--balance">
                            {balance}
                        </div>
                    </div>

                    <div className="gift-card-balance--result--row">
                        <div className="gift-card-balance--result--label">
                            {cardNumberLabel}
                        </div>
                        <div className="gift-card-balance--result--value">
                            {inputValue}
                        </div>
                    </div>

                    <div className="gift-card-balance--result--row">
                        <div className="gift-card-balance--result--label">
                            {expirationDateLabel}
                        </div>
                        <div className="gift-card-balance--result--value">
                            {expirationDate}
                        </div>
                    </div>

                    <Button
                        onClick={this.reset}
                        className="gift-card-balance--result--button"
                    >
                        {tryAgainButtonText}
                    </Button>
                </div>
            );
        } else {
            content = (
                <form
                    onSubmit={this.onSubmit}
                    className="gift-card-balance--form"
                >
                    <p className="gift-card-balance--text">{formText}</p>

                    {error && (
                        <MessageBox
                            className="gift-card-balance--error"
                            type={'error'}
                            iconName={'cross'}
                        >
                            {error}
                        </MessageBox>
                    )}

                    <Input
                        className="gift-card-balance--input"
                        fieldErrorMessage={cardNumberInputErrorMessage}
                        id="card-number"
                        infoText={cardNumberInputInfoText}
                        label={cardNumberLabel}
                        name="card-number"
                        onChange={this.onInputChange}
                        ref={el => (this.input = el)}
                        required
                        validate={value =>
                            value.length === 16 && /^\d*$/.test(value)
                        }
                        value={inputValue}
                    />

                    <Button>{submitButtonText}</Button>
                </form>
            );
        }

        return (
            <div className="gift-card-balance">
                <Headline tag={2}>{headline}</Headline>

                {content}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.localization.locale,
    countryCode: state.localization.country.toUpperCase(),
    captchaKey: state.settings.captchaKey,
    nonce: state.settings.nonce,
});

export default withRouter(connect(mapStateToProps)(GiftCardBalance));
