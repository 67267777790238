import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Warning from '../warning';
import Column from '../column';
import Row from '../row';

import './style.styl';

class ErrorMessage extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        viewport: PropTypes.object,
        error: PropTypes.shape({
            status: PropTypes.number,
            errorMessage: PropTypes.string,
        }),
        buttonText: PropTypes.string,
    };

    componentDidMount() {
        if (process.browser && window.Sentry && this.props.error) {
            const requestId = this.props.error.requestId;
            const error = new Error(JSON.stringify(this.props.error, null, 4));
            //eslint-disable-next-line
            Sentry.withScope(function(scope) {
                if (requestId) {
                    scope.setTag('request_id', requestId);
                }
                Sentry.captureException(error); // eslint-disable-line
            });
        }
    }

    render() {
        const { error, title, viewport, buttonText } = this.props;
        const { localize } = this.context;

        let errorMessage;

        if (error) {
            if (error.errorMessage === 'not-found' || error.status === 404) {
                errorMessage = (
                    <Warning
                        fullError={true}
                        title={title}
                        message={localize('error-404') || 'Page Not found'}
                        error={error}
                    />
                );
            } else if (error.status === 500) {
                errorMessage = (
                    <Warning
                        fullError={true}
                        title={title}
                        message={
                            error.message ||
                            localize('error-500') ||
                            'Internal server error'
                        }
                        error={error}
                    />
                );
            } else if (error.status === 401) {
                errorMessage = (
                    <Warning
                        fullError={true}
                        title={title}
                        message={localize('error-401') || 'Not allowed'}
                        error={error}
                    />
                );
            } else {
                if (this.context.settings.showErrorsInFrontend) {
                    //TODO: this is not being set from docker-compose, confirm that's the right behaviour
                    errorMessage = (
                        <Warning
                            fullError={true}
                            title={title}
                            message={
                                error.errorMessage ||
                                localize('error-generic') ||
                                'Internal server error'
                            }
                            error={error}
                        />
                    );
                } else {
                    errorMessage = (
                        <Warning
                            fullError={true}
                            title={title}
                            type="error"
                            error={error}
                            message={
                                error.errorMessage ||
                                localize('error-generic') ||
                                'Internal server error'
                            }
                        />
                    );
                }
            }
        } else {
            errorMessage = (
                <Warning
                    fullError={true}
                    title={title}
                    type="error"
                    message={
                        localize('error-generic') || 'Internal server error'
                    }
                />
            );
        }

        if (buttonText) {
            // If button text prop, then add it to the props
            errorMessage = React.cloneElement(errorMessage, { buttonText });
        }

        return (
            <div className="error-message">
                <Row>
                    <Column width="1/1" viewport={viewport}>
                        <div className="error-message--inner">
                            {errorMessage}
                        </div>
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
});

ErrorMessage.propTypes = {
    error: PropTypes.object,
    viewport: PropTypes.object,
};

ErrorMessage.contextTypes = {
    settings: PropTypes.object,
    localize: PropTypes.func,
};

export default connect(mapStateToProps)(ErrorMessage);
