import clearingnummer from 'clearingnummer';

// Sweden only
export const getBankNameByClearingNumber = number => {
    number = number.toString();
    if (number.length > 4) {
        // Banks like Swedbank has a fifth number. The clearingnummer library needs the hypghen so
        // we do a search-replace with optional hyphen and convert it to the reqired format
        number = number.replace(/(\d{4})-?(\d{1})/, '$1-$2');
    }
    return clearingnummer.bankName(number || '');
};

export const getBankNameByIdentifier = (identifier, country) => {
    switch (country) {
        case 'sv-se':
            return getBankNameByClearingNumber(identifier);
        default:
            return 'Unknowmn';
    }
};
