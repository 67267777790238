/**
 * Finds the highest row number in a placement.
 * @param {array} placement The array of col/row configs.
 */
const findRowsNeeded = placement => {
    let rows = 1;
    placement.forEach(p => {
        if (p.row.e > rows) {
            rows = p.row.e;
        }
    });
};

const create = (converters = {}) => {
    /**
     * Convert the section layout (which is an array of objects with width information) into another layout and configuration style.
     * It's possible to place children in any order and in any position in a grid with dynamically adjusting rows.
     *
     * Incoming layout could be ->
     * [{width: '1/3'}, {width: '2/3}]
     *
     * And converted with 'identity' type would be for a 12 grid ->
     * [{col: {s: 1: e: 5}, row: {s: 1, e: 2}}, {col: {s: 5: e: 13}, row: {s: 1, e: 2}}]
     *
     * @param {Number} gridCols Number of columns, most likely 12
     * @param {String} type Type of convertion, 'mobile' for example
     * @param {Array} template Array of objects with 'width' information, for example {width: '1/3'}
     * @param {Array} children Array of columns.
     *
     * @returns {Object} converted - object with placement and children and templateRows is just 'auto' repeated number of rows times.
     *
     */
    return (gridCols, type, template, children) => {
        const converter = converters[type] || converters.identity;
        const converted = converter(gridCols, template, children);

        const rows = findRowsNeeded(converted.placement);
        const templateRows = 'auto '.repeat(rows);

        return {
            converted,
            templateRows
        };
    };
};

export default create;
