import { useState, useEffect } from 'react';
import cookies from 'react-cookies';

export default (
    cookieName,
    defaultState,
    saveDefaultState = true,
    options = {}
) => {
    const [shouldSave, setShouldSave] = useState(saveDefaultState);
    const [state, setState] = useState(() => {
        const cookieValue = cookies.load(cookieName);
        if (!cookieValue) {
            if (defaultState) {
                return defaultState;
            }
            return {};
        }
        return cookieValue.split(',').reduce((acc, curr) => {
            const [key, value] = curr.split(':');
            acc[key] = value;
            return acc;
        }, {});
    });

    function saveAsCookie() {
        const stateArray = Object.entries(state).map(
            ([key, value]) => `${key}:${value}`
        );
        // 1 is for enabled, 0 is for disabled.
        cookies.save(cookieName, stateArray.join(','), options);
        typeof options?.saveCallback === 'function' &&
            options.saveCallback();
    }

    useEffect(() => {
        if (state && Object.keys(state).length > 0 && shouldSave) {
            saveAsCookie();
        }
        setShouldSave(true);
    }, [state]);

    const setNewState = (val, shouldSave = true) => {
        setShouldSave(shouldSave);
        setState({ ...val });
    };

    return [state, setNewState];
};
