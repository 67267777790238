import Loadable from 'react-loadable';
import React from 'react'; // eslint-disable-line
import Loading from '../../components/loading';

const LoadableComponent = Loadable({
    loader: () => import(/* webpackChunkName = "group" */ './group'),
    loading: Loading,
    render(loaded, props) {
        let mergedProps = {
            ...props,
            ...loaded.default.parseProps(props),
        };
        let Component = loaded.default;
        return <Component {...mergedProps} />;
    },
});

LoadableComponent.parseProps = props => props;

export default LoadableComponent;
