import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import useOutsideClick from '../../../../hooks/use-outside-click';
import { Inner, StyledIcon } from './style';
import Wrapper from '../wrapper';

const SlideContentHeight = ({
    animation = {},
    children,
    closeIcon,
    closeOnOutsideClick = true,
    forceSlide = false,
    open,
    toggleOpen,
    innerStyle,
    outsideClickDependencies = [],
}) => {
    const isVisibleOnMount = useRef(open && !forceSlide);
    const containerRef = useRef(null);
    const innerRef = useRef(null);

    useOutsideClick({
        ref: innerRef,
        dependencies: [open, ...outsideClickDependencies],
        shouldExecute: closeOnOutsideClick,
        callback: e => {
            if (open && e.target && toggleOpen) {
                toggleOpen();
            }
        },
    });

    const visibleStyle = {
        height: 'auto',
        overflow: 'visible',
        zIndex: '10002',
        ...animation.to,
    };

    const hiddenStyle = {
        ...visibleStyle,
        height: 0,
        overflow: 'hidden',
        ...animation.from,
    };

    const getElementHeight = ref =>
        ref.current ? ref.current.getBoundingClientRect().height : 0;

    const transitions = useTransition(open, null, {
        enter: () => async (next, cancel) => {
            cancel();

            const height = getElementHeight(innerRef);

            await next({
                height,
                overflow: 'hidden',
            });
            await next(visibleStyle);
        },
        leave: () => async (next, cancel) => {
            cancel();

            const height = getElementHeight(containerRef);

            await next({ height });
            await next(hiddenStyle);

            isVisibleOnMount.current = false;
        },
        from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
        unique: true,
    });

    const close = closeIcon && open && (
        <StyledIcon name="close" width={20} height={20} {...closeIcon} />
    );

    return transitions.map(({ item: show, props: springProps, key }) => {
        if (show) {
            return (
                <animated.div ref={containerRef} key={key} style={springProps}>
                    <Inner innerStyle={innerStyle} ref={innerRef}>
                        {close}
                        {children}
                    </Inner>
                </animated.div>
            );
        }

        return null;
    });
};

SlideContentHeight.propTypes = {
    animation: PropTypes.shape({
        to: PropTypes.object,
        from: PropTypes.object,
    }),
    children: PropTypes.node,
    closeIcon: PropTypes.shape({
        onClick: PropTypes.func,
    }),
    closeOnOutsideClick: PropTypes.bool,
    forceAppear: PropTypes.bool,
    innerStyle: PropTypes.object,
    open: PropTypes.bool,
    overlay: PropTypes.oneOfType([
        PropTypes.shape({
            background: PropTypes.string.isRequired,
            alpha: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
    ]),
    toggleOpen: PropTypes.func,
    outsideClickDependencies: PropTypes.array,
};

export default props => (
    <Wrapper {...props}>
        <SlideContentHeight {...props} />
    </Wrapper>
);
