
import React from 'react';
import styles from '../../../../../variables/style';
import PropTypes from 'prop-types';

import ContentBlockModule from '../../../../content-block-module';


function renderBestMatchingDescription (item, highlight) {
    if (highlight && highlight.elasticContent) {
        return highlight.elasticContent;
    } else if (item && item.elasticContent) {
        return item.elasticContent;
    }

    return '';
}

function getTypographicSchemeForViewport (viewport) {
    if (viewport && viewport.width < styles.breakpoints.mobileMaxWidth) {
        return 'medium';
    }
    return 'small';
}

const Excerpt = ({ item, highlight, viewport }) => (
    <ContentBlockModule
        noPadding={true}
        typographicScheme={getTypographicSchemeForViewport(viewport)}>
        {`<p>${renderBestMatchingDescription(item, highlight)}</p>`}
    </ContentBlockModule>
);

Excerpt.propTypes = {
    item: PropTypes.object,
    highlight: PropTypes.object,
    viewport: PropTypes.object
};

export default Excerpt;
