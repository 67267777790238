import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './svg-icon';

const ArrowRightWithTail = ({ viewBox = '0 0 62.7 62.7', ...rest }) => (
    <SvgIcon viewBox={viewBox} {...rest}>
        <polygon
            points="60.4,33.8 62.9,31.2 62.9,31.2 62.9,31.2 60.4,28.8 59.5,27.8 50.4,27.8 54.8,23 44.9,13.2 40,18.2 49.6,27.8
            -0.2,27.8 -0.2,34.8 49.6,34.8 40,44.3 44.9,49.2"
        />
    </SvgIcon>
);

ArrowRightWithTail.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string,
};

export default ArrowRightWithTail;
