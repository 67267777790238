import produce from 'immer';

import {
    REQUEST_HEADER,
    RECEIVE_HEADER,
    HEADER_ERROR,
} from '../actions/header';

import setCommonReducerProperties from '../helpers/set-common-reducer-properties';

const initialState = {
    headerData: {
        menu: [],
        loginButton: {}
    },
    all: [],
    ...setCommonReducerProperties(),
};

function header(state = initialState, action) {
    return produce(state, draft => {
        let headerData;

        switch (action.type) {
            case REQUEST_HEADER:
                draft.loading = true;
                draft.error = false;
                draft.loaded = false;
                break;

            case RECEIVE_HEADER:
                headerData = action.payload;
                draft.headerData = headerData;
                draft.error = false;
                draft.loading = false;
                draft.loaded = true;
                break;

            case HEADER_ERROR:
                draft.error = 404;
                draft.loading = false;
                draft.loaded = true;
                break;
        }
    });
}

export default header;
