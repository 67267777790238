/**
 * @file             : services/frontend/src/www-src/scripts/components/column/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 03.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import styles from '../../variables/style';
import Link from '../link';

import './style.styl';
import './color-schemes.styl';

const gridSize = 12;

class Column extends React.Component {

    static vcConvertToProps(atts){

        let paddingBottom = parseInt(atts.padding_bottom, 10);
        let paddingTop = parseInt(atts.padding_top, 10);
        let contentPadding = parseInt(atts.content_padding, 10);

        let verticalOffsetTop = atts.vertical_offset_top ? atts.vertical_offset_top : 0;
        let verticalOffsetBottom = atts.vertical_offset_bottom ? atts.vertical_offset_bottom : 0;
        let newProps = {
            width: atts.width ? atts.width : '',
            mobile: atts.mobile,
            order: atts.order ? atts.order : '',
            stretchWidth: atts.stretchWidth ? atts.stretchWidth : false,
            equalHeight: atts.equalHeight ? atts.equalHeight : false,
            viewport: atts.viewport ? atts.viewport : {},
            columnIndex: atts.columnIndex ? atts.columnIndex : 0,
            columnLength: atts.columnLength ? atts.columnLength : 1,
            numberOfChildren: atts.numberOfChildren ? atts.numberOfChildren : 1,
            columnIsLink: atts.column_is_link !== '',
            link: atts.link_url || {},
            paddingBottom: !isNaN(paddingBottom) ? paddingBottom : 1,
            paddingTop: !isNaN(paddingTop) ? paddingTop : 1,
            contentPadding: !isNaN(contentPadding) ? contentPadding : 0,
            columnColorScheme: atts.column_color_scheme ? atts.column_color_scheme : '',
            alignment: atts.content_alignment ? atts.content_alignment : 'left',
            columnTextColor: atts.column_text_color ? atts.column_text_color : 'default',
            verticalOffsetTop: !isNaN(Number(verticalOffsetTop)) ? Number(verticalOffsetTop) : 0,
            verticalOffsetBottom: !isNaN(Number(verticalOffsetBottom)) ? Number(verticalOffsetBottom) : 0,
            columnOnTop: atts.column_on_top === 'true',
        };

        return newProps;

    }

    static defaultProps = {
        allColumnWidths: [],
    }

    static propTypes = {
        children: PropTypes.any,
        numberOfChildren: PropTypes.number,
        width: PropTypes.string,
        start: PropTypes.string,
        order: PropTypes.string,
        stretchWidth: PropTypes.bool,
        equalHeight: PropTypes.bool,
        viewport: PropTypes.object,
        mobile: PropTypes.string,
        minHeight: PropTypes.number,
        columnIsLink: PropTypes.bool,
        link: PropTypes.object,
        columnLength: PropTypes.number,
        columnIndex: PropTypes.number,
        className: PropTypes.string,
        allColumnWidths: PropTypes.array,
        paddingTop: PropTypes.number,
        paddingBottom: PropTypes.number,
        contentPadding: PropTypes.number,
        alignment: PropTypes.string,
        stretchRow: PropTypes.bool,
        bgImage: PropTypes.bool,
        imageOverlayType: PropTypes.string,
        columnTextColor: PropTypes.string,
        verticalOffsetTop: PropTypes.number,
        verticalOffsetBottom: PropTypes.number,
        columnColorScheme: PropTypes.string,
        columnOnTop: PropTypes.bool,
    }

    getColumnWidthClass (dividend, divisor, viewport) {

        let columnClass = `${dividend}-${divisor}`;

        if (!viewport) {
            return columnClass;
        }

        if (viewport.width < styles.breakpoints.mobileMaxWidth) { // Mobile
            let mobileConversionTable = {
                '1-2': '1-1',
                '1-3': '1-1',
                '1-4': '1-1',
                '3-4': '1-1',
                '2-3': '1-1',
                '1-6': '1-1',
                '5-6': '1-1',
                '3-2': '1-1'
            };
            return mobileConversionTable[columnClass] ? mobileConversionTable[columnClass] : columnClass;
        } else if (viewport.width > styles.breakpoints.mobileMaxWidth && viewport.width <= styles.breakpoints.tabletMaxWidth) { // Tablet


            let tabletConversionTable = {
                '1-2': '1-1',
                '1-3': '1-1',
                '5-12': '1-1',
                '2-6': '1-1',
                '1-4': '1-2',
                '3-4': '1-1',
                '2-3': '1-1',
                '4-6': '1-1',
                '1-6': '1-2',
            };

            return tabletConversionTable[columnClass] ? tabletConversionTable[columnClass] : columnClass;
        } else if (viewport.width > styles.breakpoints.tabletMaxWidth && viewport.width <= styles.breakpoints.desktopMaxWidth) {

            const smallDesktopConversionTable = {
                '1-4': '1-2',
                '3-4': '1-1',
            };

            return smallDesktopConversionTable[columnClass] ? smallDesktopConversionTable[columnClass] : columnClass;
        }



        return columnClass;
    }

    parseColumnWidth(width, viewport) {

        let [dividend, divisor] = width ? width.split('/') : ['1', '1'];

        divisor = divisor || 1;

        let columnWidth = this.getColumnWidthClass(dividend, divisor, viewport);

        return columnWidth;
    }

    render () {

        const {
            children,
            width,
            order,
            stretchWidth,
            viewport,
            equalHeight,
            minHeight,
            columnIsLink,
            columnIndex,
            columnLength,
            link,
            allColumnWidths,
            start,
            paddingTop,
            paddingBottom,
            alignment,
            stretchRow,
            bgImage,
            imageOverlayType,
            columnTextColor,
            verticalOffsetTop,
            verticalOffsetBottom,
            columnColorScheme,
            contentPadding,
            columnOnTop,
        } = this.props;

        let columnWidth = this.parseColumnWidth(width, viewport);

        const style = {};
        if (equalHeight) {
            style.minHeight = `${minHeight}px`;
        }

        // Calculate start of item for IE11, and break into next row since IE11 wont
        // continue automatically.
        // The index is based on all the previous positions
        let { position, isLast, rowIndex } = allColumnWidths
            .map(columnWidth => {
                return this.parseColumnWidth(columnWidth, viewport);
            })
            .reduce((positionData, columnWidth, index) => {

                const [dividend, divisor] = columnWidth ? columnWidth.split('-') : ['1', '1'];
                const currentPosition = gridSize / divisor * dividend;

                //console.log('currentPosition', currentPosition)
                const newIndex = positionData.index + currentPosition;
                const newPosition = newIndex % gridSize;
                const newRowIndex = Math.floor(newIndex / 12) + 1;
                if ((newPosition % 12) / 12 > 1 && index === columnIndex - 1) {
                    positionData.isLast = true;
                }
                if (index >= columnIndex) {
                    return positionData;
                }
                positionData.position = newPosition;
                positionData.index = newIndex;
                positionData.rowIndex = newRowIndex;

                return positionData;

            }, { position: 1, rowIndex: 1, index: 1, isLast: false, isFirst: false });

        // The first element shouldnt have a left margin, since gap doesnt work in IE
        const isFirst = position === 1;

        // Then we create a class for column and row starts, which are defined in the css file
        // to be used only if its IE11

        const msGridColumnClass = `column--ms-grid-column-start-${position}`;
        const msGridRowClass = `column--ms-grid-row-start-${rowIndex}`;

        let childrenWithProps = React.Children.map(this.props.children, (child) => {
            if (child) {
                // Override columnwidth if the specific component requires it
                if (child.type && child.type.columnSizes) {
                    columnWidth = child.type.columnSizes(width, viewport, columnWidth);
                }
                if (child.type && child.type.name !== 'Column') {
                    if (this.props.equalHeight) {
                        return React.cloneElement(child, {
                            minHeight: this.props.minHeight,
                            columnWidth,
                            alignment,
                            viewport,
                            columnIndex,
                            childIndex: child.key
                        });
                    }
                    if (typeof child.type === 'string') {
                        return child;
                    }
                    return React.cloneElement(child, {
                        columnWidth,
                        alignment,
                        viewport,
                        imageOverlayType,
                        bgImage,
                        columnIndex,
                        childIndex: child.key
                    });
                }
                return React.cloneElement(child, {
                    columnIndex: columnIndex,
                    columnLength: columnLength,
                    alignment: alignment,
                });
            }
        }) || [];

        let imageOverlayClass = '';
        if (imageOverlayType !== 'none') {
            imageOverlayClass = imageOverlayType === 'dark' ? 'column--overlay--dark' : 'column--overlay--light';
        }

        const columnClass = ClassNames(
            'column',
            {'column--on-top': columnOnTop},
            `column--alignment-${alignment}`,
            { [`column--text-color-${columnTextColor}`]: columnTextColor === 'default' ? null : true },
            { [`column--${columnWidth}`]: true },
            { ['column--stretch']: stretchWidth ? true && this.props.children : null },
            { [`column--order-${order}`]: order ? true : null },
            { ['column--row-stretch']: stretchRow ? true : null },
            { ['column--overlay']: bgImage ? true && this.props.children : null },
            { [`${imageOverlayClass}`]: bgImage && imageOverlayType ? true && this.props.children : null },
            // *** Start IE11 classes ***
            msGridColumnClass,
            msGridRowClass,
            { ['column--grid-column-stretch-first']: isFirst && stretchWidth && this.props.children },
            { ['column--grid-column-stretch-last']: isLast && stretchWidth && this.props.children },
            { ['column--grid-column-stretch-first-1-1']: isFirst && stretchWidth && this.props.children && columnWidth === '1-1' },
            { ['column--ms-grid-column-first']: isFirst },
            { ['column--ms-grid-column-last']: isLast },
            // **** End IE11 classes ****

            { [`column--grid-column-start-${start}`]: start ? start : false },

            { [`column--padding-top-${paddingTop || 0}`]: true },
            { [`column--padding-bottom-${paddingBottom || 0}`]: true },
            { [`column--padding-inner-${contentPadding}`]: true },

            { [`column--vertical-offset-top-${verticalOffsetTop || 0}`]: true },
            { [`column--vertical-offset-bottom-${verticalOffsetBottom || 0}`]: true },
            { ['column--has-vertical-offset-top']: verticalOffsetTop > 0 },
            { 'column--has-vertical-offset-bottom': verticalOffsetBottom > 0 },

            { [`column--colorscheme-${columnColorScheme}`]: columnColorScheme ? true : false },

            this.props.className,

        );

        if (columnIsLink && link.url) {
            let target = link.target === '' ? '_self' : link.target;
            return (
                <Link to={link.url} target={target} className={columnClass} style={style}>{ children }</Link>
            );
        }

        return (
            <div className={columnClass} style={style}>{ childrenWithProps }</div>
        );

    }
}

export default Column;
