import React from 'react';
import PropTypes from 'prop-types';

import './style.styl';

class Label extends React.Component {

    static propTypes = {
        children: PropTypes.any.isRequired,
        className: PropTypes.any,
        id: PropTypes.string,
        size: PropTypes.string
    }

    render () {

        let {className, children, id} = this.props;

        return (
            <label id={id} className={className}>{children}</label>
        );

    }

}

export default Label;

