/**
 * @file             : services/frontend/src/www-src/scripts/containers/search/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 24.10.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
require('./style.styl');

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import ClassNames from 'classnames';
import { search, reset, closeSearchModal } from '../../actions/search';
import Loading from '../../components/loading';
import Link from '../../components/link';
import SearchResultsList from '../../components/search-results-list';
import Button from '../../components/button-component';
import Icons from '../../components/icons';
import { closeMobileMenu } from '../../actions/header-menu-mobile';
import Headline from '../../components/headline';
import SearchInput from '../../components/search-input';

class Search extends React.Component {

    constructor (props) {
        super(props);
    }

    static propTypes = {
        open: PropTypes.bool,
        query: PropTypes.string,
        hits: PropTypes.array,
        total: PropTypes.number,
        page: PropTypes.number,
        numPages: PropTypes.number,
        loading: PropTypes.bool,
        error: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.object
        ]),
        match: PropTypes.object,
        dispatch: PropTypes.func,
        viewport: PropTypes.object,
        history: PropTypes.object,
        humany: PropTypes.array,
        mobile: PropTypes.any,
    }

    onSearch (query) {
        if (query && query.length >= 3) {
            let page = query === this.props.query ? this.props.page : 1;
            search(query, page)(this.props.dispatch, this.context.store.getState());
        }
    }

    onReset () {
        reset()(this.props.dispatch);
    }

    goToPage (page) {
        search(this.props.query, page)(this.props.dispatch, this.context.store.getState());
    }

    handleCloseSearchModal (goToUrl) {
        const isMobileMenu = this.props.viewport.width < 769;
        if (goToUrl) {
            this.props.history.push(goToUrl);
        }
        closeSearchModal()(this.props.dispatch);
        if (isMobileMenu) {
            closeMobileMenu()(this.props.dispatch);
        }
    }

    noQuery () {
        return (
            <div className="search--help"></div>
        );
    }

    getHumanyResult() {
        const { humany } = this.props;
        let innerContent = null;
        if (humany.error) {
            innerContent = (
                <div className="search--results--no-results">{this.context.localize('humany-faq-error')}</div>
            );
        } else if(humany.loading) {
            innerContent = <Loading />;
        } else {
            innerContent = (
                <SearchResultsList
                    hits={humany.list}
                    viewport={this.props.viewport}
                    onSearchResultSelected={this.handleCloseSearchModal.bind(this)}
                    className="search--results-list"
                    isHumanyList={true}
                />
            );
        }

        return (
            <div className="search--results--humany">
                <Headline className="search--results--humany--headline" tag={4}>{this.context.localize('faq')}</Headline>
                {innerContent}
                { this.props.open ? <Humany shouldRenderHtml={false} /> : null }
            </div>
        );

    }

    renderResults () {
        const { viewport, hits, query, page, numPages } = this.props;

        const results = query && query.length >= 2 ? (
            <div className="search--results">
                <div className="search--results--intro">
                    {this.context.localize('search-result-intro')}
                </div>
                <SearchResultsList
                    hits={hits}
                    viewport={viewport}
                    onSearchResultSelected={this.handleCloseSearchModal.bind(this)}
                    className="search--results-list" />
            </div>
        ) : this.noQuery();



        const pagination = numPages > 1 ? (
            <div className="search--results-pagination">
                {
                    Array(numPages).join().split(',').map((emptyRow, i) => {
                        let index = i + 1;
                        const linkClassNames = ClassNames(
                            'search--results-pagination-link',
                            {
                                'search--results-pagination-link--active' : index === page
                            }
                        );
                        return (
                            <Link
                                to={'#'}
                                onClick={this.goToPage.bind(this, index)}
                                key={`pagination-${index}`}
                                className={linkClassNames}
                            >
                                {index}
                            </Link>
                        );
                    })
                }
            </div>
        ) : null;

        return (
            <div>
                {results}
                {pagination}
            </div>
        );
    }

    renderHumanyResults () {
        const humanyResult = this.getHumanyResult();
        return humanyResult;
    }

    renderErrorOrLoading () {
        const { loading } = this.props;
        if (loading){
            return (<Loading />);
        }
        return (
            <div className="search--results--no-results">{this.context.localize('noResults')}</div>
        );
    }

    searchTop() {
        return (
            !this.props.mobile
                ? <div className="search--top">
                    <SearchInput
                        open={this.props.open}
                        className="search--input"
                        onSearch={this.onSearch.bind(this)}
                        // onClear={this.onSearchClear.bind(this)}
                        placeholder={this.context.localize('search')} />
                    <div className="search--close-wrapper">
                        <Button
                            className="search--close-wrapper--close-button"
                            type="secondary"
                            onClick={(e) => { e.preventDefault(); this.handleCloseSearchModal(); }}
                            useMargin={false}
                        >
                            <div className="search--close-wrapper--close-button--text">
                                {this.context.localize('close')}
                            </div>
                            <Icons name="closeCircle" height="21" width="21" className="my-icon" stroke="#fff"/>
                        </Button>
                    </div>
                </div>
                : null
        );
    }

    render () {
        const { open, error, loading } = this.props;

        let translationStyle = {};

        if (process.title === 'browser') {

            if ( !this.props.mobile ) {
                if (open) {
                    document.documentElement.style.overflow = 'hidden';
                } else {
                    document.documentElement.style.overflow = 'auto';
                }
            }

            if (this.context.featureSupport.cssVars) {
                const offset = open ? '0' : '-100vw';
                document.body.style.setProperty('--search-panel', offset);
            } else {
                const right = open ? '0px' : '1000px';
                translationStyle = {
                    right: right
                };
            }

        }

        if (!this.context.featureSupport.cssVars) {
            translationStyle.right = open ? '0px' : '-1000px';
        }

        const overlayClasses = ClassNames({
            'search--overlay': open,
            'search--overlay--hidden': !open
        });
        return (
            <div className="search">
                <div className={overlayClasses} onClick={() => closeSearchModal()(this.props.dispatch)}></div>
                <div className="search--content" style={translationStyle}>
                    {this.searchTop()}
                    <div className="search--results">
                        <div className="search--results--inner">
                            { error || loading ? this.renderErrorOrLoading() : this.renderResults()}
                        </div>
                    </div>
                    {/* <div className="search--content--humany">
                        { this.renderHumanyResults() }
                    </div> */}
                </div>
            </div>
        );
    }
}

Search.contextTypes = {
    store: PropTypes.object,
    localize: PropTypes.func,
    registerFetchAction: PropTypes.func,
    featureSupport: PropTypes.object
};

const mapStateToProps = (state) => {

    const result = {
        open: state.search.open,
        query: state.search.query,
        hits: state.search.hits,
        total: state.search.total,
        page: state.search.page,
        numPages: state.search.numPages,
        loading: state.search.loading,
        error: state.search.error,
        viewport: state.viewport,
        mobile: state.settings.mobile,
        humany: state.humany.faq,
    };

    return result;

};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch
    };
};

const SearchContainer = connect(mapStateToProps, mapDispatchToProps)(Search);

export default SearchContainer;
