import React, { useEffect, useRef, useState } from 'react';
import { StyledTableWrapper } from './style';
import PropTypes from 'prop-types';

const Table = ({ children }) => {
    const ref = useRef(null);
    const [hasFooters, setHasFooters] = useState(null);

    useEffect(() => {
        if (ref.current) {
            const tableHeaders = ref.current.querySelectorAll('thead th');
            const tableFooters = ref.current.querySelectorAll('tfoot td');
            const tableRows = ref.current.querySelectorAll('tbody tr');

            const headers = Array.from(tableHeaders).map(
                header => header.innerText
            );

            const footers = Array.from(tableFooters).map(
                footer => footer.innerText
            );

            setHasFooters(Boolean(footers.length));

            tableRows.forEach(tr => {
                Array.from(tr.children).forEach((td, index) => {
                    headers.length && td.setAttribute('label', headers[index]);
                    footers.length && td.setAttribute('footer', footers[index]);
                });
            });
        }
    }, [ref]);

    return (
        <StyledTableWrapper
            ref={ref}
            dangerouslySetInnerHTML={{ __html: children }}
            hasFooters={hasFooters}
        />
    );
};

Table.parseProps = ({ innerHTML }) => {
    return {
        children: innerHTML,
    };
};

Table.propTypes = {
    children: PropTypes.string,
};

export default Table;
