import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './style.styl';

class Loading extends React.Component {
    static propTypes = {
        fullHeight: PropTypes.bool,
        height: PropTypes.number,
        noInitialTransition: PropTypes.bool,
        small: PropTypes.bool,
        miniLoader: PropTypes.bool,
        color: PropTypes.string,
        className: PropTypes.string,
        background: PropTypes.bool,
    };

    render() {
        const {
            height,
            small,
            fullHeight,
            miniLoader,
            noInitialTransition,
            color,
            className,
            background,
        } = this.props;

        const classes = classNames('loading', {
            'loading--small': !height && small,
            'loading--full-height': fullHeight,
            'loading--with-background': background,
        }, className);

        const svgClasses = classNames({
            'loading--svg--initial-transition': !noInitialTransition,
        });

        const style = {
            ...(height && { height: `${height}px` }),
        };

        const miniLoaderStyle = {
            ...(color && {
                borderTopColor: color,
                borderBottomColor: color,
            }),
        };

        return miniLoader ? (
            <div className="loading--mini-loader-wrapper" style={style}>
                <div
                    className="loading--mini-loader"
                    style={miniLoaderStyle}
                ></div>
            </div>
        ) : (
            <div className={classes} style={style}>
                <svg className={svgClasses}>
                    <path
                        d="m 12.5,20 15,0 0,0 -15,0 z"
                        className="loadLine one"
                    />
                    <path
                        d="m 32.5,20 15,0 0,0 -15,0 z"
                        className="loadLine two"
                    />
                    <path
                        d="m 52.5,20 15,0 0,0 -15,0 z"
                        className="loadLine three"
                    />
                    <path
                        d="m 72.5,20 15,0 0,0 -15,0 z"
                        className="loadLine four"
                    />
                </svg>
            </div>
        );
    }
}

export default Loading;
