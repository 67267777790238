/**
 * @file             : services/frontend/src/www-src/scripts/actions/page.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 25.10.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import { clearTitle, setTitle, setMarket } from './info';
import {
    shouldFetch,
    fetchResource,
    parseHeaderData,
} from '../helpers/fetch-helper';

export const REQUEST_PAGE = 'REQUEST_PAGE';
export const RECEIVE_PAGE = 'RECEIVE_PAGE';
export const ACTIVATE_PAGE = 'ACTIVATE_PAGE';
export const PAGE_ERROR = 'PAGE_ERROR';

// Used to load a markettype if none is defined
export const DEFAULT_MARKET_TYPE = 'private';

/* We keep this since we should call it, but for some reason dont
function requestPage(slug) {
    return {
        type: REQUEST_PAGE,
        slug
    };
}
*/

function activatePage(slug) {
    return {
        type: ACTIVATE_PAGE,
        slug,
    };
}

function receivedPage(slug, data) {
    return {
        type: RECEIVE_PAGE,
        slug,
        payload: data,
    };
}

function responseError(slug, error) {
    return {
        type: PAGE_ERROR,
        slug,
        error,
    };
}

function dispatchMarketType(dispatch, page, fromReducer) {
    if (!page) {
        setMarket(DEFAULT_MARKET_TYPE)(dispatch); //set default if no page, for example 404
    }

    if (fromReducer) {
        if (!page.content.market_type) {
            if (page.content.parent_market_type) {
                setMarket(page.content.parent_market_type)(dispatch);
            } else {
                setMarket(DEFAULT_MARKET_TYPE)(dispatch);
            }
        } else {
            setMarket(page.content.market_type)(dispatch);
        }
    } else {
        if (!page.market_type) {
            if (page.parent_market_type) {
                setMarket(page.parent_market_type)(dispatch);
            } else {
                setMarket(DEFAULT_MARKET_TYPE)(dispatch);
            }
        } else {
            setMarket(page.market_type)(dispatch);
        }
    }
}

export function fetchPage(slug) {
    return async function(dispatch, state, requestOptions) {
        let page = state.pages.list[slug];

        // If we already have it in the store, we dont ned to fetch it again.
        // But if we want to make it really cool, we should use the servcieworker
        if (shouldFetch({ entity: page, slug })) {
            try {
                let url = `${state.settings.apiUrl}/page/${slug}`;

                const page = await fetchResource(
                    url,
                    parseHeaderData(state.requestData, requestOptions)
                );

                dispatch(receivedPage(slug, page));

                // Clear the title of the page, since we know that we are in the root of a program
                dispatch(clearTitle());

                // The the first part of the title to the project title
                dispatch(setTitle(page.post_title, 0));

                // Get market type and dispatch it as payload in setMarket action
                dispatchMarketType(dispatch, page, false);

                return page;

            } catch (err) {
                if (err.status === 404 || err.status === 401) {
                    dispatch(receivedPage(slug, err));
                } else {
                    dispatch(responseError(slug, err));
                }
                dispatchMarketType(dispatch, false, false); //set market type to default for menu to work on error pages
            }

            // If its already active and we have it, we just resolve
        } else if (state.pages.current === slug) {
            return Promise.resolve();
        } else if (!page.loading) {
            let page = state.pages.list[slug];

            // Clear the title of the page, since we know that we are in the root of a program
            dispatch(clearTitle());

            // The the first part of the title to the project title
            dispatch(setTitle(page.content.post_title, 0));

            // Just activate the page, we knoe the contest is already loaded
            dispatch(activatePage(slug));

            // Get market type and dispatch it as payload in setMarket action
            return dispatchMarketType(dispatch, page, true);
        }
    };
}
