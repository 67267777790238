/**
 * @file             : services/frontend/src/www-src/scripts/reducers/header-menu.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 03.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import produce from 'immer';
import setCommonReducerProperties from '../helpers/set-common-reducer-properties';

import {
    REQUEST_HEADERMENU,
    RECEIVE_HEADERMENU,
    HEADERMENU_NOT_FOUND,
    ACTIVATE_HEADERMENU,
    SUBMENU_OPEN,
} from '../actions/header-menu';

const initialState = {
    list: {},
    current: null,
    subMenuOpen: 0,
    ...setCommonReducerProperties(),
};

function headerMenu(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case REQUEST_HEADERMENU:
                draft.current = action.menuName;
                draft.list[action.menuName] = draft.list[action.menuName] || {
                    slug: action.menuName,
                    // includes required properties loaded, loading and error.
                    ...setCommonReducerProperties({
                        loading: true,
                    }),
                };
                break;

            case RECEIVE_HEADERMENU: {
                const menu = action.payload.menu.map(item => {
                    return {
                        ...item,
                        url: item.url,
                    };
                });

                // we separate the menu items as some top level header menu items may be placed
                // in the topmenu.
                const menuItems = {
                    ...(action.payload.menu_bar && {
                        menuBar: action.payload.menu_bar,
                    }),
                    allItems: menu,
                    topMenuItems: menu.filter(
                        item => item['place_in_top_menu']
                    ),
                    firstLevelMenuItems: menu.filter(
                        item => !item['place_in_top_menu']
                    ),
                };

                draft.current = action.menuName;
                draft.list[action.menuName] = {
                    items: menuItems,
                    slug: action.menuName,
                    menuBarHamburgerLabel:
                        action.payload.menu_bar_hamburger_label,
                    loading: false,
                    loaded: true,
                    error: false,
                };
                break;
            }

            case HEADERMENU_NOT_FOUND:
                draft.error = 404;
                draft.loading = false;
                draft.loaded = true;
                break;

            case ACTIVATE_HEADERMENU:
                draft.current = action.menuName;
                draft.list[action.menuName] = {
                    ...draft.list[action.menuName],
                    loading: false,
                };
                break;

            case SUBMENU_OPEN:
                draft.subMenuOpen = action.status;
                break;
        }
    });
}

export default headerMenu;
