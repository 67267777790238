import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './svg-icon';

const NewWindow = ({ viewBox = '0 0 24 24', ...rest }) => (
    <SvgIcon viewBox={viewBox} {...rest}>
        <g
            id="ic/newwindow"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <path
                d="M8.87965091,1 L8.87965091,3.57001161 L3.56487126,3.55833472 L3.56487126,20.4172168 L20.4351287,20.4420302 L20.4351287,15.0578869 L23,15.0578869 L23,23 L1,22.9675236 L1,1 L8.87965091,1 Z M13.6473802,1 L18.7991073,1.01131199 L18.881916,3.29487477 L21.1672163,1.01897495 L21.1694147,1.01678554 L21.6900836,1.01788025 L22.9791146,1.01496102 L22.9791146,1.02079947 L22.9849772,1.02079947 L22.9820459,2.30452811 L23,10.3141483 L20.4351287,10.3199867 L20.4241364,5.37153757 L10.9744179,14.7823851 L9.16068752,12.9761154 L18.610406,3.56526787 L13.6415176,3.55432078 L13.6473802,1 Z"
                id="newwindow"
                fill="#000000"
            ></path>
        </g>
    </SvgIcon>
);

NewWindow.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string,
};

export default NewWindow;
