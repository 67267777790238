require('./style.styl');

import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';

const Logo = ({ className }) => {
    let src ='/assets/logos/ya-logo-new.png';

    return (
        <img
            className={className}
            src={src}
            title="yA Resurs Bank"
            alt="yA Resurs Bank"
        />
    );
};

Logo.propTypes = {
    className: PropTypes.string,
};

export default Logo;
