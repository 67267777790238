const BUNDLE_SRC = 'https://browser.sentry-cdn.com/5.12.1/bundle.min.js';
const BUNDLE_INTEGRITY =
    'sha384-y+an4eARFKvjzOivf/Z7JtMJhaN6b+lLQ5oFbBbUwZNNVir39cYtkjW1r6Xjbxg3';

const init = (dsn, project = 'resurs-dev', version, extra = {}) => {
    if (!dsn) {
        console.warn('Sentry is disabled since dsn is missing.'); // eslint-disable-line
        return;
    }

    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line
        console.warn(
            'Sentry has been disabled since the project is running in development mode.'
        );
        return;
    }

    const errors = [];

    const addError = error => {
        errors.push(error);
    };

    // Listen for errors before Sentry has been loaded
    window.addEventListener('error', addError);

    let retries = 0;

    const checkSentry = () => {
        setTimeout(() => {
            if (!window.Sentry) {
                // No Sentry found. Retry.
                if (retries >= 10) {
                    // If it somehow got bigger than 10 or is equal to 10 we should stop trying
                    console.warn('Failed to load Sentry.'); //eslint-disable-line
                    return;
                }
                retries++;
                return checkSentry(); // Try again
            }
            // Sentry exists! Remove our own error event handler
            window.removeEventListener('error', addError);
            if (errors.length > 0) {
                // If there happened to be some errors before Sentry
                // was loaded we loop through them and send them to Sentry.
                errors.forEach(window.Sentry.captureException);
            }
        }, 1000);
    };

    checkSentry();

    requestAnimationFrame(() => {
        const script = document.createElement('script');
        script.async = true;
        script.crossOrigin = 'anonymous';
        script.integrity = BUNDLE_INTEGRITY;
        script.src = BUNDLE_SRC;
        script.addEventListener('load', () => {
            // eslint-disable-next-line
            Sentry.init({
                dsn,
                release: `${project}@${version}`,
            });
            Object.entries(extra).forEach(([key, value]) => {
                Sentry.setExtra(key, value); // eslint-disable-line
            });
        });
        document.head.appendChild(script);
    });
};

export default init;
