import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './svg-icon';

const ArrowLeftWithTail = ({ viewBox = '0 0 62.7 62.7', ...rest }) => {
    return (
        <SvgIcon viewBox={viewBox} {...rest}>
            <polygon points="2.3,28.6 -0.2,31.2 -0.2,31.2 -0.2,31.2 2.3,33.6 3.2,34.6 12.3,34.6 7.9,39.4 17.8,49.2 22.7,44.2 13.1,34.6 62.9,34.6 62.9,27.6 13.1,27.6 22.7,18.1 17.8,13.2 " />
        </SvgIcon>
    );
};

ArrowLeftWithTail.propTypes = {
    viewBox: PropTypes.string,
};

export default ArrowLeftWithTail;
