import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';

export const StyledToggleSection = styled.div`
    padding: ${theme.spacing.base} 0;
    border-bottom: 1px solid ${theme.colors.lightGrey};
`;

export const StyledTitleRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.small};
`;

export const StyledMandatoryTextHolder = styled.div`
    color: ${theme.colors.blue};
    span {
        margin-right: 1rem;
    }
`;
