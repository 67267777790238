/**
 * @file             : services/frontend/src/www-src/scripts/containers/header-menu/mobile/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 14.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */

import './style.styl';

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { fetchHeaderMenu, fetchClusterMenu } from '../../../actions/header-menu';
import { setAnchorPointOffset } from '../../../actions/anchorpoint';
import Link from '../../../components/link';
import Loading from '../../../components/loading';
import Logo from '../../../components/logo';
import Hamburger from '../../../components/hamburgers';
import Icons from '../../../components/icons';
import Alerts from '../../alerts';
import SearchInput from '../../../components/search-input';
import ClassNames from 'classnames';
import {
    closeSearchModal,
    openSearchModal,
    search,
} from '../../../actions/search';
import {
    closeMobileMenu,
    openMobileMenu,
    setActiveMenuItems,
    openMenuItem,
    closeMenuItem,
} from '../../../actions/header-menu-mobile';
import style from '../../../variables/style';

class MobileMenu extends React.Component {
    static propTypes = {
        activeMenuItems: PropTypes.object,
        currentPage: PropTypes.string,
        currentPageRoot: PropTypes.string,
        dispatch: PropTypes.func,
        history: PropTypes.object,
        info: PropTypes.object,
        location: PropTypes.object,
        loginButton: PropTypes.object,
        marketMenu: PropTypes.array,
        marketType: PropTypes.string,
        menu: PropTypes.object,
        mobileMenu: PropTypes.array,
        mobileMenuLoading: PropTypes.bool,
        mobileMenuNotFound: PropTypes.bool,
        mobileMenuOpen: PropTypes.bool,
        onClick: PropTypes.func,
        open: PropTypes.bool,
        openMenuItems: PropTypes.object,
        page: PropTypes.number,
        query: PropTypes.string,
        registerFetchAction: PropTypes.func,
        setActiveMenuItemStatus: PropTypes.func,
        toggleMenuItemOpenStatus: PropTypes.func,
        user: PropTypes.object,
        filterVisibleMenuItems: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    getMenuSlugByMarketType(marketType) {
        const { currentPageRoot } = this.props;
        // For cluster pages, use same menu for both desktop and mobile
        return marketType === 'cluster' ? currentPageRoot : `mobile_menu_${marketType}`;
    }

    componentWillMount() {
        let { marketType } = this.props;
        if (marketType) {
            const slug = this.getMenuSlugByMarketType(marketType);
            this.context.registerFetchAction(
                marketType === 'cluster'
                    ? fetchClusterMenu(slug)
                    : fetchHeaderMenu(slug),
                'fetch-header-menu-mobile'
            );
        }

        // init active menu items
        const activeItems = this.initActiveMenuItems(this.props);
        this.props.setActiveMenuItemStatus(activeItems);
        // also set open status as same as active items on init
        this.props.toggleMenuItemOpenStatus(activeItems);
    }

    componentWillReceiveProps(newProps) {
        if (newProps.marketType !== this.props.marketType) {
            const slug = this.getMenuSlugByMarketType(newProps.marketType);
            this.context.registerFetchAction(
                newProps.marketType === 'cluster'
                    ? fetchClusterMenu(slug)
                    : fetchHeaderMenu(slug),
                'fetch-header-menu-mobile'
            );
        }
        if (newProps.menu && newProps.currentPage != this.props.currentPage) {
            const activeItems = this.initActiveMenuItems(newProps);
            newProps.setActiveMenuItemStatus(activeItems);
            // also set open status as same as active items on init
            this.props.toggleMenuItemOpenStatus(activeItems);
        }
    }

    componentDidMount() {
        const offset = -(style.mobileMenu.paddingTop);
        setAnchorPointOffset(offset)(this.props.dispatch);
    }

    componentDidUpdate(prevProps) {
        if (this.props.mobileMenuOpen !== prevProps.mobileMenuOpen) {
            if (!this.props.mobileMenuOpen) {
                document.documentElement.style.overflow = 'auto';
                document.body.style.overflow = 'auto';
                document.body.style.height = 'auto';
            } else {
                document.documentElement.style.overflow = 'hidden';
                document.body.style.overflow = 'hidden';
                document.body.style.height = '100%';
            }
        }
    }

    initActiveMenuItems(props) {
        if (props.menu) {
            let menuItems = props.menu.items ? props.menu.items.allItems : [];
            let currentPageUrl = '/' + props.currentPage + '/';

            if (props.marketType === 'cluster') {
                // Handle cluster pages with simpler 1-level navigation
                let selectedMenu = menuItems.find(
                    item => item.url && item.url === currentPageUrl
                );

                return {
                    selectedFirstLevelMenuId: selectedMenu ? selectedMenu.ID : null,
                };
            }

            // Find the first menu and/or sub-menus respective items that matches the active
            // page URL and store their IDs as selected.
            const selectedItemIds = this.getSelectedItemIds(
                menuItems,
                currentPageUrl
            );

            return selectedItemIds;
        } else {
            return {};
        }
    }

    getSelectedItemIds(menuItems, currentPageUrl, menuLevel = 0) {
        const levelStringCapitalized = this.getLevelString(menuLevel, true);

        let selectedMenuItemIds = {};

        const selectedMenuItem = this.getSelectedItem(
            menuItems,
            currentPageUrl
        );
        if (selectedMenuItem) {
            selectedMenuItemIds = {
                ...selectedMenuItemIds,
                ...{
                    [`selected${levelStringCapitalized}LevelMenuId`]: selectedMenuItem.ID,
                },
            };
            if (selectedMenuItem.children) {
                const subSelectedMenuItemId = this.getSelectedItemIds(
                    selectedMenuItem.children,
                    currentPageUrl,
                    menuLevel + 1
                );
                if (subSelectedMenuItemId) {
                    selectedMenuItemIds = {
                        ...selectedMenuItemIds,
                        ...subSelectedMenuItemId,
                    };
                }
            }
        } else {
            selectedMenuItemIds = {
                ...selectedMenuItemIds,
                ...{ [`selected${levelStringCapitalized}LevelMenuId`]: null },
            };
        }

        return selectedMenuItemIds;
    }

    getSelectedItem(menuItems, currentPageUrl) {
        const selectedItem = menuItems.reduce((selectedItem, menuData) => {
            if (
                menuData &&
                this.isPageInCurrentUrl(menuData.url, currentPageUrl)
            ) {
                selectedItem = menuData;
            }
            return selectedItem;
        }, null);

        return selectedItem;
    }

    isPageInCurrentUrl(url, currentPageUrl) {
        if (url === currentPageUrl) {
            return true;
        } else if (currentPageUrl.includes(url)) {
            return true;
        } else {
            return false;
        }
    }

    toggleMenu() {
        let open = !this.props.mobileMenuOpen;
        if (!open) {
            this.closeMenu();
        } else {
            window.scrollTo(0, 0);
            openMobileMenu()(this.props.dispatch);
        }
    }

    closeMenu() {
        closeSearchModal()(this.props.dispatch);
        closeMobileMenu()(this.props.dispatch);
    }

    getToggleIcon(open, children, id, level) {
        if (!children) {
            return <div />;
        }

        let icon = open ? (
            <Icons color="white" name="minus" />
        ) : (
            <Icons color="white" name="plus" />
        );

        const key = this.getLevelKeyString(level);
        const activeMenuItem = {
            [key]: id,
        };

        let element = (
            <div
                className="mobile-menu--list-item--toggle-icon"
                onClick={this.props.toggleMenuItemOpenStatus.bind(
                    this,
                    activeMenuItem,
                    key,
                    open ? 'close' : 'open'
                )}
            >
                {icon}
            </div>
        );

        return element;
    }

    onSearch(query) {
        if (query && query.length >= 3) {
            let page = query === this.props.query ? this.props.page : 1;
            search(query, page)(
                this.props.dispatch,
                this.context.store.getState()
            );
            if (!this.props.open) {
                openSearchModal()(this.props.dispatch);
            }
        }
    }

    onSearchClear() {
        closeSearchModal()(this.props.dispatch);
    }

    getLevelString(level, capitalized = false) {
        const numberToMenuLevel = [
            'first',
            'second',
            'third',
            'fourth',
            'fifth',
        ];
        return capitalized
            ? `${numberToMenuLevel[level]
                .slice(0, 1)
                .toUpperCase()}${numberToMenuLevel[level].slice(1)}`
            : numberToMenuLevel[level];
    }

    getLevelKeyString(level) {
        return `selected${this.getLevelString(level, true)}LevelMenuId`;
    }

    getSubMenusHtml(subMenuItems, level = 1) {
        const levelString = this.getLevelString(level);

        const itemsHtml = subMenuItems.map(item => {
            const active =
                this.props.activeMenuItems[this.getLevelKeyString(level)] ===
                item.ID;
            const open =
                this.props.openMenuItems[this.getLevelKeyString(level)] ===
                item.ID;
            const notifications = this.getNotificationsForMenuItem(item);

            const menuItemClasses = classNames({
                ['mobile-menu--sub-list--item']: true,
                ['mobile-menu--sub-list--item--active--with-children']:
                    active && item.children,
                [`mobile--${levelString}-level-menu--list--item`]: true,
                [`mobile--${levelString}-level-menu--list--item--active`]: active,
            });
            let subChildren = null;
            if (item.children && open) {
                subChildren = this.getSubMenusHtml(item.children, level + 1);
            }

            const linkClasses = classNames({
                ['mobile-menu--sub-list--item--link']: true,
                ['mobile-menu--sub-list--item--link--active']: active,
            });
            return (
                <li key={item.ID} className={menuItemClasses}>
                    <div className="mobile-menu--sub-list--top-of-link--item">
                        <Link
                            className={linkClasses}
                            to={item.url}
                            look="light"
                            onClick={this.closeMenu.bind(this)}
                        >
                            {item.title}
                            {!open && notifications > 0 ? (
                                <div className="mobile-menu--messages-badge">
                                    {notifications}
                                </div>
                            ) : null}
                        </Link>
                        <span>
                            {this.getToggleIcon(
                                open,
                                item.children,
                                item.ID,
                                level
                            )}
                        </span>
                    </div>
                    {subChildren}
                </li>
            );
        });

        return (
            <div className={`mobile--${levelString}-level-menu`}>
                <ul
                    className={`mobile-menu--sub-list mobile--${levelString}-level-menu--list`}
                >
                    {itemsHtml}
                </ul>
            </div>
        );
    }

    getTopLevelMenuHtml({ items, loading }) {
        const { marketType } = this.props;

        let topLevelMenuItems = items
            ? items.allItems.map(menuItem => {
                const notifications = this.getNotificationsForMenuItem(
                    menuItem
                );

                const open = 
                    this.props.openMenuItems[this.getLevelKeyString(0)] === menuItem.ID;

                const menuItemClasses = classNames({
                    'mobile-menu--list-item': true,
                    'mobile-menu--list-item--cluster': marketType === 'cluster',
                    'mobile-menu--list-item--openable': marketType !== 'cluster',
                    'mobile-menu--list-item--open': open,
                });

                const { selectedFirstLevelMenuId } = this.props.activeMenuItems;
                const linkClasses = classNames({
                    'mobile-menu--list-item--link-active': 
                        marketType === 'cluster' && selectedFirstLevelMenuId === menuItem.ID,
                });

                let subMenusHtml = null;
                if (menuItem.children && open) {
                    subMenusHtml = this.getSubMenusHtml(menuItem.children);
                }

                return (
                    <li key={menuItem.ID} className={menuItemClasses}>
                        <div className="mobile-menu--list--top-of-link--item">
                            <Link
                                to={menuItem.url}
                                className={linkClasses}
                                look="light"
                                onClick={this.closeMenu.bind(this)}
                            >
                                {menuItem.title}
                                {!open && notifications > 0 ? (
                                    <div className="mobile-menu--messages-badge">
                                        {notifications}
                                    </div>
                                ) : null}
                            </Link>
                            <span>
                                {this.getToggleIcon(
                                    open,
                                    menuItem.children,
                                    menuItem.ID,
                                    0
                                )}
                            </span>
                        </div>
                        {subMenusHtml}
                    </li>
                );
            })
            : [];

        if (loading) {
            topLevelMenuItems = [<div>Loading...</div>];
        }

        return <ul className="mobile-menu--list">{topLevelMenuItems}</ul>;
    }

    getMarketMenuItems() {
        const { marketMenu, info } = this.props;

        const marketItems = marketMenu
            ? marketMenu.map(menu => {
                let isActive = info.marketType === menu.market_type;
                const liClassNames = ClassNames(
                    'mobile-menu--markets-list--item',
                    { 'mobile-menu--markets-list-item--active': isActive }
                );
                const linkClassNames = ClassNames(
                    'mobile-menu--markets-list-item--link',
                    {
                        'mobile-menu--markets-list-item--link--active': isActive,
                    }
                );
                return (
                    <li key={menu.ID} className={liClassNames}>
                        <Link
                            onClick={this.closeMenu.bind(this)}
                            to={menu.url}
                            className={linkClassNames}
                            look={false}
                        >
                            {menu.title}
                        </Link>
                    </li>
                );
            })
            : null;

        return marketItems;
    }

    getNotificationsForMenuItem(menuItem) {
        function recurse(menuItem) {
            if (
                menuItem.badge_type &&
                !notifications.includes(menuItem.badge_type)
            ) {
                notifications.push(menuItem.badge_type);
            }

            if (Array.isArray(menuItem.children)) {
                menuItem.children.forEach(recurse);
            }
        }

        const notifications = [];

        recurse(menuItem);

        return notifications.reduce((result) => {
            return result;
        }, 0);
    }

    handleLoginButtonClick = () => {
        this.closeMenu();
    }

    render() {
        const { menu, mobileMenuOpen, marketType, currentPage } = this.props;

        if (!menu || menu.loading) {
            return <Loading small={true} />;
        }

        if (process.title === 'browser') {
            if (mobileMenuOpen) {
                document.documentElement.style.overflow = 'hidden';
            }
        }

        const marketItems = this.getMarketMenuItems();
        const topLevelMenuHtml = this.getTopLevelMenuHtml(menu);

        // If a user is logged in, do not show the market items or search area in mobile menu.
        const searchAndMarketItems = this.props.user.isAuthenticated ? null : (
            <React.Fragment>
                <div className="mobile--search">
                    <SearchInput
                        className="mobile--search--input"
                        onSearch={this.onSearch.bind(this)}
                        onClear={this.onSearchClear.bind(this)}
                        placeholder={this.context.localize(
                            'search-placeholder'
                        )}
                    />
                </div>
                <ul className="mobile-menu--market-list">
                    {marketItems}
                </ul>
            </React.Fragment>
        );

        let userButton;
        userButton = (
            <Link
                look="dark"
                to={this.props.loginButton.url || '#'}
                className="mobile-menu--group mobile-menu--log-in"
                onClick={this.handleLoginButtonClick}
                gtmLabel = 'ya login click'
                gtmValue = ''
                gtmCategory = 'MyPages'
            >
                <Icons name="avatar" color="black" height={24} width={24} />
                <p className="mobile-menu--group--text mobile-menu--log-in--text">
                    {this.context.localize('log-in')
                        ? this.context.localize('log-in')
                        : 'Log in'}
                </p>
            </Link>
        );

        let startUrl = '/';
        if (marketType === 'cluster' && currentPage) {
            // For cluster pages the logotype should point to the topmost page in that cluster
            startUrl = `/${currentPage.split('/')[0]}/`;
        }

        return (
            <div className="mobile-menu header-menu">
                <Alerts ticker fixed/>
                <div className="mobile-menu--top-bar">
                    <div className="mobile-menu--logo-container">
                        <Link
                            look="light"
                            to={startUrl}
                            className="mobile-menu--logo-link"
                            onClick={this.closeMenu.bind(this)}
                        >
                            <Logo className="mobile-menu--logo" />
                        </Link>
                    </div>

                    <div className="mobile-menu--groups">
                        {marketType !== 'cluster' ? userButton : null}

                        <div
                            className="mobile-menu--group mobile-menu--toggle-button"
                            onClick={this.toggleMenu.bind(this)}
                        >
                            <Hamburger active={mobileMenuOpen} type="slider" />
                            <p className="mobile-menu--group--text mobile-menu--toggle-button--text">
                                {mobileMenuOpen
                                    ? this.context.localize('close')
                                    : this.context.localize('menu')}
                            </p>
                        </div>
                    </div>
                </div>

                <CSSTransition
                    in={mobileMenuOpen}
                    timeout={200}
                    classNames="mobile-menu--wrapper-"
                >
                    <div className="mobile-menu--wrapper">
                        <div className="mobile-menu--wrapper-inner">
                            {searchAndMarketItems}
                            {topLevelMenuHtml}
                        </div>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

MobileMenu.contextTypes = {
    store: PropTypes.object,
    registerFetchAction: PropTypes.func,
    localize: PropTypes.func,
    featureIsEnabled: PropTypes.func,
};

const mapStateToProps = (state, props) => {
    const menu = state.headerMenu.list[state.headerMenu.current];

    if (menu && menu.items) {
        // firstLevelMenuItems contains items that doesn't have "Place in top menu" checked in WP
        // allItems contains firstLevelMenuItems and "Place in top menu"
        // topMenuItems contains items that have "Place in top menu" checked in WP
        menu.items.firstLevelMenuItems = props.filterVisibleMenuItems(
            menu.items.firstLevelMenuItems
        );
        menu.items.allItems = props.filterVisibleMenuItems(menu.items.allItems);
        menu.items.topMenuItems = props.filterVisibleMenuItems(
            menu.items.topMenuItems
        );
    }

    return {
        menu: menu,
        marketType: state.info.marketType,
        info: state.info,
        query: state.search.query,
        page: state.search.page,
        open: state.search.open,
        mobileMenuOpen: state.headerMenuMobile.mobileMenuOpen,
        currentPage: state.pages.current,
        currentPageRoot: state.pages.current.replace(/\/.*$/g, ''),
        activeMenuItems: state.headerMenuMobile.activeMenuItems,
        openMenuItems: state.headerMenuMobile.openMenuItems,
        loginUrl: state.settings.loginUrl,
        user: state.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
        setActiveMenuItemStatus: activeMenuItems => {
            dispatch(setActiveMenuItems(activeMenuItems));
        },
        toggleMenuItemOpenStatus: (levelAndIdPair, key, status) => {
            if (status === 'close') {
                dispatch(closeMenuItem(key));
            } else {
                dispatch(openMenuItem(levelAndIdPair));
            }
        },
    };
};

const MobileMenuContainer = withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MobileMenu)
);

export default MobileMenuContainer;
