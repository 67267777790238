import produce from 'immer';
import theme from '../emotion/theme';

import { VIEWPORT_UPDATE_SIZE } from '../actions/viewport';

const initialState = {
    initiated: false,
    width: 0,
    height: 0,
    isMobileDevice: false,
};

function viewport(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case VIEWPORT_UPDATE_SIZE:
                if (action) {
                    draft.width = action.width;
                    draft.height = action.height;
                    draft.initiated = action.initiated;
                    draft.headerMenuHeight = action.headerMenuHeight;
                    draft.isMobileDevice =
                        action.width <= theme.breakpoints.tablet;
                }
                break;
        }
    });
}

export default viewport;
