import produce from 'immer';
import setCommonReducerProperties from '../helpers/set-common-reducer-properties';

import {
    REQUEST_PAGE,
    RECEIVE_PAGE,
    ACTIVATE_PAGE,
    PAGE_ERROR,
} from '../actions/page';

import { LOG_OUT } from '../actions/user';

const initialState = {
    list: {},
    current: '',
};

function pages(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case REQUEST_PAGE:
                draft.current = action.slug;
                draft.list[action.slug] = draft.list[action.slug] || {
                    slug: action.slug,
                    // includes required properties loaded, loading and error.
                    ...setCommonReducerProperties({
                        loading: true,
                    }),
                };
                break;

            case RECEIVE_PAGE:
                draft.current = action.slug;
                draft.isBankPage = action.payload.bank_page || false;
                draft.list[action.slug] = {
                    content: action.payload,
                    slug: action.slug,
                    loading: false,
                    loaded: true,
                    error: false,
                };
                break;

            case ACTIVATE_PAGE:
                draft.current = action.slug;
                draft.list[action.slug].error = false;
                draft.list[action.slug].loading = false;
                break;

            case PAGE_ERROR:
                draft.current = action.slug;
                draft.list[action.slug] = {
                    error: action.error,
                    loading: false,
                    loaded: false,
                    message: action.message,
                    status: action.status,
                    slug: action.slug,
                };
                break;

            case LOG_OUT: {
                if (action.payload) {
                    const regex = new RegExp(`^${action.payload}`);
                    // Remove all cached bank page content
                    const bankPages = Object.keys(draft.list).filter(page =>
                        regex.test(page)
                    );

                    bankPages.forEach(page => {
                        delete draft.list[page];
                    });
                }
                break;
            }
        }
    });
}

export default pages;
