import Loadable from 'react-loadable';
import React from 'react'; // eslint-disable-line
import Loading from '../loading';

const LoadableComponent = Loadable({
    loader: () => import(/* webpackChunkName = "steps-boxes" */ './steps-boxes.container'),
    loading: Loading,
    render(loaded, props) {
        let mergedProps = {
            ...props,
            ...loaded.default.parseProps(props),
        };
        let Component = loaded.default;
        return <Component {...mergedProps}/>;
    }
});

export default LoadableComponent;
