import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const Portal = ({ children, domNode }) => createPortal(children, domNode);

Portal.propType = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    domNode: PropTypes.node.isRequired,
};

export default Portal;
