/**
 * @file             : services/frontend/src/www-src/scripts/components/search-input/index.jsx
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 24.10.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../icons';

import './style.styl';

class SearchInput extends React.Component {
    static propTypes = {
        initialQuery: PropTypes.string,
        className: PropTypes.string,
        onSearch: PropTypes.func,
        onReset: PropTypes.func,
        onClear: PropTypes.func,
        open: PropTypes.bool,
        placeholder: PropTypes.string,
    };

    static defaultProps = {
        initialQuery: '',
        onSearch: () => {},
        onReset: () => {},
        onClear: () => {},
        open: false,
        placeholder: ''
    };

    static contextTypes = {
        localize: PropTypes.func
    };

    constructor (props) {
        super(props);

        this.inputRef = React.createRef();
        this.searchTimeout = null;
        this.state = {
            query: this.props.initialQuery,
        };
    }

    componentDidUpdate () {
        if (this.props.open) {
            this.inputRef.current.focus();
        }
    }

    clearQuery () {
        this.setState({
            query: ''
        }, () => {
            this.props.onReset();
        });
    }

    onChange (e) {
        e.preventDefault();
        this.setState({
            query: e.target.value
        }, () => {
            const { onSearch, onClear } = this.props;
            clearInterval(this.searchTimeout);
            this.searchTimeout = setTimeout(() => {
                const query = this.state.query;
                if (query === this.props.initialQuery || query === '') {
                    onClear();
                }
                else if (query.length > 2) {
                    onSearch(query);
                }
            }, 500);
        });
    }

    onKeyUp (ev) {
        if (ev.key === 'Escape') {
            this.clear();
            ev.target.blur();
        }
    }

    clear () {
        this.setState({
            query: this.props.initialQuery,
        }, () => {
            if (this.props.onClear) {
                this.props.onClear();
            }
        });
    }

    render () {
        let className = 'search-input ' + this.props.className;

        return (
            <div className={className}>
                <Icons className="mobile--search-input--icon" name="search" color="white" width="24" height="24"/>
                <input
                    type="text"
                    ref={this.inputRef}
                    value={this.state.query}
                    onChange={this.onChange.bind(this)}
                    onKeyUp={this.onKeyUp.bind(this)}
                    className="search-input--input"
                    placeholder={this.props.placeholder}/>
            </div>
        );
    }
}

export default SearchInput;
