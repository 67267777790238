export const getItemByUrl = (items, url) => {
    const iterate = item => {
        if (item.url === url) {
            find = item;
            return true;
        }

        if (item.children) {
            return item.children.some(iterate);
        }
    };

    let find;
    items.some(iterate);
    return find;
};
