import produce from 'immer';

import {
    SET_TITLE,
    CLEAR_TITLE,
    SET_MARKET
} from '../actions/info';

const initialState = {
    titleData: {},
    title: '',
    siteName: '',
    marketType: 'private',
};

const constructPageTitle = (titleData, siteName) => {
    let keys = Object.keys(titleData);
    keys.sort();
    let title = keys.map(key => titleData[key]);
    title.push(siteName);
    return title.join(' - ');
};

function info(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case SET_TITLE:
                draft.titleData[action.index] = action.value;
                draft.title = constructPageTitle(draft.titleData, draft.siteName);
                break;

            case CLEAR_TITLE:
                draft.titleData = {};
                draft.title = '';
                break;

            case SET_MARKET:
                draft.marketType = action.marketType;
                break;
        }
    });
}

export default info;
