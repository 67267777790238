/**
 * @file             : services/frontend/src/www-src/scripts/components/index.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 15.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */

import FAQClusterPage from './faq-cluster-page';
import TableComponent from './table-component';
import WrappedImage from './wrapped-image';
import StepsBoxes from './steps-boxes';
import Spacer from './spacer';
import LoanCalculator from './@resursbank/loan-calculator';
import MarketingConsent from './@resursbank/samtykke/marketing-consent';
import CustomerConsent from './@resursbank/samtykke/customer-consent';
import CustomerCenterConsent from './@resursbank/samtykke/customer-center-consent';


/*
    Containers
 */

import ContactForm from '../containers/contact-form';
import DocumentHandler from '../containers/document-handler';
import GiftCardBalance from '../containers/gift-card-balance';

import ClickableInfoBoxes from '../containers/clickable-infoboxes';

// These are Gutenberg blocks
import Section from './section/section';
import SectionColumn from './section/column';
import Text from './text';
import Heading from './heading';
import List from './list/list';
import ButtonComponent from './button-component';
import Group from '../containers/group';
import Humany from '../containers/humany-widget';
import Iframe from './iframe';
import ApplicationForm from './@resursbank/application-form';
import Signing from './@resursbank/signing';
import Identification from './@resursbank/identification';
/*
*/
const vcComponents = {
    // Gutenberg
    'next24hr/section': Section,
    'next24hr/column': SectionColumn,
    'resurs/paragraph': Text,
    'resurs/heading': Heading,
    'resurs/spacer': Spacer,
    'core/list': List,
    'resurs/button': ButtonComponent,
    'resurs/document-handler': DocumentHandler,
    'resurs/image': WrappedImage,
    'resurs/gift-card-balance': GiftCardBalance,
    'core/group': Group,
    'core/table': TableComponent,
    'resurs/clickable-infoboxes': ClickableInfoBoxes,
    'resurs/steps-boxes': StepsBoxes,
    'resurs/faq-cluster': FAQClusterPage,
    'resurs/contact-form': ContactForm,
    'resurs/humany': Humany,
    'resurs/iframe': Iframe,
    'resurs/application-form': ApplicationForm,
    'resurs/marketing-consent': MarketingConsent,
    'resurs/customer-center-consent': CustomerCenterConsent,
    'resurs/customer-consent': CustomerConsent,
    'resurs/signing': Signing,
    'resurs/identification': Identification,
    'resurs/calculatorpage': LoanCalculator,
};

function vcConvertToProps(component, attributes, parentProps, childrenData) {
    let Module = vcComponents[component];
    if (Module && typeof Module.vcConvertToProps === 'function') {
        return Module.vcConvertToProps(
            Object.assign({}, parentProps, attributes),
            childrenData
        ); // Override components attributes with parent (if they both share the name attribute)
        // } else if (Module && typeof Module.vcConvertToProps === 'undefined') {
        //     // eslint-disable-next-line no-console
        //     console.warn(
        //         'Module: ',
        //         Module.name,
        //         ' does not implement vcConvertToProps() function',
        //     );
    }

    return attributes;
}

export { vcComponents, vcConvertToProps };
