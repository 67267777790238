import { css } from '@emotion/core';

/* Sizes */
const xxsmall = css`
    font-size: 1.375rem;
    line-height: 1.1;
`;

const xsmall = css`
    font-size: 1.5rem;
    line-height: 1.33333em;
`;

const small = css`
    font-size: 1.75rem;
    line-height: 1.5em;
`;

const normal = css`
    font-size: 2rem;
    line-height: 1.5em;
`;

const medium = css`
    font-size: 2.25rem;
    line-height: 1.5em;
`;

const large = css`
    font-size: 3rem;
    line-height: 1.5em;
`;

const xlarge = css`
    font-size: 4rem;
    line-height: 1.5em;
`;

const sizes = {
    xxsmall,
    xsmall,
    small,
    normal,
    medium,
    large,
    xlarge,
};

const fontFamily = '"Montserrat", Arial, sans-serif';

/* Weights */
const light = css`
    font-family: ${fontFamily};
    font-weight: 400;
`;

const bold = css`
    font-family: ${fontFamily};
    font-weight: 700;
`;

const bolder = css`
    font-family: ${fontFamily};
    font-weight: 700;
`;

const weights = {
    normal: light,
    bold,
    bolder,
};

export const TextCss = ({
    size,
    weight,
    color,
    backgroundColor,
    italic,
    padding,
    contentPadding,
    margin,
    theme,
    align,
}) => {
    let colorToUse = 'black';
    let bgColor = theme.colors[backgroundColor] || backgroundColor || 'transparent';
    if (color) {
        colorToUse = theme.colors[color] || color;
    }
    return css`
        color: ${colorToUse};
        background: ${bgColor};
        font-style: ${italic ? 'italic' : 'initial'};
        ${sizes[size] ? sizes[size] : normal}
        ${weights[weight] ? weights[weight] : light};
        ${padding
        ? css`
                padding: ${padding};
            `
        : css`
                padding: ${theme.spacing[contentPadding] || 0};
            `};
        ${margin &&
            css`
                margin: ${margin};
            `};

        text-align: ${align};

        .size-small {
            ${small};
        }

        .size-large {
            ${large};
        }

        .size-normal {
            ${normal};
        }
    `;
};
