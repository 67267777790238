import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTransition, animated } from 'react-spring';
import useOutsideClick from '../../../../hooks/use-outside-click';
import { Inner, StyledIcon } from './style';
import Wrapper from '../wrapper';

const Appear = ({
    animation = {},
    children,
    closeIcon,
    closeOnOutsideClick = true,
    forceAppear = false,
    open,
    toggleOpen,
    innerStyle,
    outsideClickDependencies = [],
}) => {
    const isVisibleOnMount = useRef(open && !forceAppear);
    const containerRef = useRef(null);
    const innerRef = useRef(null);

    useOutsideClick({
        ref: innerRef,
        dependencies: [open, ...outsideClickDependencies],
        shouldExecute: closeOnOutsideClick,
        callback: e => {
            if (open && e.target && toggleOpen) {
                toggleOpen();
            }
        },
    });

    const visibleStyle = {
        zIndex: '10002',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        opacity: '1',
        ...animation.to,
    };

    const hiddenStyle = {
        ...visibleStyle,
        opacity: '0',
        ...animation.from,
    };

    const transitions = useTransition(open, null, {
        enter: visibleStyle,
        leave: () => async (next, cancel) => {
            cancel();
            await next(hiddenStyle);
            isVisibleOnMount.current = false;
        },
        from: isVisibleOnMount.current ? visibleStyle : hiddenStyle,
        unique: true,
        duration: 200,
    });

    return transitions.map(({ item: show, props: springProps, key }) => {
        if (show) {
            return (
                <animated.div ref={containerRef} key={key} style={springProps}>
                    <Inner innerStyle={innerStyle} ref={innerRef}>
                        {closeIcon && open && (
                            <StyledIcon
                                name="close"
                                width={20}
                                height={20}
                                {...closeIcon}
                            />
                        )}
                        {children}
                    </Inner>
                </animated.div>
            );
        }

        return null;
    });
};

Appear.propTypes = {
    animation: PropTypes.shape({
        to: PropTypes.object,
        from: PropTypes.object,
    }),
    children: PropTypes.node,
    closeIcon: PropTypes.shape({
        onClick: PropTypes.func,
    }),
    closeOnOutsideClick: PropTypes.bool,
    forceAppear: PropTypes.bool,
    innerStyle: PropTypes.object,
    open: PropTypes.bool,
    overlay: PropTypes.oneOfType([
        PropTypes.shape({
            background: PropTypes.string.isRequired,
            alpha: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
    ]),
    toggleOpen: PropTypes.func,
    outsideClickDependencies: PropTypes.array,
};

export default props => (
    <Wrapper {...props}>
        <Appear {...props} />
    </Wrapper>
);
