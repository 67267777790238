import React from 'react'; //eslint-disable-line
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { combineQueryParamsToUrl } from '../../helpers/url-helper';
import { Link, withRouter } from 'react-router-dom';
import { isExternalLink } from '../../helpers/data-helper';
import { setAnchorPointTarget } from '../../actions/anchorpoint';
import { setDataLayer } from '../../helpers/gtm';

import './style.styl';

const LinkWrapper = (props) => {

    function handleClick (e) {

        let category = props.gtmCategory ? (props.gtmCategory.title || props.gtmCategory) : '';
        if (category.error) {
            category = 'click';
        }
        let value = props.gtmValue ? props.gtmValue : '';
        if (category !== '' || value !== '' || props.gtmLabel) {
            setDataLayer({action: 'click', category, label: props.gtmLabel, value});
        }

        const { target, to, onClick } = props;

        if (onClick) {
            onClick(e);
        }

        if (to && isExternalLink(to) || target === '_blank') {
            return;
        }

        setAnchorPointTarget(props.to);

        // If a link has no anchoroint, scroll to top of page
        const anchorPoint = props.to.split('#')[1];
        if (!props.disablePageScroll && !(anchorPoint && anchorPoint.length > 1)) {
            window.scrollTo(0,0);
        }

    }

    let {
        to,
        children,
        className,
        target,
        look,
        size,
        marginBottom,
        download,
        replace,
        location,
        preserveQuerystring,
    } = props;

    let classes = classNames(className, {
        'link--default': look === 'default' || !look,
        'link--dark': look === 'dark',
        'link--dark-always': look === 'dark-always',
        'link--light': look === 'light',
        'link--black': look === 'black',
    }, {
        '': !size,
        'link--small': size === 'small',
        'link--large': size === 'large'
    }, {
        '': !marginBottom,
        'link--margin-btm-small': marginBottom === 'small',
        'link--margin-btm-large': marginBottom === 'large'
    });

    // Remove trailing slash from links
    to = to.replace(/\/$/, '');

    // We check if children contain a link, in which case we warn, since we cant have a link that has a
    // link inside.
    if (Array.isArray(children)) {
        let buttonChildren = children.filter( child => {
            if ( child === null) {
                return false;
            }
            return child.type && child.type.displayName === 'WrappedButton';
        });
        children = buttonChildren.length ? 'Can not have a button as a child of a link' : children;
    }

    // the /login is handled this way because <Redirect> does not handle external URLs and without rebuilding
    // our Link and Button flashes a page before the redirect runs
    let url = to;

    if (preserveQuerystring) {
        url = combineQueryParamsToUrl(location.search, url);
    }
    if (url && url.match(/^\/login/) || isExternalLink(url) || target === '_blank' || /^\/kundesenter/.test(url)) {
        let rel = '';
        if (target === '_blank') {
            rel = 'noopener';
        }

        return (
            <a href={url} className={classes} target={target} onClick={handleClick} rel={rel} download={download}>{ children }</a>
        );
    }


    return (
        <Link data-id={props.dataId} to={url} className={classes} onClick={handleClick} download={download} replace={replace}>{children}</Link>
    );

};

LinkWrapper.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    download: PropTypes.bool,
    gtmLabel: PropTypes.string,
    gtmValue: PropTypes.string,
    dataId: PropTypes.string,
    gtmCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    look: PropTypes.oneOf([ false, 'default', 'light', 'dark', 'dark-always', 'black', null, '']),
    size: PropTypes.oneOf([ 'large', 'small', null, '']),
    marginBottom: PropTypes.oneOf([ 'large', 'small', null, '']),
    target: PropTypes.oneOf([
        '_blank',
        '_self',
        '_parent',
        '_top',
        ''
    ]),
    onClick: PropTypes.func,
    /* If you want to disable the page scrolling to top of the page on link click */
    disablePageScroll: PropTypes.bool,
    replace: PropTypes.bool,
    location: PropTypes.object,
    /* If the target url should include the current query params */
    preserveQuerystring: PropTypes.bool,
};


export default withRouter(LinkWrapper);
