import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logOutUser } from '../../../actions/user';
import {
    closeMobileMenu,
    openMobileMenu,
} from '../../../actions/header-menu-mobile';
import { fetchHeaderMenu } from '../../../actions/header-menu';
import MobileMenuComponent from './components';

const mapStateToProps = ({
    bank,
    headerMenu,
    headerMenuMobile,
    pages,
    viewport,
}) => {
    const logOutUrl =
        bank.settings && bank.settings.logoutPage
            ? bank.settings.logoutPage.url
            : null;

    return {
        currentPage: pages.current,
        isOpen: headerMenuMobile.mobileMenuOpen,
        logOutUrl,
        menu: headerMenu.list[headerMenu.current],
        viewport,
    };
};

const mapDispatch = dispatch => ({
    onLogOutUser: () => dispatch(logOutUser()),
    onToggleMenu: open => dispatch(open ? closeMobileMenu() : openMobileMenu()),
});

const MobileMenu = (
    {
        barLinksLimit,
        currentPage,
        isOpen,
        logOutUrl,
        menu,
        onLogOutUser,
        onToggleMenu,
    },
    context
) => {
    useEffect(() => {
        context.registerFetchAction(
            fetchHeaderMenu('mobile_menu_bank'),
            'fetch-mobile-menu'
        );
    }, []);

    if (!menu || !menu.items) {
        return null;
    }

    return (
        <MobileMenuComponent
            barLinksLimit={barLinksLimit}
            currentPage={`/${currentPage}/`}
            isOpen={isOpen}
            logOut={{
                url: logOutUrl,
                label: context.localize('log-out'),
                dispatch: onLogOutUser,
            }}
            labels={{
                hamburger: menu.menuBarHamburgerLabel,
            }}
            menuBar={menu.items.menuBar}
            menuItems={menu.items.allItems}
            onToggleMenu={onToggleMenu}
        />
    );
};

MobileMenu.propTypes = {
    barLinksLimit: PropTypes.number,
    currentPage: PropTypes.string,
    isOpen: PropTypes.bool,
    logOutUrl: PropTypes.string,
    menu: PropTypes.object,
    onLogOutUser: PropTypes.func,
    onToggleMenu: PropTypes.func,
};

MobileMenu.contextTypes = {
    localize: PropTypes.func,
    registerFetchAction: PropTypes.func,
};

const MobileMenuContainer = withRouter(
    connect(mapStateToProps, mapDispatch)(MobileMenu)
);

export default MobileMenuContainer;
