import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { fetchHeader } from '../../actions/header';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HeaderMenu from '../header-menu';
import {
    closeSearchModal,
    openSearchModal,
    search,
} from '../../actions/search';
import { getFaqFromQuery } from '../../actions/humany';

import './style.styl';

class Header extends React.Component {
    static propTypes = {
        viewport: PropTypes.object,
        marketMenu: PropTypes.object,
        info: PropTypes.object,
        dispatch: PropTypes.func,
        query: PropTypes.string,
        page: PropTypes.number,
        open: PropTypes.bool,
        isBankPage: PropTypes.bool,
        header: PropTypes.object,
        mobile: PropTypes.string,
    };

    constructor(props, context) {
        super(props);
        if (props.info.marketType !== 'cluster' && !props.header.loaded) {
            context.registerFetchAction(fetchHeader(), 'fetch-header');
        }
    }

    handleOpenSearch() {
        openSearchModal()(this.props.dispatch);
    }

    onSearch(query) {
        if (query && query.length >= 3) {
            let page = query === this.props.query ? this.props.page : 1;
            search(query, page)(
                this.props.dispatch,
                this.context.store.getState()
            );
            getFaqFromQuery(query)(
                this.props.dispatch,
                this.context.store.getState()
            );
            if (!this.props.open) {
                window.scrollTo(0, 0);
                openSearchModal()(this.props.dispatch);
            }
        }
    }

    onSearchClear() {
        closeSearchModal()(this.props.dispatch);
    }

    render() {
        const { marketType } = this.props.info;
        const { isBankPage, header, viewport } = this.props;

        const headerMenuProps = {
            marketMenu: marketType === 'cluster' ? [] : header.headerData.menu,
            loginButton: header.headerData.loginButton,
            marketType: marketType,
            topMenu: header.headerData.topMenu,
        };

        const headerClasses = classNames({
            'header-bank-mobile': isBankPage && viewport.isMobileDevice,
        });

        return (
            <div className={headerClasses}>
                <HeaderMenu {...headerMenuProps} />
            </div>
        );
    }
}

Header.contextTypes = {
    registerFetchAction: PropTypes.func,
    store: PropTypes.object,
    localize: PropTypes.func,
    featureSupport: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        header: state.header,
        viewport: state.viewport,
        info: state.info,
        query: state.search.query,
        page: state.search.page,
        isBankPage: state.pages.isBankPage,
        open: state.search.open,
        mobile: state.settings.mobile,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    };
};

const HeaderContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Header)
);

export default HeaderContainer;
