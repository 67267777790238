import React from 'react';
import PropTypes from 'prop-types';

import SearchResultsListItem from '../search-results-list-item';
import ClassNames from 'classnames';

import './style.styl';

class SearchResultsList extends React.Component {

    static propTypes = {
        hits: PropTypes.array,
        viewport: PropTypes.object,
        onSearchResultSelected: PropTypes.func,
        isHumanyList: PropTypes.bool
    };

    static defaultProps = {
        hits: [],
        onSearchResultSelected: () => {},
        isHumanyList: false
    };

    constructor (props) {
        super(props);
    }

    renderResultsListItems () {
        const { viewport, hits, onSearchResultSelected, isHumanyList } = this.props;
        return hits.map((hit) => {
            if (isHumanyList) {
                return (
                    <SearchResultsListItem
                        key={`search-result-${hit.Id}`}
                        item={hit}
                        viewport={viewport}
                        template='humany'
                        onSearchResultSelected={onSearchResultSelected}
                    />
                );
            }
            return (
                <SearchResultsListItem
                    key={`search-result-${hit.id}`}
                    item={hit}
                    viewport={viewport}
                    template={hit.template}
                    onSearchResultSelected={onSearchResultSelected}
                />
            );
        });
    }

    renderNotFound () {
        return this.props.isHumanyList ? '' : this.context.localize('noResults');
    }

    render () {
        const hits = this.props.hits;
        const containerClassNames = ClassNames(
            'search-result-list--container',
            {'search-result-list--container--humany':this.props.isHumanyList}
        );
        return (
            <div className={containerClassNames}>
                {hits.length > 0 ? this.renderResultsListItems() : this.renderNotFound()}
            </div>
        );
    }
}


SearchResultsList.contextTypes = {
    localize: PropTypes.func,
};

export default SearchResultsList;
