import React from 'react';
import PropTypes from 'prop-types';
import { StyledSvgButton } from './style';
import { Cookie } from './cookie';

const ToggleCookiePreferences = ({ toggleConsent }) => (
    <StyledSvgButton onClick={() => toggleConsent(true)}>
        <Cookie />
    </StyledSvgButton>
);

ToggleCookiePreferences.propTypes = {
    toggleConsent: PropTypes.func,
};

export default ToggleCookiePreferences;
