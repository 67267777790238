import Manager from 'react-scrollable-anchor/lib/Manager';

export const ANCHORPOINTS_UPDATE_OFFSET  = 'ANCHORPOINTS_UPDATE_OFFSET';

function updateAnchorpointOffset(offset) {
    return {
        type: ANCHORPOINTS_UPDATE_OFFSET,
        offset
    };
}

export function setAnchorPointOffset(offset) {

    return function (dispatch) {
        if (process.title === 'browser') {
            dispatch(updateAnchorpointOffset(offset));
        }
    };
}

export function setAnchorPointTarget(permalink) {
    if (process.title === 'browser' && permalink) {
        let anchorPoint = permalink.split('#')[1];
        if (anchorPoint && anchorPoint.length > 1) {
            Manager.goToSection(anchorPoint);
        }
    }
}

