import React from 'react';
import PropTypes from 'prop-types';
import { StyledDescriptionBoxContainer } from './style';

const DescriptionBox = ({ children }) => {
    return (
        <StyledDescriptionBoxContainer>
            {children}
        </StyledDescriptionBoxContainer>
    );
};

DescriptionBox.propTypes = {
    children: PropTypes.any,
};

export default DescriptionBox;
