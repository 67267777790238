export const MODALS_OPEN = 'MODALS_OPEN';

export function openModal (name, props) {
    return {
        type: MODALS_OPEN,
        payload: { name, props },
    };
}

export const MODALS_CLOSE = 'MODALS_CLOSE';

export function closeModal (name) {
    return {
        type: MODALS_CLOSE,
        payload: name,
    };
}
