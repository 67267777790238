import { postResource } from '../helpers/fetch-helper';
import { sendPageInteraction } from '../helpers/gtm';

export const SET_SUBMIT_ERROR = 'SET_SUBMIT_ERROR';
export const SET_SUBMIT_LOADING = 'SET_SUBMIT_LOADING';

export function setSubmitError(formId, error) {
    return {
        type: SET_SUBMIT_ERROR,
        formId: formId,
        error: error,
    };
}

export function setSubmitLoading(formId, loading) {
    return {
        type: SET_SUBMIT_LOADING,
        formId: formId,
        loading: loading,
    };
}

export function submitForm(formData, options = {}, endpoint = 'form') {
    return async function(dispatch, getState) {
        function sendAnalyticsData() {
            const actions = [];
            const { gtmOptions = {} } = options;
            if (gtmOptions.paymentInsurance) {
                actions.push('Sign payment insurance');
            }

            actions.forEach(action => sendPageInteraction({ action }));
        }

        let state = Object.keys(getState).length === 0 ? getState() : getState;

        const url = `${state.settings.apiUrl}/${endpoint}`;
        const formId =
            formData instanceof FormData
                ? formData.get('formId')
                : formData.formId;

        dispatch(setSubmitError(formId, false));
        dispatch(setSubmitLoading(formId, true));

        try {
            const response = await postResource(url, formData, options);
            sendAnalyticsData();
            dispatch(setSubmitLoading(formId, false));
            return response;
        } catch (err) {
            dispatch(setSubmitError(formId, err));
            dispatch(setSubmitLoading(formId, false));
            throw err;
        }
    };
}

export function submitBusinessLoanForm(formData, options = {}) {
    return submitForm(formData, options, 'business-loan-form');
}

export const SET_BUSINESS_LOAN_CREDIT_CHECK_ERROR =
    'SET_BUSINESS_LOAN_CREDIT_CHECK_ERROR';

export function setSubmitCreditCheckError(formId, error) {
    return {
        type: SET_BUSINESS_LOAN_CREDIT_CHECK_ERROR,
        formId: formId,
        error: error,
    };
}

export function submitBusinessLoanCreditCheck(formData, options = {}) {
    return async function(dispatch, getState) {
        let state = Object.keys(getState).length === 0 ? getState() : getState;

        const url = `${state.settings.apiUrl}/business-loan/credit-check`;
        const formId =
            formData instanceof FormData
                ? formData.get('formId')
                : formData.formId;

        dispatch(setSubmitCreditCheckError(formId, false));
        dispatch(setSubmitLoading(formId, true));

        try {
            const response = await postResource(url, formData, options);
            dispatch(setSubmitLoading(formId, false));
            return response;
        } catch (err) {
            dispatch(setSubmitCreditCheckError(formId, err));
            dispatch(setSubmitLoading(formId, false));
            throw err;
        }
    };
}

export function submitCisionSubscribe(formData) {
    return async function(dispatch, state) {
        const url = `${state.settings.apiUrl}/cision/subscribe`;
        dispatch(setSubmitError('cision--subscribe-form', false));
        dispatch(setSubmitLoading('cision--subscribe-form', true));

        try {
            const response = await postResource(url, formData, {});
            dispatch(setSubmitLoading('cision--subscribe-form', false));
            return response;
        } catch (err) {
            dispatch(setSubmitError('cision--subscribe-form', err.message));
            dispatch(setSubmitLoading('cision--subscribe-form', false));
            throw err;
        }
    };
}
