import React from 'react';
import uuid from 'uuid/v4';
import { vcComponents } from '../components';

export const getInnerBlockComponentsRecursive = (blocks, viewport) => {
    return blocks.map(block => {
        if (!block || !block.blockName) return;
        const Component = vcComponents[block.blockName] || null;

        if (Component) {
            let parsedProps =
            typeof Component.parseProps === 'function'
                ? Component.parseProps(block)
                : block;

            const innerBlocks = parsedProps.blocks
                ? getInnerBlockComponentsRecursive(parsedProps.blocks)
                : null;

            if (block.blockName === 'next24hr/section') {
                parsedProps = { ...parsedProps, width: 'contained' };
            }

            return (
                <Component
                    key={uuid()}
                    children={innerBlocks}
                    viewport={viewport}
                    {...parsedProps}
                />
            );
        }
        return null;
    });
};

/**
 * This should always return an object that has the same shape expected in our react image component
 *
 * @param {object} gutenbergImage - the image coming from gutenberg
 * @returns {object}
 */
export const parseGutenbergImage = (gutenbergImage) => {
    // Some properties are nested
    const { image } = gutenbergImage;

    if (!image) {
        throw new Error('missing-image-data');
    }

    return {
        src: image.url,
        focus: image.focus,
        originalHeight: image.height,
        originalWidth: image.width,
        width: '100%',
        heightAspectRatio: image.height / image.width,
        jwt: image.jwt,
        service: gutenbergImage.service,
        useNativeWidth: gutenbergImage.useNativeWidth,
        removePadding: gutenbergImage.removePadding,
        imageVersion: gutenbergImage.imageVersion,
        altText: gutenbergImage.altText,
        imageText: gutenbergImage.imageText,
        imageLinkUrl: gutenbergImage.link,
        imageIsLink: gutenbergImage.imageIsLink ? true : false,
    };
};
