import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './svg-icon';

const ArrowLeftWithTail = ({ viewBox = '-14 -8 50 50', ...rest }) => {
    return (
        <SvgIcon viewBox={viewBox} {...rest}>
            <polygon
                points="1 22.8716323 3.45 20.3866878 8.2 25.2551506 8.2 0 11.7 0 11.7 25.2551506 16.5 20.3866878 19 22.8716323 14.1 27.8922345 11.7 25.6608558 11.7 30.2757528 10 32"
            ></polygon>
        </SvgIcon>
    );
};

ArrowLeftWithTail.propTypes = {
    viewBox: PropTypes.string,
};

export default ArrowLeftWithTail;
