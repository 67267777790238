import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@24hr/resursbank-theme/ya-bank';
import Toggle from '../../../../components/toggle';
import Text from '../../../../components/text';
import Icons from '../../../../components/icons';
import {
    StyledToggleSection,
    StyledTitleRow,
    StyledMandatoryTextHolder,
} from './style';

const ToggleSection = ({
    canToggle,
    title,
    description,
    mandatoryText,
    onToggle,
    toggled,
}) => {
    const renderToggleSection = () => {
        if (canToggle) {
            return <Toggle onChange={onToggle} value={toggled} />;
        }
        return (
            <StyledMandatoryTextHolder>
                <span>
                    <Icons
                        name="checkMark"
                        width={15}
                        height={15}
                        color={colors.blue}
                    />
                </span>
                {mandatoryText}
            </StyledMandatoryTextHolder>
        );
    };
    return (
        <StyledToggleSection>
            <StyledTitleRow>
                <Text as="p" weight="bold">
                    {title}
                </Text>
                {renderToggleSection()}
            </StyledTitleRow>
            <Text as="p">{description}</Text>
        </StyledToggleSection>
    );
};

ToggleSection.propTypes = {
    canToggle: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    mandatoryText: PropTypes.string,
    toggled: PropTypes.bool,
    onToggle: PropTypes.func,
};

export default ToggleSection;
