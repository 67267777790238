import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../variables/style';
import * as Templates from './templates';

import './style.styl';

class SearchResultsListItem extends React.Component {

    static propTypes = {
        template: PropTypes.string,
        item: PropTypes.object,
        viewport: PropTypes.object,
        highlight: PropTypes.object,
        onSearchResultSelected: PropTypes.func
    };

    static defaultProps = {
        viewport: {},
        template: 'page',
        onSearchResultSelected: () => {}
    };

    constructor (props) {
        super(props);
    }

    handleItemPress () {
        const { viewport, item, onSearchResultSelected } = this.props;
        if (viewport.width < styles.breakpoints.mobileMaxWidth) {
            onSearchResultSelected(item.permalink);
        }
    }

    renderSearchItemTemplate () {
        const { viewport, item, template, onSearchResultSelected } = this.props;
        switch (template) {
            case 'humany':
                return (
                    <Templates.HumanyItem
                        item={item}
                        viewport={viewport}
                        onSearchResultSelected={onSearchResultSelected}
                    />
                );
            case 'page':
            case 'post':
            default:
                return (
                    <Templates.SingleDocument
                        item={item}
                        viewport={viewport}
                        onSearchResultSelected={onSearchResultSelected}/>
                );
        }
    }

    render () {
        return (
            <div className="search-result-list--item" onClick={this.handleItemPress.bind(this)}>
                {this.renderSearchItemTemplate()}
            </div>
        );

    }

}

export default SearchResultsListItem;
