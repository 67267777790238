import { fetchResource, parseHeaderData } from '../helpers/fetch-helper';

export const REQUEST_FOOTER = 'REQUEST_FOOTER';
export const RECEIVE_FOOTER = 'RECEIVE_FOOTER';
export const CLOSE_MENU = 'CLOSE_MENU';

function requestFooter() {
    return {
        type: REQUEST_FOOTER
    };
}

function receivedFooter(data) {
    return {
        type: RECEIVE_FOOTER,
        payload: data
    };
}

export function fetchFooter() {

    return async function (dispatch, state, requestOptions) {

        let url = `${state.settings.apiUrl}/footer`;

        if (state.headerMenu.loading) {
            return true;
        }

        dispatch(requestFooter());

        try {
            const menu = await fetchResource(url, parseHeaderData(state.requestData, requestOptions));
            dispatch(receivedFooter(menu));
            return Promise.resolve();
        } catch (err) {
            // dispatch(notFound());
            throw err;
        }

    };

}
