import { parseHeaderData, fetchResource } from '../helpers/fetch-helper';
import { openModal } from './modals';
import { logOutUser } from './user';

export const HEARTBEAT = 'HEARTBEAT';

const HEARTBEAT_MIN_INTERVAL = 30000;

// Set to function when starting
let execHeartbeat;
let expirationWarningTimeout;

// This forces a heartbeat, othwerwise we dont force a new token
export function heartbeat() {
    if (execHeartbeat) {
        execHeartbeat(true);
    }
}

export function clearHeartbeat() {
    clearTimeout(expirationWarningTimeout);
}

export function startHeatbeat(dispatch, { getState }) {

    if (!execHeartbeat) {

        let lastBeat;
        let beatPending = false;
        let logoutTimeout;

        execHeartbeat = async (force = false) => {



            const now = new Date();
            const state = getState();
            const { inactivityWarningTimeout } = state.bank.settings;


            if ( (force === true && !beatPending) || (!beatPending && (!lastBeat || now - lastBeat > HEARTBEAT_MIN_INTERVAL))) {

                clearTimeout(expirationWarningTimeout);
                clearTimeout(logoutTimeout);

                lastBeat = now;
                beatPending = true;

                const url = `${state.settings.apiUrl}/auth/heartbeat?timestamp=${(new Date()).getTime()}`;

                const options = { method: 'GET', credentials: 'include' };
                const beat = await fetchResource(
                    url,
                    parseHeaderData(state.requestData, options)
                );

                if (!beat) {
                    /**
                     * If user logs out during a pending heartbeat request we will have no
                     * beat.
                     */
                    return;
                }

                beatPending = false;

                const tokenExpires = new Date(
                    Date.now() + beat.tokenExpiresIn * 1000 + 60
                );

                dispatch({
                    type: HEARTBEAT,
                    ...beat,
                    authenticated: beat.authenticated,
                    tokenExpires,
                });

                if (beat.authenticated) {
                    const showWarningTime = beat.tokenExpiresIn * 1000 - inactivityWarningTimeout * 60 * 1000;
                    if (showWarningTime > 0) {
                        expirationWarningTimeout = setTimeout(() => {
                            dispatch(openModal('INACTIVE', { tokenExpires }));
                        }, showWarningTime);
                    }
                    logoutTimeout = setTimeout(
                        execHeartbeat,
                        // try to call the logout a little before it actually expires so we can call the api logout in
                        // their bank API
                        beat.tokenExpiresIn * 1000 - 300
                    );
                } else if (!beat.authenticated && state.user.isAuthenticated) {
                    location.href = state.bank.settings.logoutPage.url;
                    // we do not need to clear the show warning timeout here as logOut action already does that.
                    logOutUser()(dispatch, state);
                } else {
                    // we do not need to clear the show warning timeout here as logOut action already does that.
                    logOutUser()(dispatch, state);
                }
            }
        };

        window.addEventListener('click', execHeartbeat);
        window.addEventListener('scroll', execHeartbeat);

        execHeartbeat();

    }

}

export function stopHeartbeat() {
    if (execHeartbeat) {
        window.removeEventListener('click', execHeartbeat);
        window.removeEventListener('scroll', execHeartbeat);
        execHeartbeat = null;
    }
}

export function triggerHeartbeat() {
    return async function(dispatch, state, requestOptions) {
        const url = `${state.settings.apiUrl}/auth/heartbeat`;
        const beat = await fetchResource(
            url,
            parseHeaderData(state.requestData, requestOptions)
        );

        const tokenExpires = new Date(Date.now() + beat.tokenExpiresIn * 1000);

        dispatch({
            type: HEARTBEAT,
            ...beat,
            authenticated: beat.authenticated,
            tokenExpires,
        });
    };
}
