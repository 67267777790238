import React from 'react';
import PropTypes from 'prop-types';

const CircleSpinner = ({ height, width }) => {
    return (
        <svg
            width={width}
            height={height}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-dual-ring"
            style={{ background: 'none' }}
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                strokeLinecap="round"
                r="40"
                strokeWidth="4"
                stroke="#e15500"
                strokeDasharray="62.83185307179586 62.83185307179586"
                transform="rotate(239.904 50 50)"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </circle>
        </svg>
    );
};

CircleSpinner.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
};

CircleSpinner.defaultProps = {
    height: 20,
    width: 20,
};

export default CircleSpinner;
