const executeIfFunction = f => (typeof f === 'function' ? f() : f);

/**
 * Acts like a switch case but without fall-through.
 * Evaluate case with a key and retrieve its value.
 * Values can be functions where only the matching case will be evaluated.
 * @param {object} cases - object to find key in
 * @param {string} defaultCase - default object key
 * @param {string} key - object key
 * @return {(defaultCase: string) => (key: string) => any} object value
 * @example
 *
 * ```js
switchCaseF({
    'RESET': 0,
    'INCREMENT': () => state + 1,
    'DECREMENT': () => state -1
})(state)(action.type)
 * ```
 */
export const switchCase = cases => defaultCase => key =>
    executeIfFunction(
        cases.hasOwnProperty(key) ? cases[key] : cases[defaultCase]
    );
