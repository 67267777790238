/**
 * @file             : services/frontend/src/www-src/scripts/actions/header-menu-mobile.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 24.10.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
export const ACTIVATE_MOBILE_SUBMENU = 'ACTIVATE_MOBILE_SUBMENU';
export const ACTIVATE_MOBILE_MENU_GO_BACK = 'ACTIVATE_MOBILE_MENU_GO_BACK';
export const TOGGLE_IS_GLOBALLY_COLLAPSED = 'TOGGLE_IS_GLOBALLY_COLLAPSED';
export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const SET_ACTIVE_MENU_ITEMS = 'SET_ACTIVE_MENU_ITEMS';
export const OPEN_MENU_ITEM = 'OPEN_MENU_ITEM';
export const CLOSE_MENU_ITEM = 'CLOSE_MENU_ITEM';

function activateMobileMenuBack() {
    return {
        type: ACTIVATE_MOBILE_MENU_GO_BACK
    };
}


function toggleMobileMenu(open) {
    return {
        type: TOGGLE_MOBILE_MENU,
        open
    };
}



export function setMobileMenuBack() {
    return function(dispatch) {
        dispatch(activateMobileMenuBack());
    };
}



export function toggleIsGloballyCollapsed(isCollapsed) {
    return function(dispatch) {
        dispatch({
            type: TOGGLE_IS_GLOBALLY_COLLAPSED,
            isCollapsed: isCollapsed
        });
    };
}

export function openMobileMenu() {
    return function(dispatch) {
        dispatch(toggleMobileMenu(true));
    };
}

export function closeMobileMenu() {
    return function(dispatch) {
        dispatch(toggleMobileMenu(false));
    };
}

export function setActiveMenuItems(activeMenuItems) {
    return {
        type: SET_ACTIVE_MENU_ITEMS,
        activeMenuItems,
    };
}

export function openMenuItem(levelAndIdPair) {
    return {
        type: OPEN_MENU_ITEM,
        levelAndIdPair,
    };
}

export function closeMenuItem(key) {
    return {
        type: CLOSE_MENU_ITEM,
        key,
    };
}

