export function setDataLayer(data = {}) {
    if (!data?.event) {
        data.event = 'eventTracking'; // add a generic eventTracking tag to make the event useable in GTM
    }
    if (process.title === 'browser' && window.dataLayer) {
        if ('eventInfo' in data) {
            window.dataLayer.push({ eventInfo: null }); // Make sure that eventInfo always is null before pushing new data
        }
        window.dataLayer.push(data);
    }
}

/**
 * Function that sends Page_Interaction event to analytics.
 * @author Marcus Thelin <marcus.thelin@24hr.se>
 * @param {object} eventData - Payload to send along event.
 */
export function sendPageInteraction(eventData = {}) {
    const data = {
        category: 'My pages',
        ...eventData,
    };
    data.event = 'Page_Interaction';
    if (process.title === 'browser' && window.dataLayer) {
        window.dataLayer.push(data);
    } else {
        // eslint-disable-next-line
        console.info('GA:', data);
    }
}