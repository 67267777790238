import './style.styl';

import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { twitterSquare } from 'react-icons-kit/fa/twitterSquare';
import { linkedin } from 'react-icons-kit/fa/linkedin';
import { instagram } from 'react-icons-kit/fa/instagram';
import { facebookSquare } from 'react-icons-kit/fa/facebookSquare';
import { youtubeSquare } from 'react-icons-kit/fa/youtubeSquare';
import Row from '../row';
import Link from '../link';
import Column from '../column';
import Logo from '../logo';
import Newsletter from '../../containers/newsletter';

class Footer extends React.Component {
    static propTypes = {
        copyright: PropTypes.string,
        socialLinks: PropTypes.arrayOf(
            PropTypes.shape({
                network: PropTypes.string.isRequired,
                href: PropTypes.string.isRequired,
            })
        ),
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string.isRequired,
                content: PropTypes.string.isRequired,
            })
        ),
        viewport: PropTypes.object,
        showTop: PropTypes.bool,
        version: PropTypes.string,
    };

    render() {
        const ICON_COMPONENTS = {
            facebook: facebookSquare,
            instagram: instagram,
            linkedin: linkedin,
            twitter: twitterSquare,
            youtube: youtubeSquare,
        };

        const showTop = this.props.showTop;

        let copyright = this.props.copyright;
        let columns = this.props.columns;
        let links = this.props.socialLinks;
        let columnElements =
            columns &&
            columns.map((colData, i) => {
                let colWidth = '1/' + columns.length;
                return (
                    <Column
                        width={colWidth}
                        key={`col-${i}`}
                        className="footer--column"
                        viewport={this.props.viewport}
                    >
                        {colData.header && (
                            <h2 className="footer--column--header">
                                {colData.header}
                            </h2>
                        )}
                        <div
                            className="footer--column--content"
                            dangerouslySetInnerHTML={{
                                __html: colData.content,
                            }}
                        />
                        {i === columns.length - 1 && (
                            <Newsletter
                                title="MOTTA VÅRT NYHETSBREV"
                                labels={{
                                    emailPlaceholder: 'Din e-postadresse',
                                    emailErrorText:
                                        'Legg inn en gyldig e-postadresse',
                                    emailSuccess: 'Du er registrert.',
                                    signUp: 'Send',
                                }}
                            />
                        )}
                    </Column>
                );
            });

        let linkElements =
            links &&
            links.map((linkData, i) => {
                let classes =
                    'footer--icon-box footer--icon-box-' + linkData.network;
                let IconComponent = ICON_COMPONENTS[linkData.network];
                let icon = IconComponent ? (
                    <div
                        style={{
                            color: '#3e3e3e',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '95%',
                        }}
                    >
                        <Icon size={22} icon={IconComponent} />
                    </div>
                ) : null;

                return (
                    <div className={classes} key={`link-${i}`}>
                        <Link look="light" to={linkData.href} target="_blank">
                            {icon}
                        </Link>
                    </div>
                );
            });

        return (
            <footer className="footer">
                {showTop && (
                    <div className="footer--top">
                        <Row strechRow={true}>{columnElements}</Row>
                    </div>
                )}
                <div className="footer--bottom">
                    <Row strechRow={true} className="footer--strech">
                        <Column
                            className="footer--misc"
                            width="1/4"
                            viewport={this.props.viewport}
                        >
                            <div className="footer--icons-container">
                                {linkElements}
                            </div>
                        </Column>
                        <Column
                            className="footer--misc"
                            width="1/2"
                            viewport={this.props.viewport}
                        >
                            <div className="footer--copyright-text">
                                {copyright}
                            </div>
                        </Column>
                        <Column
                            className="footer--misc"
                            width="1/4"
                            viewport={this.props.viewport}
                        >
                            <div className="footer--logo-container">
                                <Logo
                                    white
                                    className="footer--info--logo"
                                />
                            </div>
                        </Column>
                    </Row>
                    <div className="footer--version">v{this.props.version}</div>
                </div>
            </footer>
        );
    }
}
export default Footer;
