import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import * as YaLib from '@resursbank/ya-react-lib';

const mapToState = state => {
    return {
        env: state.info.env,
    };
};

const ApplicationForm = ({
    form = 'KreditKort',
    location,
    campaignId,
    env,
    defaultSelectedIndex = 0,
    maxNumYears = 15,
    ...restProps
}) => {
    const params = useMemo(() => {
        return queryString.parse(location.search);
    }, [location.search]);

    const FormComponent = React.useMemo(() => YaLib[form], [form]);
    const isProd = env === 'prod';

    const { prefilled, campaignId: campaignIdParam, ...rest } = params;
    const prioritizedCampaignId = campaignIdParam || campaignId;

    const baseApiUrl = isProd
        ? 'https://cmshelper-cust-yabank-prod.apps.okd.paas.h.bitbit.net'
        : 'https://cmshelper-cust-yabank-stage.apps.okd.paas.h.bitbit.net';

    const calculatorEndpointURL = isProd
        ? 'https://calculatorservice-cust-yabank-prod.apps.okd.paas.h.bitbit.net/api/calculator/calculateLoan'
        : 'https://calculatorservice-cust-yabank-stage.apps.okd.paas.h.bitbit.net/api/calculator/calculateLoan';

    return (
        <FormComponent
            prefilled={prefilled === 'true'}
            defaultSelectedIndex={defaultSelectedIndex - 1}
            calculatorMaxYears={maxNumYears}
            campaignId={prioritizedCampaignId}
            baseApiUrl={baseApiUrl}
            calculatorEndpointURL={calculatorEndpointURL}
            {...rest}
            {...restProps}
        />
    );
};

ApplicationForm.parseProps = atts => {
    return atts;
};

ApplicationForm.propTypes = {
    env: PropTypes.string,
    form: PropTypes.string,
    campaignId: PropTypes.string,
    location: PropTypes.shape({
        search: PropTypes.string,
    }),
    defaultSelectedIndex: PropTypes.number,
    maxNumYears: PropTypes.number,
};

export default connect(mapToState)(withRouter(ApplicationForm));
