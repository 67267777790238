import { fetchResource, parseHeaderData } from '../../helpers/fetch-helper';

export const REQUEST_FAQ = 'REQUEST_FAQ';
export const RECEIVE_FAQ = 'RECEIVE_FAQ';
export const FAQ_ERROR = 'FAQ_ERROR';

export function requestFaq() {
    return {
        type: REQUEST_FAQ,
    };
}

export function receivedFaq(faq) {
    return {
        type: RECEIVE_FAQ,
        faq,
    };
}

export function faqError(error) {
    return {
        type: FAQ_ERROR,
        error,
    };
}

export function getFaqFromQuery(query) {
    return async function(dispatch, state, requestOptions) {
        const url = `${state.settings.apiUrl}/humany/faq/${query}`;
        dispatch(requestFaq());
        try {
            const humanyFaq = await fetchResource(
                url,
                parseHeaderData(state.requestData, requestOptions)
            );
            dispatch(receivedFaq(humanyFaq));
            return Promise.resolve();
        } catch (err) {
            dispatch(faqError(err));
            return Promise.reject(err);
        }
    };
}
