import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import cx from 'classnames';
import Spinner from '../circle-spinner';
import Tooltip from '../tooltip';
import './style.styl';

const ToggleComponent = ({
    ariaLabel,
    disabled,
    id,
    label,
    name,
    onChange,
    value,
    className,
    enableSpinner = false,
    loading = false,
    tooltipText,
}) => {
    return (
        <div className={cx('toggle--wrapper', className)}>
            {label && (
                <label
                    className="toggle--label"
                    htmlFor={id}
                    id={`${name}-${id}`}
                >
                    {label}
                </label>
            )}
            <Toggle
                aria-label={ariaLabel}
                aria-labelledby={`${name}-${id}`}
                disabled={disabled}
                id={id}
                checked={value}
                icons={false}
                id={id}
                name={name}
                onChange={event => onChange(event.target.checked)}
            />
            {enableSpinner && loading && (
                <div className="toggle-wrapper--spinner">
                    <Spinner />
                </div>
            )}
            {tooltipText && (
                <div className="toggle-wrapper--tooltip">
                    <Tooltip info={tooltipText} />
                </div>
            )}
        </div>
    );
};

ToggleComponent.propTypes = {
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    className: PropTypes.string,
    enableSpinner: PropTypes.bool,
    loading: PropTypes.bool,
    tooltipText: PropTypes.string,
};

export default ToggleComponent;
