export const getParentsByItem = (items, itemId, parentId) => {
    return items.reduce((acc, item) => {
        acc = item.ID === parentId ? [...acc, parentId, itemId] : acc;
        // prettier-ignore
        acc = item.children
            ? [
                ...acc,
                ...getParentsByItem(item.children, item.ID, parentId),
            ]
            : acc;

        return acc;
    }, []);
};
