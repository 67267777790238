const CORPORATE_ACCOUNTS = 'corporate_accounts';
const MPTS = 'mpts';

export const shouldShowItem = ({
    requires,
    userHasCorporateAccounts,
    userHasMpts,
}) => {
    let show = true;

    switch (requires) {
        case CORPORATE_ACCOUNTS:
            show = userHasCorporateAccounts;
            break;
        case MPTS:
            show = userHasMpts;
            break;
    }

    return show;
};
