import React from 'react';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import MenuItem from '../menu-item';
import Icons from '../../../../../components/icons';
import Button from '../../../../../components/button-component';
import { StyledMenuWapper, StyledMenuWapperHeader } from './style';

const MenuWrapper = ({
    currentPage,
    expandedItems,
    logOut,
    menu,
    onToogleMenu,
    setExpandedItems,
}) => {
    const theme = useTheme();

    const menuItemData = item => ({
        id: item.ID,
        badgeType: item.badge_type,
        children: item.has_children ? item.children : false,
        isCurrentPage: currentPage === item.url,
        parent: Number(item.menu_item_parent),
        target: item.target,
        title: item.title,
        type: item.type,
        url: item.url,
    });

    const handleLogOut = () => {
        logOut.dispatch();
        onToogleMenu();
    };

    const handleExpandedItems = (id, e) => {
        e.stopPropagation();

        return setExpandedItems(
            expandedItems.indexOf(id) !== -1
                ? expandedItems.filter(menuId => menuId !== id)
                : [...expandedItems, id]
        );
    };

    const renderMenu = (array, depth = 0) =>
        array.map(item => {
            const extendedItem = {
                ...menuItemData(item),
                depth,
            };

            const hasChildren = Boolean(extendedItem.children);
            const isExpandedItem =
                expandedItems.indexOf(extendedItem.id) !== -1;
            const idToAdd = hasChildren ? extendedItem.id : extendedItem.parent;

            return (
                <MenuItem
                    expandedItems={expandedItems}
                    handleExpandedItems={handleExpandedItems}
                    hasChildren={hasChildren}
                    idToAdd={idToAdd}
                    isCurrentPage={currentPage === extendedItem.url}
                    isExpandedItem={isExpandedItem}
                    item={extendedItem}
                    key={extendedItem.id}
                    onToogleMenu={onToogleMenu}
                    renderMenu={renderMenu}
                />
            );
        });

    return (
        <StyledMenuWapper>
            <StyledMenuWapperHeader>
                <Button
                    url={logOut.url}
                    color="white"
                    border={{ shorthand: 'border', color: 'white' }}
                    onClick={handleLogOut}
                    allCaps={true}
                >
                    {logOut.label}
                </Button>
                <Icons
                    color={theme.colors.white}
                    height={34}
                    name="cross"
                    onClick={onToogleMenu}
                    width={34}
                />
            </StyledMenuWapperHeader>
            {renderMenu(menu)}
        </StyledMenuWapper>
    );
};

MenuWrapper.propTypes = {
    currentPage: PropTypes.string,
    expandedItems: PropTypes.array,
    logOut: PropTypes.object,
    menu: PropTypes.array,
    onToogleMenu: PropTypes.func,
    setExpandedItems: PropTypes.func,
};

export default MenuWrapper;
