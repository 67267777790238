import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledButtonWrapper } from './style';
import { setDataLayer } from '../../helpers/gtm';
import { sendAsGoal } from '../../helpers/ab-first.js';
import { switchCase } from '../../helpers/switch';
import Link from '../link/index';
import * as IconComponent from '../icon-component';

// Overcomplicated way of allowing line breaks in the buttons.
// This is to make sure that nothing else is parsed. The alternative method would
// be to have dangerouslysetinnerhtml, which we really dont ant to use.
const MultiLineText = props => {
    if (!props.children || typeof props.children !== 'string') {
        return props.children;
    }
    const list = props.children.split('\\n');
    if (list.length === 1) {
        return props.children;
    }
    const mapped = list.map(content => {
        return <p>{content}</p>;
    });
    return mapped;
};

const Button = ({
    abFirstGoal,
    allCaps,
    background,
    border = {},
    children,
    color,
    disabled,
    className,
    display = 'block',
    fullWidth,
    gtmCategory,
    gtmLabel,
    gtmValue,
    icon,
    margin,
    onClick,
    rel,
    size = 'small',
    target,
    textAlign = 'left',
    url,
    preserveQuerystring,
}) => {
    const buttonRef = useRef(null);
    const [buttonStyles, setButtonStyles] = useState({});

    useEffect(() => {
        const { paddingRight } = window.getComputedStyle(buttonRef.current);
        setButtonStyles({ paddingRight });
    }, []);

    const setAbFirstGoal = () => {
        if (abFirstGoal && abFirstGoal.useAsGoal) {
            sendAsGoal(abFirstGoal.abTestUuid);
        }
    };

    const handleClick = e => {
        setAbFirstGoal();
        const category = gtmCategory ? gtmCategory.title || gtmCategory : '';
        const value = gtmValue ? gtmValue : '';

        if (category !== '' || value !== '' || gtmLabel !== '') {
            setDataLayer({
                action: 'click',
                category: category.error ? 'click' : category,
                label: gtmLabel,
                value,
            });
        }

        onClick && onClick(e);
    };

    let iconSettings = {};

    if (icon) {
        iconSettings = {
            name: 'ArrowRightWithTail',
            width: 50,
            shape: 'square',
            placement: 'right',
            spacing: icon.shape == 'circle' ? buttonStyles.paddingRight : '0px',
            ...icon,
        };
    }

    const renderIcon = () => {
        if (!icon) {
            return null;
        }

        const Icon = icon && IconComponent[icon.name || 'ArrowRightWithTail'];

        return (
            <Icon
                inButton
                css={{ right: iconSettings.spacing }}
                {...iconSettings}
            />
        );
    };

    const content = <MultiLineText>{children}</MultiLineText>;

    const renderButton = (
        <StyledButtonWrapper
            as={url ? 'div' : 'button'}
            iconWidth={iconSettings.width}
            iconSpacing={iconSettings.spacing}
            icon={iconSettings}
            disabled={disabled}
            onClick={url ? handleClick : onClick}
            $textAlign={textAlign}
            $margin={margin}
            fullWidth={fullWidth}
            $color={color || 'white'}
            className={className}
            $background={background || 'purple'}
            $border={{
                width: 1,
                color: 'superLightGrey',
                ...border,
            }}
            size={size}
        >
            <StyledButton
                ref={buttonRef}
                disabled={disabled}
                $display={display}
                allCaps={allCaps}
                $background={background || 'transparent'}
            >
                {content}
            </StyledButton>
            {renderIcon()}
        </StyledButtonWrapper>
    );

    if (url) {
        return (
            <Link
                to={url}
                {...{
                    rel,
                    target,
                    abFirstGoal,
                    gtmCategory,
                    gtmLabel,
                    gtmValue,
                    onClick,
                }}
                preserveQuerystring={preserveQuerystring}
            >
                {renderButton}
            </Link>
        );
    }

    return renderButton;
};

const iconShape = {
    name: PropTypes.string,
    background: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    padding: PropTypes.string,
    placement: PropTypes.oneOf(['left', 'right']),
    placementValue: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    shape: PropTypes.oneOfType([
        PropTypes.oneOf(['square', 'circle']),
        PropTypes.bool,
    ]),
};

Button.propTypes = {
    abFirstGoal: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    allCaps: PropTypes.bool,
    background: PropTypes.string,
    border: PropTypes.shape({
        shorthand: PropTypes.oneOf([
            'border',
            'border-bottom',
            'border-top',
            'border-left',
            'border-right',
        ]).isRequired,
        width: PropTypes.number,
        color: PropTypes.string,
    }),
    children: PropTypes.node,
    className: PropTypes.string,
    color: PropTypes.string,
    display: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    gtmCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    gtmLabel: PropTypes.string,
    gtmValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    icon: PropTypes.oneOfType([PropTypes.shape(iconShape), PropTypes.bool]),
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    rel: PropTypes.string,
    margin: PropTypes.any,
    size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
    target: PropTypes.oneOf(['_blank', '_self', '']),
    textAlign: PropTypes.oneOf(['left', 'center', 'right']),
    url: PropTypes.string,
    preserveQuerystring: PropTypes.bool,
};

Button.parseProps = atts => {
    return {
        url: atts.url,
        children: atts.text,
        target: atts.target,
        rel: atts.rel,
        background: atts.background,
        color: atts.color,
        border: {
            shorthand: 'border',
            color: atts.borderColor,
        },
        size: atts.size,
        GTMlabel: atts.GTMlabel,
        GTMcategory: atts.GTMcategory,
        fullWidth: atts.fullWidth,
        textAlign: 'center',
        abFirstGoal: atts.abFirstGoal,
        margin: switchCase({
            none: '0',
            top: '2.5rem 0 0',
            bottom: '0 0 2.5rem',
            topAndBottom: '2.5rem 0',
        })('none')(atts.margin),
    };
};

export default Button;
