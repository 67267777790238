import PropTypes from 'prop-types';
import { vcComponents, vcConvertToProps } from '../../components';
import React from 'react';
import './style.styl';
import DynamicModuleGeneratorErrorBoundary from './dynamic-module-generator-error-boundary';
import convertToVirtualSections from './convert-to-virtual-sections';

const levelTypes = [
    'row',
    'column',
    'component',
    'subcomponent',
];

class DynamicModuleGenerator extends React.Component {

    static propTypes = {
        content: PropTypes.any,
        viewport: PropTypes.object,
        mobile: PropTypes.string,
        permalink: PropTypes.string,
        flags: PropTypes.object
    }

    constructor (props) {
        super(props);
    }

    renderBakeryComponent(componentData, Module, parentProps, index, level, indexes) {
        const type = levelTypes[level - 1];

        let outerProps = {
            viewport: parentProps.viewport
        };

        /* eslint-disable camelcase */
        let {num_children} = componentData;
        if (num_children > 0) {
            outerProps.numberOfChildren = num_children;
        }

        /* eslint-enable camelcase */
        let passProps = Object.assign(componentData.attributes, outerProps);

        // Combine all the props based on what the current module wants
        passProps = vcConvertToProps(componentData.name, passProps, parentProps, componentData.children);

        // This is a little double work, but we want to make sure that all props that we want to send 
        // to all children are passed. We need to take some time to check that how to clean this up.
        passProps = {...passProps, ...outerProps};

        // If there are some props we want to pass down to every child, we do it here
        passProps.permalink = parentProps.permalink;

        const newIndexes = {
            ...indexes,
            [type]: index,
        };


        let children = componentData.has_children ? this.renderComponent(componentData.children, passProps, level + 1, newIndexes, false) : null;
        if (children === null && componentData.content) {
            children = componentData.content;
        }

        // Quick fix to make IE and background theme work again
        if (['vc_column', 'vc_row'].includes(componentData.name)) {
            return React.createElement(Module, {
                key: index,
                children: children,
                ...passProps,
                indexes: newIndexes,
                ...this.props.flags,
            });
        }

        return (
            <DynamicModuleGeneratorErrorBoundary name={componentData.name} key={index}>
                {
                    React.createElement(Module, {
                        key: index,
                        children: children,
                        ...passProps,
                        indexes: newIndexes,
                        ...this.props.flags,
                    })
                }
            </DynamicModuleGeneratorErrorBoundary>
        );
    }

    convertGutenbergAttsToProps (Module, attributes, parentProps) {
        if (Module && typeof Module.parseProps === 'function') {
            return Module.parseProps(Object.assign({}, parentProps, attributes));
        }
        return attributes;
    }

    renderGutenbergComponent(componentData, Module, parentProps, index, viewport, indexes, level) {
        let outerProps = {};

        if (componentData.blocks && componentData.blocks.length > 0) {
            outerProps.numberOfChildren = componentData.blocks.length;
        }

        let attributes = { ...componentData };
        delete attributes.blocks; // We dont want to pass the array of children, that goes as prop called children
        let passProps = Object.assign(attributes || {}, outerProps);

        // Combine all the props based on what the current module wants
        passProps = this.convertGutenbergAttsToProps(Module, passProps, parentProps);


        // If there are some props we want to pass down to every child, we do it here
        passProps.permalink = parentProps.permalink;
        passProps.moduleName = componentData.blockName;
        passProps.viewport = viewport;
        passProps.isGutenbergPage = true;

        let children = componentData.blocks && componentData.blocks.length > 0 ? this.renderComponent(componentData.blocks, passProps, undefined, undefined, true) : null;
        if (children === null && componentData.content) {
            children = componentData.content;
        }

        const type = levelTypes[level - 1];
        const newIndexes = {
            ...indexes,
            [type]: index,
        };

        return React.createElement(Module, {
            key: index,
            children: children,
            indexes: newIndexes,
            ...passProps
        });
    }

    renderComponent (components, parentProps = {}, level = 1, indexes = {}, isGutenbergPage) {
        if (!components) {
            return;
        }

        const { viewport } = this.props;

        const items = components.map((componentData, index) => {

            const Module = isGutenbergPage ? vcComponents[componentData.blockName] : vcComponents[componentData.name];

            if (!Module) {
                const name = isGutenbergPage ? componentData.blockName : componentData.name;
                console.error('Could not match VC module of type "%s"', name); // eslint-disable-line no-console
                return (<div className="dynamic-module-generator--error">Could not find a matching component for <span className="dynamic-module-generator--error-label">{name}</span></div>);
            }

            if (isGutenbergPage) {
                return this.renderGutenbergComponent(componentData, Module, parentProps, index, viewport, indexes, level);
            } else {
                return this.renderBakeryComponent(componentData, Module, parentProps, index, level, indexes);
            }

        });

        return items;
    }

    render () {
        const isGutenbergPage = this.props.content.every(comp => comp.blockName);
        const content = isGutenbergPage ? convertToVirtualSections(this.props.content) : this.props.content;

        return (
            <div className="dynamic-module-generator">
                {this.renderComponent(
                    content,
                    { permalink: this.props.permalink, mobile: this.props.mobile, viewport: this.props.viewport },
                    undefined,
                    undefined,
                    isGutenbergPage
                )}
            </div>
        );
    }
}

export default DynamicModuleGenerator;
