import create from './create';

import mobile from './converter/mobile';
import identity from './converter/identity';

// Collection of converters.
const converters = {
    mobile,
    identity
};

const convert = create(converters);

export default convert;
