import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

require('./style.styl');

class Snackbar extends React.Component {

    static propTypes = {
        handleRequestClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        action: PropTypes.array, // An array with, for example, buttons [<Button>Ok</Button>, <Button>Reject</Button>]
    }

    static defaultProps = {
        vertical: 'bottom',
        horizontal: 'center',
        action: [],
        style: {},
    }

    constructor(props) {
        super(props);
        this.state = {
            render: true,
            timeout: -1,
        };
    }

    componentWillReceiveProps (nextProps) {

        if (nextProps.open !== this.props.open) {
            if (nextProps.open === false) {
                clearTimeout(this.state.timeout);
                let timeout = setTimeout(() => {
                    this.setState({render: false});
                }, 1000);
                this.setState({timeout});
            } else {
                clearTimeout(this.state.timeout);
                this.setState({render: true});
            }
        }

    }

    render () {

        const {
            open,
            message,
            action,
        } = this.props;

        if (process.title !== 'browser') {
            return null;
        }
            
        if (!this.state.render) {
            return (<div key="snackbar" className="snackbar--hidden" />);
        }

        // Fix for IE 11. 'pointer-events': 'initial' is applied by default, but it doesnt work in IE.
        let style = {};
        if (!('pointer-events' in style)) {
            style.pointerEvents = 'none';
        }

        let classes = classnames({
            'snackbar': true,
            'snackbar--open' : open,
            'snackbar--closed' : !open,
        });

        return (
            <div key="snackbar" className={classes} style={style}>
                <div className="snackbar--box">
                    <div className="snackbar--message">
                        {message}
                    </div>
                    <div className="snackbar--actions">
                        {action}
                    </div>
                </div>
            </div>
        );

    }

}

export default Snackbar;
