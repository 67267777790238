import produce from 'immer';

import {
    TOGGLE_COOKIE_CONSENT,
    SET_COOKIE_CONSENT_VIEW,
} from '../actions/cookie-consent';
import { OVERVIEW } from '../containers/cookie-preferences/views';

const initialState = {
    show: false,
    firstTimeVisit: false,
    view: OVERVIEW,
};

function cookieConsent(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case TOGGLE_COOKIE_CONSENT:
                draft.show = action.payload.show;
                draft.firstTimeVisit = action.payload.firstTime;
                break;
            case SET_COOKIE_CONSENT_VIEW:
                draft.view = action.payload.view;
                break;
        }
    });
}

export default cookieConsent;
