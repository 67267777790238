import React from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';
import { rgba } from 'emotion-rgba';
import Portal from '../../../portal';

const Wrapper = ({ useDisableScroll, usePortal, open, children, overlay }) => {
    let Container = 'div';
    let containerProps = {};

    if (usePortal && typeof document !== 'undefined') {
        Container = Portal;
        containerProps = { domNode: document.body };
    }

    const backgroundOverlay = () =>
        rgba(overlay.background || 'black', overlay.alpha || 0.5);

    const StyledMainContainer = css`
        html, body, .main-container {
            overflow: ${open && useDisableScroll && 'hidden !important'};
        }

        .main-container {
            &::after {
                content: '';
                transition: background-color 0.5s;
            }

            ${open &&
                overlay &&
                css`
                    position: relative;
                    &::after {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: ${backgroundOverlay()};
                        z-index: 10001; /* Slightly higher than the menu */
                    }
                `}
        }
    `;

    return (
        <Container {...containerProps}>
            <Global styles={StyledMainContainer} />
            {children}
        </Container>
    );
};

Wrapper.propTypes = {
    usePortal: PropTypes.bool,
    useDisableScroll: PropTypes.bool,
    open: PropTypes.bool,
    children: PropTypes.node,
    overlay: PropTypes.oneOfType([
        PropTypes.shape({
            background: PropTypes.string.isRequired,
            alpha: PropTypes.number.isRequired,
        }),
        PropTypes.bool,
    ]),
};

export default Wrapper;
