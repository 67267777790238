import React from 'react';
import './style.styl';

function HumanyWidget() {
    return (
        <div
            id="humany_kundesenter"
            className="humany-widget humany-initialized humany-has-history humany-kundesenter humany-inline humany-theme-standard humany-route-index humany-component-index humany-view-faq humany-browse humany-view-untexted humany-nn humany-category-0 humany-category-all humany-home "
        ></div>
    );
}

export default HumanyWidget;
