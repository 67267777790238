import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { withTheme } from 'emotion-theming';


const Column = styled.div``;
const ColumnComponent = ({
    children,
    gridItemWidth,
    alignment,
    theme,
    paddingTop,
    paddingBottom,
    verticalOffsetTop,
    verticalOffsetBottom,
    direction,
    backgroundColor,
    contentPadding,
}) => {

    const { spacing, colors } = theme;

    const spacingMap = {
        none: '0rem',
        smaller: spacing.smaller,
        small: spacing.small,
        base: spacing.base,
        medium: spacing.medium,
        large: spacing.large,
        xlarge: spacing.xlarge,
    };

    const offsetMap = {
        medium: '12rem',
        large: '24rem',
    };

    let alignCss = null;
    if (alignment === 'center' ) {
        alignCss = css`
            align-items: center;
        `;
    } else if (alignment === 'right') {
        alignCss = css`
            align-items: flex-end;
        `;
    }

    let topOffsetCss = null;
    let bottomOffsetCss = null;
    if (verticalOffsetTop && verticalOffsetTop !== 'none') {
        topOffsetCss = css`
            margin-top: -${offsetMap[verticalOffsetTop]}
        `;
    }
    if (verticalOffsetBottom && verticalOffsetBottom !== 'none') {
        bottomOffsetCss = css`
            margin-bottom: -${offsetMap[verticalOffsetBottom]}
        `;
    }

    let mainCss = css`
        display: flex;
        border-left: none;
        text-align: ${alignment};
        padding-top: ${spacingMap[paddingTop]};
        padding-bottom: ${spacingMap[paddingBottom]};
        padding-left: ${spacingMap[contentPadding]};
        padding-right: ${spacingMap[contentPadding]};
        flex-direction: ${direction};
        background-color: ${colors[backgroundColor]};
        ${alignCss};
        ${topOffsetCss};
        ${bottomOffsetCss};
    `;

    const elements = React.Children.toArray(children);

    const clonedChildren = elements.map((child, index) => {
        return React.cloneElement(child, {
            gridItemWidth,
            last: index === elements.length - 1,
            first: index === 0,
        });
    });
    return <Column css={mainCss}>{clonedChildren}</Column>;
};

ColumnComponent.vcConvertToProps = () => {
    return {};
};

ColumnComponent.propTypes = {
    children: PropTypes.any,
    gridItemWidth: PropTypes.number,
    columnType: PropTypes.string,
    alignment: PropTypes.string,
    paddingTop: PropTypes.string,
    paddingBottom: PropTypes.string,
    verticalOffsetTop: PropTypes.string,
    verticalOffsetBottom: PropTypes.string,
    theme: PropTypes.any,
    direction: PropTypes.oneOf(['column', 'row']),
    backgroundColor: PropTypes.string,
    contentPadding: PropTypes.string,
};

ColumnComponent.defaultProps = {
    alignment: 'left',
    direction: 'column',
    backgroundColor: 'transparent',
    contentPadding: 'none',
};

ColumnComponent.parseProps = (atts) => {
    const newProps = {
        columnType: atts.columnType,
        alignment: atts.alignment,
        paddingTop: atts.paddingTop,
        paddingBottom: atts.paddingBottom,
        verticalOffsetTop: atts.verticalOffsetTop,
        verticalOffsetBottom: atts.verticalOffsetBottom,
        direction: atts.direction,
        backgroundColor: atts.backgroundColor,
        contentPadding: atts.contentPadding,
        blocks: atts.blocks,
    };
    return newProps;
};

export default withTheme(ColumnComponent);
