import { fetchResource } from '../helpers/fetch-helper';

export const REQUEST_SITEMAP = 'REQUEST_SITEMAP';
export const RECEIVE_SITEMAP = 'RECEIVE_SITEMAP';
export const SITEMAP_ERROR = 'SITEMAP_ERROR';

export function requestSitemap() {
    return {
        type: REQUEST_SITEMAP,
    };
}

export function sitemapError(error) {
    return {
        type: SITEMAP_ERROR,
        error
    };
}

export function receiveSitemap(items) {
    return {
        type: RECEIVE_SITEMAP,
        items
    };
}

export function fetchSitemapItems() {
    return async function (dispatch, state) {
        const url = `${state.settings.apiUrl}/sitemap`;
        dispatch(requestSitemap());
        try {
            const sitemapData = await fetchResource(url);
            dispatch(receiveSitemap(sitemapData.sitemapItems));
            return Promise.resolve();
        } catch (err) {
            dispatch(sitemapError(err));
            return Promise.resolve();
        }
    };
}
