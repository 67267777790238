import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from '../../variables/style';
import Icons from '../icons';

import './style.styl';

class MessageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closed: false,
        };
    }
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.string,
        iconName: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]).isRequired,
        closable: PropTypes.bool,
    };

    getIconColor(type) {
        switch (type) {
            case 'error':
                return style.colors.formInvalid;
            case 'approval':
                return style.colors.formValid;
            case 'information':
                return style.colors.colorMypagesBlue;
            default:
                return style.colors.colorRawbBlack;
        }
    }

    render() {
        const { type, iconName, children, closable = false } = this.props;
        if (!children || this.state.closed) {
            return null;
        }
        const BoxClasses = classNames(
            'message-box',
            { 'message-box--error': type === 'error' },
            { 'message-box--approval': type === 'approval' },
            { 'message-box--information': type === 'information' },
            this.props.className
        );

        return (
            <div className={BoxClasses}>
                {iconName && (
                    <Icons
                        className="message-box--icon"
                        color={this.getIconColor(type)}
                        name={iconName}
                        width="25px"
                        height="25px"
                    />
                )}
                <div className="message-box--content">{children}</div>
                {closable && (
                    <button
                        className="message-box--close-btn"
                        onClick={() => this.setState({ closed: true })}
                    >
                        <Icons name="cross" />
                    </button>
                )}
            </div>
        );
    }
}

export default MessageBox;
