import produce from 'immer';

import {
    TOGGLE_SEARCH_MODAL,
    REQUEST_SEARCH,
    RESET_SEARCH,
    RECEIVE_SEARCH,
    SEARCH_NOT_FOUND,
} from '../actions/search';

import setCommonReducerProperties from '../helpers/set-common-reducer-properties';

const initialState = {
    open: false,
    hits: [],
    total: 0,
    page: 1,
    numPages: 1,
    query: '',
    ...setCommonReducerProperties(),
};

function search(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case TOGGLE_SEARCH_MODAL:
                draft.open = action.open;
                break;
            case REQUEST_SEARCH:
                draft.loading = true;
                draft.error = false;
                draft.query = action.query;
                draft.page = action.page;
                break;

            case RESET_SEARCH:
                draft.loading = false;
                draft.loaded = false;
                draft.error = false;
                draft.query = '';
                draft.hits = [];
                draft.total = 0;
                draft.page = 1;
                draft.numPages = 1;
                break;

            case RECEIVE_SEARCH:
                draft.loading = false;
                draft.error = false;
                draft.loaded = true;
                draft.query = action.query;
                draft.hits = action.hits;
                draft.total = action.total;
                draft.numPages = action.numPages;
                break;

            case SEARCH_NOT_FOUND:
                draft.loading = false;
                draft.loaded = false;
                draft.error = action.error;
                draft.query = action.query;
                draft.hits = [];
                draft.total = 0;
                draft.page = 1;
                draft.numPages = 1;
                break;

        }
    });
}

export default search;
