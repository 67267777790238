import React from 'react'; // eslint-disable-line no-unused-vars
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../link';

import './style.styl';

const HeaderMenuItem = ({
    active,
    linkProp,
    noLink,
    title,
    notifications,
}) => {

    let link = null;

    const menuItemLinkClasses = classNames({
        'header-menu-item--link': true,
        'header-menu-item--link--active': active
    });

    if (noLink === false) {
        link = (<div className="header-menu-item--link">{title}</div>);
    } else {
        link = (<Link look="black" to={linkProp} className={menuItemLinkClasses}>{title}</Link>);
    }

    const notificationsClasses = classNames({
        'header-menu-item--messages-badge': true,
        'header-menu-item--messages-badge-too-many': notifications > 99,
    });

    return (
        <div className="header-menu-item">
            {link}
            {notifications > 0 ? <div className={notificationsClasses}>{ notifications > 99 ? '+10' : notifications }</div> : null}
        </div>
    );
};

HeaderMenuItem.propTypes = {
    active: PropTypes.bool,
    badgeType: PropTypes.string,
    linkProp: PropTypes.string,
    noLink: PropTypes.bool,
    title: PropTypes.string,
    notifications: PropTypes.number,
};

export default HeaderMenuItem;
