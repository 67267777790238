import { css } from '@emotion/core';
import Image from '../image';

const styles = props => {
    const {
        index,
        width,
        backgroundType,
        backgroundValue,
        theme: { gap, outerPadding, spacing },
        verticalPadding,
    } = props;

    const verticalMap = {
        none: '0rem',
        smaller: spacing.smaller,
        small: spacing.small,
        base: spacing.base,
        medium: spacing.medium,
        large: spacing.large,
        big: spacing.xlarge,
        xlarge: spacing.xlarge,
    };

    let sectionBase;

    // DynamicModuleGenerator automatically creates a section inside CollapsibleWrapper's body,
    // and any content inserted into that body is itself inserted into a section + column, so we get double padding.
    // A section with the below verticalPadding means it's that body section, and so we handle it uniquely (removing most padding but keeping some on the bottom for better symmetry)
    sectionBase = css`
        padding-top: ${ verticalMap[verticalPadding] };
        padding-bottom: ${ verticalMap[verticalPadding] };
    `;


    let sectionCss = css`
        ${sectionBase}
    `;
    let placerCss = '';
    let backgroundImage = null;


    if (width === 'normal') {
        placerCss = backgroundValue
            ? css`
                  background-color: ${backgroundType === 'color' ? backgroundValue : 'none'};
                  padding: ${gap.col};
                  margin-top: ${gap.col};
                  margin-bottom: ${gap.col};
                  margin-left: -${gap.col};
                  margin-right: -${gap.col};
              `
            : '';

        if (backgroundType === 'image') {
            backgroundImage = backgroundValue ? (
                <div
                    css={css`
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: -9;
                        margin-left: -${gap.col};
                        margin-right: -${gap.col};
                        margin-bottom: ${gap.col};
                        margin-top: -${gap.col};
                    `}
                >
                    <Image
                        rounded={false}
                        src={backgroundValue.url}
                        jwt={backgroundValue.jwt}
                        isBackground={true}
                        cover={true}
                        useNativeWidth
                        fullHeight
                    />
                </div>
            ) : null;
        }
    } else if (width === 'full') {
        sectionCss = backgroundValue
            ? css`
                ${sectionBase}
                background-color: ${ backgroundType === 'color' ? backgroundValue : 'none' };
                /** This prevents the first section to have margin top if its background type is image */
                margin-top: ${backgroundType === 'image' && index === 0 ? 0 : gap.row};
                /* margin-bottom: ${gap.row}; */
                -ms-grid-columns: ${outerPadding} minmax(0, 1fr) ${outerPadding};
                grid-template-columns: ${outerPadding} minmax(0, 1fr) ${outerPadding};
            `
            : sectionBase;

        placerCss = css`
            -ms-grid-column: 2;
            grid-column: 2 / auto;
        `;

        if (backgroundType === 'image') {
            backgroundImage = backgroundValue ? (
                <div
                    css={css`
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: -9;
                    `}
                >
                    <Image
                        rounded={false}
                        src={backgroundValue.url}
                        jwt={backgroundValue.jwt}
                        isBackground={true}
                        cover={true}
                        useNativeWidth
                        fullHeight
                    />
                </div>
            ) : null;
        }
    } else {
        sectionCss = backgroundValue
            ? css`
                ${sectionBase}
                background-color: ${ backgroundType === 'color' ? backgroundValue : 'transparent' };
            `
            : sectionBase;

        if (backgroundType === 'image') {
            backgroundImage = backgroundValue ? (
                <div
                    css={css`
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: -9;
                    `}
                >
                    <Image
                        rounded={false}
                        src={backgroundValue.url}
                        jwt={backgroundValue.jwt}
                        isBackground={true}
                        cover={true}
                        useNativeWidth
                        fullHeight
                    />
                </div>
            ) : null;
        }
    }


    return {
        sectionCss,
        placerCss,
        backgroundImage,
    };
};

export default styles;
