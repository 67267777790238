import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.styl';

const lowResSize = 7;

class ImageComponent extends React.Component {

    static propTypes = {
        altText: PropTypes.string,
        // TODO: This is not being used. Need to make sure the callers who use it (.. or atleast think they do...) wont break when we add it.
        className: PropTypes.string,
        customAltText: PropTypes.string,
        fit: PropTypes.oneOf([true, 'true', null]),
        focus: PropTypes.object,
        backgroundImage: PropTypes.bool,
        height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        heightAspectRatio: PropTypes.number,
        imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        imageText: PropTypes.string,
        imageVersion: PropTypes.string,
        jwt: PropTypes.string,
        originalHeight: PropTypes.number,
        originalWidth: PropTypes.number,
        service: PropTypes.oneOf(['aws-s3', 'local']),
        showRetinaSource: PropTypes.bool,
        src: PropTypes.string,
        useNativeWidth: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        center: PropTypes.bool,
        isBackground: PropTypes.bool,
        cover: PropTypes.bool,
        fullHeight: PropTypes.bool,
    }

    static defaultProps = {
        service: 'aws-s3',
        imageVersion: 'v5'
    }

    static contextTypes = {
        imageHost: PropTypes.string,
        featureSupport: PropTypes.object,
        connectionType: PropTypes.func,
        isMobile: PropTypes.func,
    };


    static getImageServiceSrcPath (imageHost, props, width, height, useNativeWidth) {
        let host = imageHost + '/image';

        host += '/' + props.imageVersion;

        if (props.service === 'aws-s3') {
            host += '/s3';
        }

        let imageUrl;
        let querystrings = [];

        const isGif = props.src && props.src.endsWith('gif');


        if (useNativeWidth && !isGif) {

            // If browser rendering and the device is a retina or similair DPI, we load the original img
            if (process.title === 'browser') { //  && devicePixelRatio === 2) {

                imageUrl = `${host}${props.src}`;

            } else {

                // Otherwise we load the image with half with and height
                const halfOriginalWidth = Math.ceil(props.originalWidth / 2);
                const halfOriginalHeight = Math.ceil(props.originalHeight / 2);

                imageUrl = `${host}${props.src}`;

                querystrings.push(`size=${halfOriginalWidth}x${halfOriginalHeight}`);

            }

        } else {

            imageUrl = `${host}${props.src}`;

            if (!isGif) {
                querystrings.push(`size=${width}x${height}`);
            }

        }

        if (props.imageId) {
            querystrings.push(`v=${props.imageId}`);
        }

        if (props.jwt) {
            querystrings.push(`jwt=${props.jwt}`);
        }

        imageUrl += imageUrl.includes('?') ? '&' : '?';
        imageUrl += querystrings.join('&');

        return  imageUrl;
    }

    constructor (props, context) {
        super(props);
        this.imageHost = context.imageHost;
        this.userAgent = context.userAgent;
    }

    componentDidMount() {
        this.loadedOnce = false;
        this.lowResImage = this.refs.lowResImage;
        if (this.lowResImage && this.lowResImage.complete && this.lowResImage.naturalHeight !== 0) {
            this.loaded();
        }
        if (this.image) {
            this.image.addEventListener('animationend', () => {
                this.image.classList.add('image--fullres-transition-ended');
                this.lowResImage.classList.add('image--lowres--transition-ended');
            }, false);
        }
    }

    componentWillUnmount() {
        this.loadedOnce = false;
    }

    componentWillReceiveProps (nextProps) {
        if (this.image && this.src !== nextProps.src) {
            this.image.src = this.getImageSrc(nextProps);
        }
    }

    isAbsoluteUrl (url) {
        let pattern = /^https?:\/\//i;
        return pattern.test(url);
    }

    getImageSrc (props) {
        let width = 1920;

        if (props.width) {
            width = props.width && props.width.indexOf('%') > -1 ? 1200 : parseInt(props.width, 10);
        }

        return this.getImageServiceSrcPath(props, width, 'AUTO', props.useNativeWidth);
    }

    getImageServiceSrcPath (props, width, height, useNativeWidth) {
        return ImageComponent.getImageServiceSrcPath(this.context.imageHost, props, width, height, useNativeWidth);
    }

    loaded () {
        if (this.loadedOnce === false) {
            this.loadedOnce = true;
            let src = this.getImageSrc(this.props);
            let img = new Image();
            img.addEventListener('load', () => {
                if (this.image) {
                    this.image.src = img.src;
                    this.image.classList.add('image--fullres-loaded');
                }
            }, false);
            img.src = src;
        }
    }

    getPictureElement(retinaSrc, src, altText, imgStyle) {
        const srcSet = `${retinaSrc} 2x, ${src}`;
        // const srcWebpSet = `${retinaSrc}.webp 2x, ${src}.webp`;
        // <source srcSet={srcWebpSet} type="image/webp" />
        return (
            <picture className="image--picture">
                <source srcSet={srcSet} />
                <img title={altText} style={imgStyle} ref={(image) => { this.image = image; }} className="image--fullres" alt={altText} />
            </picture>
        );
    }

    render () {
        const {
            fit,
            backgroundImage,
            height,
            heightAspectRatio,
            imageText,
            originalHeight,
            originalWidth,
            showRetinaSource,
            src,
            useNativeWidth,
            width,
            center,
            isBackground,
            cover,
            fullHeight,
        } = this.props;

        const altText = this.props.customAltText || this.props.altText;

        let figureStyle = {};
        let imgStyle = {};

        if (width) {
            figureStyle.width = width.toString().match(/%/) ? width : `${width}px`;
        }

        const halfOriginalWidth = Math.ceil(this.props.originalWidth / 2);
        const halfOriginalHeight = Math.ceil(this.props.originalHeight / 2);

        if (useNativeWidth) {
            figureStyle.height = imgStyle.height = `${halfOriginalHeight}px`;
            figureStyle.width = imgStyle.width = `${halfOriginalWidth}px`;
            imgStyle.maxWidth = '100%';
            imgStyle.objectFit = cover ? 'cover' : 'contain';
            imgStyle.height = fullHeight ? '100%' : 'auto';
        } else if (height) {
            figureStyle.height = imgStyle.height = height.toString().match(/%/) ? height : `${height}px`;
        } else {
            figureStyle.height = 0;
            figureStyle.paddingBottom = useNativeWidth || height ? '' : heightAspectRatio * 100 + '%',
            imgStyle.width = '100%';
        }



        if (backgroundImage || !this.context.featureSupport.objectFit) {
            imgStyle.height = height || 'auto';
        } else if (this.props.focus) {
            imgStyle.objectPosition = `${this.props.focus.x * 100}% ${this.props.focus.y * 100}%`;
        }

        let imageLowResComponent = null;
        let imageHighResComponent = null;

        if (this.isAbsoluteUrl(src)) {
            imageHighResComponent = (
                <img title={altText} style={imgStyle} className="image--fullres-external" src={ src } alt={altText} />
            );
        } else {
            const lowRes = this.getImageServiceSrcPath(this.props, lowResSize, Math.ceil(lowResSize * (this.props.heightAspectRatio || 1)));

            const lowResImgStyle = Object.assign({}, imgStyle);
            imageLowResComponent = (
                <img style={lowResImgStyle} ref="lowResImage" onLoad={this.loaded.bind(this)} className="image--lowres" src={ lowRes } alt={altText} />
            );

            if (useNativeWidth) {
                const retinaSrc = this.getImageServiceSrcPath(this.props, originalWidth, originalHeight, true);
                const src = this.getImageServiceSrcPath(this.props, halfOriginalWidth, halfOriginalHeight, false);
                imageHighResComponent = this.getPictureElement(retinaSrc, src, altText, imgStyle);
            } else if (showRetinaSource) {
                const retinaSrc = this.getImageServiceSrcPath(this.props, width * 2, height * 2, false);
                const src = this.getImageServiceSrcPath(this.props, width, height, false);
                imageHighResComponent = this.getPictureElement(retinaSrc, src, altText, imgStyle);
            } else {
                imageHighResComponent = (
                    <img title={altText} style={imgStyle} ref={(image) => { this.image = image; }} className="image--fullres" alt={altText} />
                );
            }
        }

        const imageTextContainer = imageText && imageText.length > 0 ? (
            <p className="image--text">
                {imageText}
            </p>
        ) : null;

        let imageWrapperStyle = {};

        if (width) {
            imageWrapperStyle.width = width.toString().match(/%/) ? width : `${width}px`;
        }

        // If we dont support object fit, just fallback to a bakcground image instead, with no fancy loading effects
        if (backgroundImage || !this.context.featureSupport.objectFit) {
            if (fit !== 'true') {
                let backgroundSource = this.isAbsoluteUrl(src) ? src : this.getImageSrc(this.props);
                figureStyle.background = `url(${backgroundSource}) no-repeat center center / cover`;
                figureStyle.backgroundSize = 'cover';
                imageLowResComponent = null;
                imageHighResComponent = null;
            }
        }

        let figureClasses = classNames(
            'image',
            {'image--fit': fit === 'true'}
        );

        let wrapperClass = classNames(
            'image--wrapper',
            {'image--wrapper-center': center}
        );

        if (isBackground) {
            figureStyle.height = '100%';
            imageWrapperStyle.height = '100%';
        }

        return (
            <div className={wrapperClass} style={imageWrapperStyle}>
                <figure className={figureClasses} style={figureStyle}>
                    {imageLowResComponent}
                    {imageHighResComponent}
                </figure>
                {imageTextContainer}
            </div>
        );
    }
}

// Expose this as well
ImageComponent.getImageServiceSrcPath;

export default ImageComponent;
