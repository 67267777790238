import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DesktopMenu from './desktop';
import MobileMenu from './mobile';
import MobileMenuBank from './mobile-menu-bank';
import { shouldShowItem } from './helpers/should-show-menu-item';
import style from '../../variables/style';

class HeaderMenu extends React.Component {
    static propTypes = {
        viewport: PropTypes.object,
        marketMenu: PropTypes.array,
        loginButton: PropTypes.object,
        userHasCorporateAccounts: PropTypes.bool,
        userHasMpts: PropTypes.bool,
        userHasCards: PropTypes.bool,
        mobile: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
        isBankPage: PropTypes.bool,
    };

    constructor(props) {
        super(props);
    }

    filterVisibleMenuItems = items =>
        items.reduce((acc, curr) => {
            if (curr.has_children && curr.children.length > 0) {
                const children = this.filterVisibleMenuItems(curr.children);

                if (children && children.length !== 0) {
                    curr.children = children;
                } else {
                    delete curr.children;
                    curr.has_children = false; // eslint-disable-line
                }
            }

            const { userHasCorporateAccounts, userHasMpts } = this.props;

            if (
                shouldShowItem({
                    requires: curr.requires,
                    userHasCorporateAccounts,
                    userHasMpts,
                })
            ) {
                return [...acc, curr];
            }

            return acc;
        }, []);

    render() {
        const { props } = this;
        const isMobileDevice = props.viewport.width < style.breakpoints.desktopSmallMaxWidth;

        let Menu = DesktopMenu;

        if (isMobileDevice) {
            Menu = MobileMenu;
        }

        if (props.isBankPage && isMobileDevice) {
            Menu = MobileMenuBank;
        }

        return (
            <Menu
                {...props}
                filterVisibleMenuItems={this.filterVisibleMenuItems}
            />
        );
    }
}

HeaderMenu.contextTypes = {
    store: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        viewport: state.viewport,
        mobile: state.settings.isMobileDevice,
        isBankPage: state.pages.isBankPage,
        userHasCorporateAccounts:
            state.user.isAuthenticated && state.user.corporateAccounts,
        userHasMpts: state.user.isAuthenticated && state.user.hasCards,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch: dispatch,
    };
};

const HeaderMenuContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(HeaderMenu)
);

export default HeaderMenuContainer;
