const abFirst = process.browser ? window.abFirst : {};
let gaCreated = false;
let retries = 0;
let timeoutReference = 0;

function initializeGa() {
    useQueue();
}

const send = function({hitType, eventCategory, eventAction, eventLabel}) {

    if (process.browser) {

        if(typeof window.ga === 'function') {

            let command = '';

            if(typeof google_tag_manager !== 'undefined') {
                command = window.ga.getAll()[0].get('name') + '.';
            }

            command += 'send';

            window.ga(command, hitType, {
                'eventCategory': eventCategory,
                'eventAction': eventAction,
                'eventLabel': eventLabel,
            });

        } else {
            console.warn('window.ga is undefined, analytics not working'); // eslint-disable-line
        }

    }

};

function pushToAnalyticsQueue(hitType, eventCategory, eventAction, eventLabel, options = { force: false }) {
    if (process.browser) {
        abFirst.queue = abFirst.queue || [];
        if (abFirst.lastUrlPushed !== window.location.pathname || options.force) {
            abFirst.lastUrlPushed = window.location.pathname;
            abFirst.queue.push({hitType, eventCategory, eventAction, eventLabel});
            useQueue();
        }
    }
}

const sendAsGoal = (uuid) => {
    const assignment = abFirst && abFirst.userAssignments && abFirst.userAssignments.find(variant => variant.abTestUuid === uuid);
    if (assignment && assignment.participant) {
        pushToAnalyticsQueue('event', 'A/B First', assignment.abTestName, `${assignment.variant}:converted`, { force: true });
    }
};

function useQueue() {
    if (process.browser) {

        if (window.ga && window.ga.getAll) {
            clearTimeout(timeoutReference);
            if (!gaCreated) {
                window.ga('create', abFirst.gaCode, 'auto');
                gaCreated = true;
            }

            if (abFirst.queue && abFirst.queue.length > 0) {
                while (abFirst.queue.length) {
                    const item = abFirst.queue.shift();
                    send(item);
                }
            }

        } else if (retries < 5) {
            retries++;
            timeoutReference = setTimeout(useQueue, 2000);
        } else {
            console.warn('window.ga is undefined, analytics not working'); // eslint-disable-line
        }

    }
}

initializeGa();

module.exports = {
    pushToAnalyticsQueue,
    sendAsGoal,
};
