/**
 * @file             : services/frontend/src/www-src/scripts/reducers/anchorpoint.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 02.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
import produce from 'immer';
import style from '../variables/style';

import {
    ANCHORPOINTS_UPDATE_OFFSET,
} from '../actions/anchorpoint';

const initialState = { offset: -(style.desktopMenu.paddingTop) };

function anchorpoint (state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case ANCHORPOINTS_UPDATE_OFFSET:
                if (action) {
                    draft.offset = action.offset;
                }
                break;
        }
    });
}

export default anchorpoint;
