require('./style.styl');

import React from 'react';
import PropTypes from 'prop-types';
import ClassNames from 'classnames';
import Image from '../image';
import Link from '../link';

class ContentBlockModule extends React.Component {

    static vcConvertToProps(atts){
        let newProps = {
            colorScheme: atts.color_scheme ? atts.color_scheme : '',
            textAlignment: atts.textAlignment ? atts.textAlignment: '',
            whiteBackground: atts.white_background ? atts.white_background: '',
            typographicScheme: atts.typographicScheme ? atts.typographicScheme: '',
        };

        return newProps;
    }

    static propTypes = {
        alignment: PropTypes.string,
        children: PropTypes.any,
        className: PropTypes.string,
        colorScheme: PropTypes.string,
        extraSidePadding: PropTypes.bool,
        extraWide: PropTypes.bool,
        whiteBackground: PropTypes.bool,
        jwt: PropTypes.string,
        noPadding: PropTypes.bool,
        paddingBottom: PropTypes.number,
        paddingLeft: PropTypes.number,
        paddingRight: PropTypes.number,
        paddingTop: PropTypes.number,
        showCode: PropTypes.bool,
        textAlignment: PropTypes.string,
        typographicScheme: PropTypes.string,
        unorderedListBorderColor: PropTypes.string,
        wysiwyg: PropTypes.bool,
    }

    static defaultProps = {
        wysiwyg: true,
    }

    parseChild = (item, i) => {

        if (typeof item !== 'string') {
            return item;
        }

        if (this.props.jwt) {
            const re = /((?:<a[^>]+>)?<img[^>]+>(?:<\/a>)?)/g;

            const split = item.split(re);

            if (split.length > 1) {
                return split.map((str, j) => {
                    if (!str.startsWith('<a') && !str.startsWith('<img')) {
                        return <div key={j} dangerouslySetInnerHTML={{__html: str}}/>;
                    }

                    const [ , href ] = str.match(/href="([^"]+)"/) || [];
                    const [ , altText ] = str.match(/alt="([^"]+)"/) || [];
                    const [ , originalHeight ] = str.match(/height="([^"]+)"/);
                    const [ , originalWidth ] = str.match(/width="([^"]+)"/);
                    const [ , src ] = str.match(/src="([^"]+)"/);
                    const [ , imageId ] = str.match(/wp-image-([^" ]+)/);

                    const img = (
                        <Image
                            key={j}
                            jwt={this.props.jwt}
                            src={src}
                            altText={altText}
                            service="aws-s3"
                            heightAspectRatio={parseInt(originalHeight, 10) / parseInt(originalWidth, 10)}
                            originalWidth={originalWidth}
                            originalHeight={originalHeight}
                            imageId={imageId}
                        />
                    );

                    return href ? <Link to={href} key={j}>{img}</Link> : img;
                });
            }
        }

        return <div key={i} dangerouslySetInnerHTML={{__html: item}}/>;

    }

    render () {

        const {
            children,
            colorScheme,
            textAlignment,
            typographicScheme,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            noPadding,
            wysiwyg,
            alignment,
            extraWide,
            extraSidePadding,
            unorderedListBorderColor,
            whiteBackground,
        } = this.props;

        const contentBlockModuleClass = ClassNames(
            'content-block-module',
            `content-block-module--alignment-${alignment}`,
            `content-block-module--unordered-list--color-${unorderedListBorderColor}`,
            { [`content-block-module--colorscheme-${colorScheme}`]: colorScheme ? true : null },
            { [`content-block-module--text-align-${textAlignment}`]: textAlignment ? true : null },
            { [`content-block-module--typographic-scheme-${typographicScheme}`]: typographicScheme ? true : null },
            { [`content-block-module--p-top-${paddingTop}`]: paddingTop >= 0 ? true : null },
            { [`content-block-module--p-bottom-${paddingBottom}`]: paddingBottom >= 0 ? true : null },
            { [`content-block-module--p-left-${paddingLeft}`]: paddingLeft >= 0 ? true : null },
            { [`content-block-module--p-right-${paddingRight}`]: paddingRight >= 0 ? true : null },
            { ['content-block-module--no-padding']: noPadding },
            { ['content-block-module--wysiwyg']: wysiwyg },
            { ['content-block-module--extra-wide']: extraWide },
            { 'content-block-module--white-background': whiteBackground },
            { ['content-block-module--ie--stop-overflow']: !this.context.featureSupport.fullFlexSupport },
            { ['content-block-module--extra-side-padding']: extraSidePadding },
            this.props.className || ''
        );

        return (
            <div className={contentBlockModuleClass}>
                {Array.isArray(children) ? children.map(this.parseChild) : this.parseChild(children)}
            </div>
        );

    }

}

ContentBlockModule.contextTypes = {
    featureSupport: PropTypes.object
};

export default ContentBlockModule;
