import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { ic_content_copy as MdContentCopy } from 'react-icons-kit/md/ic_content_copy';
import './style.styl';

const Copy = ({ string, timeout, copied }) => {
    const [copyText, setCopyText] = useState(null);

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopyText(copied);
    };

    const onCopy = (string, e) => {
        e.stopPropagation();
        copyToClipboard(string);
    };

    useEffect(() => {
        if (copyText) {
            setTimeout(() => {
                setCopyText(null);
            }, timeout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copyText]);

    return (
        <React.Fragment>
            <Icon
                className="copy--icon"
                style={{ display: 'inline-block' }}
                onClick={e => onCopy(string, e)}
                icon={MdContentCopy}
            />
            <span className="copy--text">{copyText}</span>
        </React.Fragment>
    );
};

Copy.defaultProps = {
    timeout: 1000,
    copied: 'Copied!',
};

Copy.propTypes = {
    string: PropTypes.string,
    copied: PropTypes.string,
    timeout: PropTypes.number,
};

export default Copy;
