/**
 * This action can be triggered many times within a short period of time.
 * The dispatched actions may result in a performance issue on the app in general.
 * Containers that connects its state to props (even if they don't directly subscribe to this actions reducer)
 * will suffer from an increased number of renders.
 *
 * Each render() in a Container call will affect its Components which an aditional render() call.
 * Unexpected results may occur on the frontend.
 * Animations made by css-transtions are in particular affected by this re-render behaviour.
 *
 * A good practice would be to allow the App-container to pass trough all prop changes but we limit the number of render() calls
 * using the shouldComponentUpdate() method on other Containers/Components.
 * If done with this in mind there won't be any performance drop at all.
 */

export const VIEWPORT_UPDATE_SIZE = 'VIEWPORT_UPDATE_SIZE';

function updateSize(width, height, headerMenuHeight) {
    return {
        type: VIEWPORT_UPDATE_SIZE,
        width,
        height,
        initiated: true,
        headerMenuHeight,
    };
}

const onWindowResize = function(dispatch) {
    if (process.title === 'browser') {
        const headerMenuElement = document.querySelector('.header-menu');
        dispatch(
            updateSize(
                window.innerWidth,
                window.innerHeight,
                headerMenuElement ? headerMenuElement.offsetHeight : 65,
            )
        );
    }
};

export function subscribe() {
    return function(dispatch) {
        if (process.title === 'browser') {
            window.addEventListener(
                'resize',
                onWindowResize.bind(window, dispatch),
                false
            );
            window.addEventListener(
                'orientationchange',
                onWindowResize.bind(window, dispatch),
                false
            );
            window.addEventListener(
                'load',
                onWindowResize.bind(window, dispatch),
                false
            );
            onWindowResize(dispatch);
        }
    };
}

export function unsubscribe() {
    return function(dispatch) {
        if (process.title === 'browser') {
            window.removeEventListener(
                'resize',
                onWindowResize.bind(window, dispatch),
                false
            );
            window.removeEventListener(
                'orientationchange',
                onWindowResize.bind(window, dispatch),
                false
            );
        }
    };
}
