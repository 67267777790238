import produce from 'immer';

import {
    REQUEST_FAQ,
    RECEIVE_FAQ,
    FAQ_ERROR,
    REQUEST_CATEGORIES,
    RECEIVE_CATEGORIES,
    CATEGORIES_ERROR,
    RECEIVE_GUIDES,
    REQUEST_GUIDES,
    RECEIVE_GUIDE,
    REQUEST_GUIDE,
    SET_HUMANY_VIEW,
    LIST_VIEW,
} from '../actions/humany';

const initialState = {
    faq: {},
    category: {},
    view: LIST_VIEW,
    guide: {
        loading: false,
        loaded: false,
        error: false,
        data: {},
    },
    categories: {
        loading: false,
        loaded: false,
        error: false,
        list: [],
        filtered: [],
    },
    guides: {
        loading: false,
        loaded: false,
        error: false,
        list: [],
        count: 0,
    },
};

function humany(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case RECEIVE_FAQ: {
                draft.faq.list = action.faq.Matches || [];
                draft.faq.error = false;
                draft.faq.loading = false;
                break;
            }

            case REQUEST_FAQ:
                draft.faq.loading = true;
                break;

            case FAQ_ERROR:
                draft.faq.loading = false;
                draft.faq.error = action.error;
                break;

            case RECEIVE_CATEGORIES: {
                draft.categories.list = action.categories;
                draft.categories.loaded = true;
                draft.categories.error = false;
                draft.categories.loading = false;
                break;
            }

            case REQUEST_CATEGORIES:
                draft.categories.loading = true;
                break;

            case RECEIVE_GUIDES: {
                draft.guides.list = !action.reset
                    ? [...state.guides.list, ...action.guides.Matches]
                    : action.guides.Matches;
                draft.guides.count = action.guides.TotalMatches;
                draft.guides.error = false;
                draft.guides.loading = false;
                draft.guides.loaded = true;
                break;
            }

            case REQUEST_GUIDES:
                draft.guides.loading = true;
                break;

            case RECEIVE_GUIDE: {
                draft.guide.data = action.guide;
                draft.guide.error = false;
                draft.guide.loaded = true;
                draft.guide.loading = false;
                break;
            }

            case REQUEST_GUIDE:
                draft.guide.loading = true;
                break;

            case CATEGORIES_ERROR:
                draft.categories.loading = false;
                draft.categories.error = action.error;
                break;

            case SET_HUMANY_VIEW:
                draft.view = action.view;
                break;
        }
    });
}

export default humany;
