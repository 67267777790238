import produce from 'immer';

import {
    CLOSE_ALERT,
    SHOW_ALERT,
} from '../actions/alerts';

const initialState = {
    showAlert: false,
    alerts: [],
    openAlerts: [],
    error: false,
};

function alerts(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case CLOSE_ALERT: {
                draft.showAlert = false;
                break;
            }

            case SHOW_ALERT: {
                draft.showAlert = true;
                break;
            }
        }
    });
}

export default alerts;
