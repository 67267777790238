import produce from 'immer';

import {
    RECEIVE_SITEMAP,
    SITEMAP_ERROR,
    REQUEST_SITEMAP,
} from '../actions/sitemap';

import setCommonReducerProperties from '../helpers/set-common-reducer-properties';

const initialState = {
    sitemap: {
        items: [],
        ...setCommonReducerProperties(),
    }
};

function sitemap(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case RECEIVE_SITEMAP: {
                draft.sitemap.items = action.items;
                draft.sitemap.error = false;
                draft.sitemap.loading = false;
                draft.sitemap.loaded = true;
                break;
            }

            case REQUEST_SITEMAP: {
                draft.sitemap.loading = true;
                break;
            }

            case SITEMAP_ERROR:
                draft.sitemap.error = action.error;
                draft.sitemap.loading = false;
                draft.sitemap.loaded = false;
                break;
        }
    });
}

export default sitemap;
