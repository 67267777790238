import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const StyledGrid = styled.div`
    /* IE11 */
    display: -ms-grid;
    -ms-grid-columns: ${p => p.IE11Values.gridColumns};
    -ms-grid-column-span: auto;

    /* Chrome */
    display: grid;
    width: 100%;
    grid-template-columns: repeat(${p => p.cols}, 1fr);
    grid-template-rows: ${p => p.templateRows};
    grid-column-gap: ${p => p.gap.col};
    grid-row-gap: ${p => p.gap.row};
    justify-items: ${p => p.justifyItems};
    align-items: ${p => p.alignItems};
    /* ---- Default end ---- */
    /* ---- 420px start ---- */
    @media screen and (max-width: 420px) {
        grid-column-gap: 8px;
        grid-row-gap: 20px;
    }
    /* ---- 420px  end ---- */
`;

// align: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
const alignToJustify = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
};

export const StyledGridItem = styled.div`
    /* ---- Default start ---- */
    width: 100%;
    /* IE11 */
    -ms-grid-column: ${p => p.col.s};
    -ms-grid-column-span: ${p => p.spanCol};
    -ms-grid-row: ${p => p.row.s};
    -ms-grid-row-span: ${p => p.spanRow};
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin-right: ${p => p.marginRight}px;
        margin-left: ${p => p.marginLeft}px;
    }

    /* Chrome */
    grid-column: ${p => p.col.s} / ${p => p.col.e};
    grid-row: ${p => p.row.s} / ${p => p.row.e};
    justify-self: ${p => p.justify};
    align-self: ${p => p.align};
    display: flex;
    flex-direction: column;
    ${p =>
        p.equalHeight &&
        css`
            height: 100%;
            /** Also stretch first div-item in the grid, i.e the column */
            & > div {
                height: 100%;
                /** Allow vertial align to work with 100% height columns */
                justify-content: ${alignToJustify[p.align] || p.align}
            }
        `};
    /* ---- Default end ---- */
`;
