import React from 'react';
import SvgIcon from './svg-icon';
import PropTypes from 'prop-types';

const Rocket = ({ viewBox = '0 0 14 14', ...rest }) => (
    <SvgIcon viewBox={viewBox} {...rest}>
        <rect
            x="26.93"
            y="163.25"
            width="36.68"
            height="12"
            transform="translate(-129.49 156.97) rotate(-71.01)"
        />
        <rect
            x="-1.08"
            y="139.15"
            width="36.68"
            height="12"
            transform="translate(-97.07 54.08) rotate(-44.68)"
        />
        <path d="M87.63,45.1a24.06,24.06,0,1,0,14.91,30.57A24.08,24.08,0,0,0,87.63,45.1Zm3.56,26.67a12.05,12.05,0,1,1-11.38-16,11.85,11.85,0,0,1,3.91.66A12.06,12.06,0,0,1,91.19,71.77Z" />
        <path d="M105.74,2.43,103.53,0l-3.24.55A90.35,90.35,0,0,0,29,115.83l.89,2.94,46.28,15.92,10-8.4,2.95,12.16,1.85-1.3a89.66,89.66,0,0,0,33.43-44.46A90.41,90.41,0,0,0,105.74,2.43ZM113,88.78a77.72,77.72,0,0,1-26.54,36.77L39.68,109.44A78.34,78.34,0,0,1,99.05,13,78.39,78.39,0,0,1,113,88.78Z" />
        <rect
            x="76.22"
            y="149.15"
            width="12"
            height="36.68"
            transform="matrix(0.99, -0.13, 0.13, 0.99, -20.99, 12.05)"
        />
    </SvgIcon>
);

Rocket.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string,
};

export default Rocket;
