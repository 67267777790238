/**
 * @file             : services/frontend/src/www-src/scripts/actions/user.js
 * @author           : Camilo Tapia <camilo.tapia@gmail.com>
 * Last Modified Date: 02.11.2017
 * Last Modified By  : Camilo Tapia <camilo.tapia@gmail.com>
 */
export const LOG_OUT = 'LOG_OUT';
export const REQUEST_RESOURCE = 'REQUEST_RESOURCE';
export const RECEIVE_RESOURCE = 'RECEIVE_RESOURCE';
export const RECEIVE_ERROR = 'RECEIVE_ERROR';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';

import {
    fetchResource,
    postResource,
    parseHeaderData,
} from '../helpers/fetch-helper';
import { clearHeartbeat } from '../actions/heartbeat';

function requestResource() {
    return {
        type: REQUEST_RESOURCE,
    };
}

function receivedResource() {
    return {
        type: RECEIVE_RESOURCE,
    };
}

function receivedError(errorMessage) {
    return {
        type: RECEIVE_ERROR,
        errorMessage,
    };
}

export function userLoggedIn(loginData) {
    return {
        type: USER_LOGGED_IN,
        termsAndConditionsPending: loginData.termsAndConditionsPending,
        termsAndConditionsUrl: loginData.termsAndConditionsUrl,
    };
}

export function logOutUser() {
    return async function(dispatch, state, requestOptions) {
        const stateObj = typeof state === 'function' ? state() : state;

        let url = `${stateObj.settings.apiUrl}/auth/logout`;

        try {
            const options = { ...requestOptions, credentials: 'include' };
            await fetchResource(
                url,
                parseHeaderData(stateObj.requestData, options)
            );
            const { bankSlug } = stateObj.bank.settings;
            // clear the heartbeat timeout when we log out
            clearHeartbeat();
            dispatch({
                type: LOG_OUT,
                payload: bankSlug,
            });
        } catch (err) {
            if (err.status == 401) {
                dispatch({
                    type: LOG_OUT,
                });
                location.href = stateObj.generalOptions.systemPages.logoutPage;
            } else {
                throw err;
            }
        }
    };
}

export function newsletterSignup(formData) {
    return async function(dispatch, state, requestOptions) {
        const url = `${state.settings.apiUrl}/user/newsletter/signup`;
        try {
            dispatch(requestResource());

            await postResource(
                url,
                { ...formData },
                parseHeaderData(state.requestData, requestOptions)
            );
            dispatch(receivedResource());

            return Promise.resolve();
        } catch (err) {
            dispatch(receivedError(err.errorMessage));
            return Promise.reject(err.errorMessage);
        }
    };
}
