import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;

    button {
        transition: background 0.5s, color 0.5s, border-color 0.5s;
        border: 1px solid rgba(255, 255, 255, 0);
        color: #683277;
    }

    button:hover {
        background: #683277;
        color: ${theme.colors.white};
        border-color: rgba(255, 255, 255, 1);
    }
`;

export const StyledHeader = styled.h2`
    margin: 3rem 0 2rem;
    color: ${theme.colors.white};
    font-size: 2.5rem;
    font-family: "Montserrat", Arial, serif;
`;
