import React from 'react';
import PropTypes from 'prop-types';
import { fetchPostResources } from '../../actions/post-resource';
import { connect } from 'react-redux';
import DocumentHandlerList from '../../components/document-handler-list';
import {loadingOrErrorCheck, LoadingAndError} from '../loading-and-error';

class DocumentHandler extends React.Component {

    static vcConvertToProps(atts) {
        let newProps = {
            categoriesToDisplay: atts.categories_to_display.split(',') || '', // split because get_categories returns a string
            test: atts.test,
            handlerId: atts.handler_id || '',
        };
        return newProps;
    }

    static propTypes = {
        categoriesToDisplay: PropTypes.array,
        language: PropTypes.string.isRequired,
        permalink: PropTypes.string,
        postResources: PropTypes.object,
        viewport: PropTypes.object,
        handlerId: PropTypes.string,
    }

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        const { categoriesToDisplay, handlerId } = this.props;
        const listTagHash = `documenthandler${this.props.permalink}`;

        const filter = {
            resourceIs: 'document',
            resourceDependsOn: categoriesToDisplay,
            limit: '-1',
            handlerId: handlerId,
        };
        this.context.registerFetchAction(
            fetchPostResources(listTagHash, filter)
        );
    }

    filterDuplicatePosts(posts) {
        let encountered = {};
        return posts.filter( item => {
            return encountered[item.id] ? false : (encountered[item.id] = true);
        });
    }

    render() {
        const {
            language,
            postResources,
            viewport,
            handlerId,
        } = this.props;

        const identifier = handlerId ? handlerId : `documenthandler${this.props.permalink}`;

        if (loadingOrErrorCheck(postResources.list, identifier)) {
            return (<LoadingAndError item={postResources.list} contentKey={identifier}/>);
        }

        const postsObject = postResources.list[identifier];
        const posts = postsObject.resources;
        const postsFiltered = this.filterDuplicatePosts(posts);

        return(
            <div className="document-handler">
                <DocumentHandlerList language={language} posts={postsFiltered} viewport={viewport}/>
            </div>
        );
    }

    static contextTypes = {
        registerFetchAction: PropTypes.func,
    }
}

const mapStateToProps = (state) => {
    return {
        postResources: state.postResources,
        viewport: state.viewport,
        language: state.settings.language,
    };
};

export default connect(mapStateToProps)(DocumentHandler);
