import produce from 'immer';

import {
    REQUEST_RESOURCE,
    RECEIVE_RESOURCE,
    ACTIVATE_RESOURCE,
    RESOURCE_NOT_FOUND,
    SORT_RESOURCES_BY_TAG,
    ACTIVATE_CURRENT_RESOURCE,
    REQUEST_SINGLE_POST,
    RECEIVE_SINGLE_POST,
    SINGLE_POST_NOT_FOUND,
} from '../actions/post-resource';

/*
    This is a general reducer for all sorts of wp posts where we store a large query.
 */

const initialState = {
    list: {},
    singlePosts: {},
    currentSlug: ''
};

/**
 * Each list object contains an object of data
 * {
 *     resources: [{}, {}, {}, ...] containing post items with content
 *     slug: "slug-string" of the page/project that requests the posts
 *     hasMore: boolean pointer to know when to stop fetching more data
 *     postsPerPage: number sets how many posts we will fetch
 *     currentOffset: number
 *     currentResource: object | boolean
 *     error: boolean
 *     loading: boolean
 *     sortBy: string
 * }
 */

function postResources(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case REQUEST_RESOURCE:
                const identifier = action.handlerId ? action.handlerId : action.slug;
                draft.currentSlug = action.slug;
                draft.list[identifier] = draft.list[identifier] || { error: false, loading: true, loaded: false, hasMore: true, sortBy: 'default', currentOffset: 0, postsPerPage: action.postsPerPage, slug: identifier, resources: [], currentResource: false };
                break;

            case RECEIVE_RESOURCE: {
                const identifier = action.handlerId ? action.handlerId : action.slug;
                let currentResourcesObject = draft.list[identifier];
                const newResources = action.resources.filter( (resource) => {
                    return !(currentResourcesObject.resources.find( res => res.id === resource.id ));
                });
                currentResourcesObject.resources = currentResourcesObject.resources.concat(newResources); // Append the received post array
                // Determine if there are no more posts to load
                if (currentResourcesObject.resources.length < currentResourcesObject.currentOffset + currentResourcesObject.postsPerPage) {
                    currentResourcesObject.hasMore = false;
                }
                currentResourcesObject.loaded = true;
                currentResourcesObject.loading = false;
                currentResourcesObject.currentOffset += currentResourcesObject.postsPerPage;

                draft.currentSlug = action.slug;
                draft.list[identifier] = currentResourcesObject;
                break;
            }

            case ACTIVATE_RESOURCE: {
                const identifier = action.handlerId ? action.handlerId : action.slug;
                draft.current = action.slug;
                let currentResourcesObject = draft.list[identifier];
                currentResourcesObject.loading = false;
                currentResourcesObject.error = false;
                draft.list[identifier] = currentResourcesObject;
                break;
            }
            case RESOURCE_NOT_FOUND:
                draft.current = action.slug;
                draft.list[action.slug] = { error: true, loading: false, loaded: false, message: action.message, hasMore: false, sortBy: 'default', status: action.status, slug: action.slug, resources: [], currentResource: false };
                break;

            case SORT_RESOURCES_BY_TAG: {
                let currentResourcesObject = draft.list[action.slug];
                currentResourcesObject.sortBy = action.tagSlug;
                currentResourcesObject.resources = action.sortedResources;

                draft.currentSlug = action.slug;
                draft.list[action.slug] = currentResourcesObject;
                break;
            }

            case ACTIVATE_CURRENT_RESOURCE: {
                let currentResourcesObject = draft.list[action.slug];
                let resourcePermalink = action.resourcePermalink;
                currentResourcesObject.currentResource = currentResourcesObject.resources.filter((resource) => {
                    return resource.permalink === resourcePermalink;
                })[0];

                draft.list[action.slug] = currentResourcesObject;
                break;
            }

            case REQUEST_SINGLE_POST: {
                const post = draft.singlePosts[action.postSlug] || {};
                post.loading = true;
                post.loaded = false;
                draft.singlePosts[action.postSlug] = post;
                break;
            }

            case RECEIVE_SINGLE_POST: {
                draft.singlePosts[action.postSlug] = action.post;
                draft.singlePosts[action.postSlug].loading = false;
                draft.singlePosts[action.postSlug].error = false;
                draft.singlePosts[action.postSlug].loaded = true;
                break;
            }

            case SINGLE_POST_NOT_FOUND: {
                const post = draft.singlePosts[action.postSlug] || {};
                post.error = true;
                post.message = action.message;
                draft.singlePosts[action.postSlug] = post;
                break;
            }
        }
    });
}

export default postResources;
