require('./style.styl');

import React from 'react';
import Row from '../../components/row';
import Column from '../../components/column';
import PropTypes from 'prop-types';
import Headline from '../../components/headline';
import DocumentHandlerListItem from '../../components/document-handler-list-item';

class DocumentHandlerList extends React.Component {

    static propTypes = {
        posts: PropTypes.array,
        viewport: PropTypes.object,
        language: PropTypes.string,
    }

    static contextTypes = {
        localize: PropTypes.func,
    }

    constructor(props) {
        super(props);
    }

    renderDocumentLinks(posts) {
        const links = posts.map((post) => {
            return (
                <DocumentHandlerListItem
                    post={post}
                    key={post.id}
                    language={this.props.language}
                />
            );
        });

        return links;
    }

    renderDocumentSubCategorys() {
        const categorys = ['forms', 'terms_and_conditions', 'information'];

        const categoryEls = categorys.map((cat, i) => {
            const categoryContextKey = cat.replace(/_/g, '-');
            const postsOfThisType = this.props.posts.filter( post  => post.type === cat );
            if (postsOfThisType.length < 1) {
                return null;
            }
            return (
                <Column width="1/3" viewport={this.props.viewport} key={i}>
                    <div className="document-handler-list--sub-category">
                        <Headline className="document-handler-list--sub-category--headline" tag={5}>{ this.context.localize(categoryContextKey) }</Headline>
                        <ul className="document-handler-list--sub-category--list">
                            { this.renderDocumentLinks(postsOfThisType) }
                        </ul>
                    </div>
                </Column>
            );
        });

        return categoryEls;
    }

    render() {
        return(
            <div className="document-handler-list">
                <Row>
                    { this.renderDocumentSubCategorys() }
                </Row>
            </div>
        );
    }

}

export default DocumentHandlerList;
