import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import { StyledGridItem } from './style';

export const gridHookValue = '12345';

const viewport = {};

/**
 * Calculate the pixel width of the content.
 */
const calcPixelWidth = ({ theme, viewport, spanCol, cols, colGap }) => {
    const maxWidth = Math.min(theme.maxWidth, viewport.width);
    const dynamicPadding =
        (parseFloat(theme.outerPadding.replace('vw'), 10) / 100) *
        viewport.width *
        2;
    const underWidthMinusPadding = maxWidth - dynamicPadding;
    let sectionWidthAfterPadding = 0;
    if (viewport.width - dynamicPadding > theme.maxWidth) {
        sectionWidthAfterPadding = theme.maxWidth;
    } else if (viewport.width > theme.maxWidth) {
        sectionWidthAfterPadding = viewport.width - dynamicPadding;
    } else {
        sectionWidthAfterPadding = underWidthMinusPadding;
    }
    const gridItemWidth =
        (spanCol / cols) *
        (sectionWidthAfterPadding - (cols / spanCol - 1) * colGap);
    return gridItemWidth;
};

/**
 * Calculate the gap in pixels. Handles if gap is 'rem' based.
 */
const calcGap = ({ theme }) => {
    let colGap = theme.gap.col.includes('rem')
        ? parseInt(theme.gap.col.replace(/rem/, ''), 10) * theme.baseSize
        : theme.gap.col;
    return colGap;
};

const Item = ({
    children,
    col,
    row,
    justify,
    align,
    columnCount,
    columnIndex,
    cols,
    equalHeight,
}) => {
    const theme = useTheme();

    let colGap = calcGap({ theme });

    // This piece of code is IE11 specific to calculate the grid gap since it's not supported in IE11. 'gap' is optional.
    const spanCol = col.e - col.s;
    const spanRow = row.e - row.s;
    const gapLeftRight = colGap;
    let marginLeft = 0;
    let marginRight = 0;
    if (columnCount > 0) {
        if (columnIndex < columnCount - 1) {
            marginRight = gapLeftRight / 2;
        }
        if (columnIndex > 0) {
            marginLeft = gapLeftRight / 2;
        }
    }
    // ---- end of IE11 gap specific code

    // Give all children the exact pixel width.
    const gridItemWidth = calcPixelWidth({
        theme,
        viewport,
        spanCol,
        cols,
        colGap,
    });
    const elements = React.Children.toArray(children);
    const clonedChildren = elements.map(child => {
        return React.cloneElement(child, {
            griditemwidth: gridItemWidth,
        });
    });
    // ---- end of exact pixelwidth stuff.

    const gridItemProps = {
        col,
        spanCol,
        row,
        spanRow,
        marginRight,
        marginLeft,
        justify,
        align,
        equalHeight,
    };

    return <StyledGridItem {...gridItemProps}>{clonedChildren}</StyledGridItem>;
};

Item.defaultProps = {
    // This prop is checked by the Grid component to
    // warn if the Grid contains any children that
    // are NOT Grid.Items.
    gridHookId: gridHookValue,
    justify: 'stretch',
    align: 'stretch',
    equalHeight: false,
};

Item.propTypes = {
    children: PropTypes.any,
    row: PropTypes.shape({
        s: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        e: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    col: PropTypes.shape({
        s: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        e: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    justify: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    align: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    columnCount: PropTypes.number,
    columnIndex: PropTypes.number,
    cols: PropTypes.number,
    equalHeight: PropTypes.bool,
};

export default Item;
