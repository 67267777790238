import {
    shouldFetch,
    fetchResource,
    parseHeaderData,
} from '../helpers/fetch-helper';

export const REQUEST_HEADERMENU = 'REQUEST_HEADERMENU';
export const RECEIVE_HEADERMENU = 'RECEIVE_HEADERMENU';
export const HEADERMENU_NOT_FOUND = 'HEADERMENU_NOT_FOUND';
export const ACTIVATE_HEADERMENU = 'ACTIVATE_HEADERMENU';
export const SUBMENU_OPEN = 'SUBMENU_OPEN';

export function subMenuOpen(status) {
    return {
        type: SUBMENU_OPEN,
        status,
    };
}

function requestHeaderMenu(menuName) {
    return {
        type: REQUEST_HEADERMENU,
        menuName,
    };
}

function receivedHeaderMenu(data, menuName) {
    return {
        type: RECEIVE_HEADERMENU,
        payload: data,
        menuName,
    };
}

function notFound() {
    return {
        type: HEADERMENU_NOT_FOUND,
    };
}

function activateHeaderMenu(menuName) {
    return {
        type: ACTIVATE_HEADERMENU,
        menuName,
    };
}

export function fetchHeaderMenu(menuName) {
    return async function(dispatch, state, requestOptions) {
        let menu = state.headerMenu.list[menuName];
        if (shouldFetch({ entity: menu, slug: menuName })) {
            let url = `${state.settings.apiUrl}/menus/${menuName}`;

            if (menu) {
                return true;
            }

            dispatch(requestHeaderMenu(menuName));

            try {
                const menu = await fetchResource(
                    url,
                    parseHeaderData(state.requestData, requestOptions)
                );
                dispatch(receivedHeaderMenu(menu, menuName));
                return Promise.resolve();
            } catch (err) {
                dispatch(notFound());
                throw err;
            }
        }

        dispatch(activateHeaderMenu(menuName));
        return Promise.resolve();
    };
}

export function fetchClusterMenu(rootPermalink) {
    return fetchHeaderMenu(`cluster/${rootPermalink}`);
}
