import React from 'react';
import SvgIcon from './svg-icon';
import PropTypes from 'prop-types';

const Minus = ({ viewBox = '0 0 14 2', ...rest }) => (
    <SvgIcon viewBox={viewBox} {...rest}>
        <path d="M.778 1.556h12.444a.757.757 0 0 0 .56-.22A.757.757 0 0 0 14 .779a.757.757 0 0 0-.219-.56.757.757 0 0 0-.559-.218H.778a.757.757 0 0 0-.56.219A.757.757 0 0 0 0 .778c0 .227.073.413.219.559a.757.757 0 0 0 .559.219z" />
    </SvgIcon>
);

Minus.propTypes = {
    viewBox: PropTypes.string,
};

export default Minus;
