import produce from 'immer';

import {
    MODALS_OPEN,
    MODALS_CLOSE,
} from '../actions/modals';

const initialState = {
    active: null,
};

function modals(state = initialState, action) {
    return produce(state, draft => {
        switch (action.type) {
            case MODALS_OPEN:
                draft.active = action.payload;
                break;

            case MODALS_CLOSE:
                draft.active = null;
                break;
        }
    });
}

export default modals;
